<template>
    <div @touchstart="triggerGif" :key="game.date+game.dome+game.time+game.field">
        <div class="broken-game" v-if="integrity < 0.9" :class="{'really-broken': integrity < 0.66}"><i class="fas fa-wrench"></i></div>
        <div class="game-preview-info">
            <div class="dome-name">{{game.dome}}</div>
            <div class="game-time">{{convertTime(game.time)}} - {{convertTime(game.time, true)}}</div>
            <div class="game-field">{{game.date}}</div>
            <div class="game-date">{{$t('search.field')}} {{game.field}}</div>
            <!-- <div class="button view-game-button">{{$t('search.view_game')}}</div> -->
        </div>
        <div class="pre-space-image">
            <img class="game-preview-img" :src="source" rel="preload">
        </div>
        <div v-if="game.preview || game.userPreview" class="preview-tag">{{$t('search.preview')}}</div>
    </div>
</template>

<script>
import api from '../api/server.js';
import {app} from "/config.json";

export default {
	name: 'GameSearchElement',
    props: {
        game: {
			type: Object,
			default: function () {
				return {};
			},
		},
        index: {
            type: String,
            default: function() {
                return "";
            }
        },
        imgIndex: {
            type: Number,
            default: function() {
                return 0;
            }
        },
        gifOn: {
            type: Boolean,
            default: function() {
                return false;
            }
        }
    },
    data: function() {
        return {
            interval: null,
            currentGifIndex: 0,
            integrity: 1,
        };
    },
    methods: {
        triggerGif() {
            const newThumbnail = this.game.thumbnail.includes('jpg');
            if (newThumbnail) {
                // EMIT THE EVENT
                this.$emit("changeGifIndex", this.index);
            }
        }
    },
    computed: {
        source: function() {
            if (!this.gifOn) {
                return this.game.thumbnail;
            } else {
                return this.game.thumbnail.replace(/0([^0]*)$/, this.imgIndex + '$1');
            }
        }
    },
    mounted() {
        const finalKey = this.game.Key.replace(app.filenames.thumbnail, app.filenames.final);
        const that = this;
        api.fetchHead(finalKey, (err, res) => {
            if (res && res.Metadata && res.Metadata.integrity > 0) {
                that.integrity = res.Metadata.integrity;
            }
        });
    }
}
</script>

<style scoped>
.preview-tag {
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    background: #610888;
    position: absolute;
    top: 24px;
    right: 36px;
    z-index: 1;
    padding: 6px;
    font-family: 'Nunito';
}

.pre-space-image {
    display: block;
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
}

.game-preview-img {
    border-radius: 12px;
    vertical-align: top;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: inherit;
}


.game-preview-info {
    min-width: 128px;
    display: inline-block;
    vertical-align: top;
    font-family: Nunito;
    color: #000;
    line-height: 18px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-right-radius: 12px;
    background: #fafafa;
    padding: 12px;
    z-index: 1;
}

.dome-name {
    font-size: 18px;
    font-weight: 600;
}

.game-time {
    font-weight: 600;
    padding-top: 4px;
}

.game-field, .game-date {
    color: #666666;
    font-size: 14px;
}

.broken-game {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #ffc107;
    color: #424242;
    text-align: center;
    line-height: 28px;
}
.broken-game.really-broken {
    background: #f44336;
}
</style>