<template>
	<div class="player-selector-container">
		<div class="cta-explainer">Select Your Player.</div>
		<div class="player-container">
			<div class="left-arrow" @click="previousPlayer">
				<div class="arrow-wrapper">
					<i class="fas fa-arrow-left"></i>
				</div>
			</div>
			<div class="images-container">
				<div :style="'background-image: url(' + currentPlayer.src + ')'" class="image"></div>
			</div>
			<div class="right-arrow" @click="nextPlayer">
				<div class="arrow-wrapper">
					<i class="fas fa-arrow-right"></i>
				</div>
			</div>
		</div>
		<button :class="disabled ? 'disabled' : ''" class="sign-up" @click="buttonClick">{{ buttonText }}</button>
	</div>
</template>

<script>

export default {
	name: 'PlayerSelector',
	props: {
		players: {
			type: Array,
			default() {
				return [];
			}
		},
		selectedPlayer: {
			type: Object,
			default() {
				return {};
			}
		},
		buttonText: {
			type: String,
			default() {
				return "";
			}
		}
	},
	data() {
		return {
			disabled: Object.keys(this.selectedPlayer).length === 0, // If it's empty, disabled
		};
	},
	computed: {
		currentPlayer() {
			if (Object.keys(this.selectedPlayer).length === 0) {
				return {src: `${this.getCloudProviderBase(true)}/player.jpg`}
			}
			const team = this.$route.params.team.toLowerCase();
			const currentPlayer = this.selectedPlayer;
			currentPlayer["src"] = `${this.getCloudProviderBase(true)}/${team}/${team}-${currentPlayer.id}.png`;
			return currentPlayer;
		}
	},
	methods: {
		previousPlayer() {
			console.log(this.selectedPlayer)
			this.disabled = false;
			const prevIndex = this.selectedPlayer && this.selectedPlayer.index || 0;
			this.$emit("playerSelected", ((prevIndex - 1) + this.players.length) % this.players.length);

		},
		nextPlayer() {
			console.log(this.selectedPlayer)
			this.disabled = false;
			const prevIndex = this.selectedPlayer && this.selectedPlayer.index || 0;
			this.$emit("playerSelected", ((prevIndex + 1) + this.players.length) % this.players.length);

		},
		buttonClick() {
			if (this.disabled) {
				alert("Please Select a Player");
				return;
			}
			this.$emit("buttonClick");
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.player-selector-container {
	text-align: center;
}

.player-container {
	width: 100%;
	display: flex;
	margin-top: 16px;
}

.images-container {
	flex-grow: 1;
}

.image {
	height: 180px;
	background-size: auto 100%;
	background-position: center center;
	width: 60px;
	margin: auto;
	background-repeat: no-repeat;
}

.arrow-wrapper {
	position: absolute;
	width: 50px;
	height: 50px;
	border-radius: 25px;
	line-height: 62px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: linear-gradient(45deg, #FF0054, #610888);
}

.right-arrow, .left-arrow {
	flex-grow: 3;
	position: relative;
}

.fa-arrow-right, .fa-arrow-left {
	width: 100%;
	line-height: 100%;
	font-size: 30px;
	color: #fff;
}

.sign-up {
	margin-top: 16px;
	cursor: pointer;
	font-family: 'Nunito';
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 32px;
	padding: 12px;
	width: 200px;
	font-size: 20px;
	border-style: none;
}

.sign-up.disabled {
	background: linear-gradient(45deg, #9e9e9e, #BDBDBD);
}

.sign-up:focus {
	box-shadow: 0 0 0 2pt #FF0054;
	outline: none;
}

.cta-explainer {
	margin-top: 24px;
	font-family: 'Nunito';
	font-size: 20px;
	font-weight: 700;
}
</style>
