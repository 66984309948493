<template>
	<div :class="{landing: landingStyle, profile: profileStyle}" class="header">
		<Slide right :closeOnNavigation="true" noOverlay>
			<router-link to="/">
				<i class="fas fa-home"></i><span>{{$t('header.home')}}</span>
			</router-link>
			<router-link to="/search" >
				<i class="fas fa-futbol"></i><span>{{$t('header.view_games')}}</span>
			</router-link>
			<router-link v-if="user.email" :to="{name: 'profile', params : { username: username}}">
				<i class="fas fa-user-circle"></i><span>{{$t('header.profile')}}</span>
			</router-link>
			<router-link :to="{name: 'userFeed'}">
				<i class="fas fa-rss"></i><span>{{$t('header.top_5')}}</span>
			</router-link>
			<router-link :to="{name: 'facilities'}">
				<i class="fas fa-plus-circle"></i><span>{{$t('header.facility_owners')}}</span>
			</router-link>
		</Slide>
		<div class="logo-container">
			<router-link to="/">
				<img class="replay-logo" src="./../assets/logo.png">
			</router-link>
			<div v-if="!hideLang" class="language-toggle" :class="{profile: profileStyle, footage: footageStyle}">
				<div v-if="$i18n.locale == 'en'">EN<img class="lang-opt--flag" src="../assets/us.png"></div>
				<div v-if="$i18n.locale == 'es'">ES<img class="lang-opt--flag" src="../assets/es.png"></div>
				<div v-if="$i18n.locale == 'fr'">FR<img class="lang-opt--flag" src="../assets/fr.png"></div>
				<div class="language-toggle--options">
					<div class="lang-opt" @click="selectLang('en')">EN<img class="lang-opt--flag" src="../assets/us.png"></div>
					<div class="lang-opt" @click="selectLang('es')" >ES<img class="lang-opt--flag" src="../assets/es.png"></div>
					<div class="lang-opt" @click="selectLang('fr')" >FR<img class="lang-opt--flag" src="../assets/fr.png"></div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Slide } from 'vue-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`


export default {
	name: 'ReplayHeader',
	components: {
		Slide
	},
	props: {
		profileStyle: {
			type: Boolean,
			default: function () {
				return false;
			},
		},
		landingStyle: {
			type: Boolean,
			default: function () {
				return false;
			},
		},
		footageStyle: {
			type: Boolean,
			default: function () {
				return false;
			},
		},
		hideLang: {
			type: Boolean,
			default: function () {
				return false;
			},
		}
	},
	data() {
		return {
		}
	},
	computed: {
		...mapGetters([
			'user',
			'username',
			'userMetadata',
			'isLoggedIn',
		]),
	},
	mounted() {
	},
	methods: {

		logout() {
			this.$auth.logout();
		},
		selectLang(code) {
			this.$i18n.locale = code;
			this.$i18n.default = false;
			this.$store.commit("SET_USER_LANGUAGE", code);
		}
	},
	watch: {
		user: {
			handler() {
				if (this.isLoggedIn && this.userMetadata.language) {
					this.$i18n.locale = this.userMetadata.language;
					this.$i18n.default = false;
				}
			},
			immediate: true,
			deep: true
		}
	}
}
</script>

<style>
.bm-burger-button {
	width: 32px !important;
	height: 28px !important;
	top: 12px !important;
	right: 52px !important;
}
.bm-burger-bars {
	background-color: #616161 !important;
	height: 18% !important;
}
.bm-menu {
	font-family: Nunito !important;
}
.bm-item-list > * > span {
	font-weight: 400 !important;
}
.bm-item-list > * {
	min-width: 300px !important;
}
.cross-style {
	top: 24px !important;
}
.fa-home, .fa-futbol, .fa-user-circle, .fa-plus-circle, .fa-rss {
	color: #fff;
}
.fa-home {
	width: 20px !important;
}

@media screen and (max-width: 640px) {
	.bm-burger-button {
		right: 22px !important;
	}
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
	background: #fff;
	box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.5);
	height: 52px;
	z-index: 1;
}

.header.landing {
	position: relative;
}

.header.profile {
	position: fixed;
	top: 0;
	width: 100%;
}

.logo-container {
	margin: 0 auto;
}

.replay-logo {
	width: 120px;
	padding: 15px 0px;
	display: inline-block;
	margin-left: 52px;
}

.navigation-container {
	display: inline-block;
	float: right;
}

.header-profile-image {
	margin-right: 32px;
	height: 36px;
	width: 36px;
	border-radius: 36px;
	margin-top: 8px;
}

.game-link {
	display: inline-block;
	float: left;
	line-height: 52px;
	margin-right: 32px;
	font-family: 'Nunito';
	font-size: 16px;
	font-weight: 600;
	color: #610888;
	cursor: pointer;
}

.game-link:hover {
	color: #FF0054;
}

.language-toggle {
	font-family: 'Nunito';
	display: inline-block;
	float: right;
	position: relative;
	margin-right: 120px;
	text-align: center;
	height: 42px;
	width: 80px;
	line-height: 42px;
	border-radius: 36px;
	margin-top: 6px;
	z-index: 1;
}

.language-toggle--options {
	display: none;
	position: absolute;
	background: #fafafa;
	width: 100%;
}

.lang-opt {
	cursor: pointer;
}

.lang-opt--flag {
	width: 24px;
	display: inline-block;
	margin-left: 8px;
	margin-top: -2px;
	vertical-align: middle;
}

.lang-opt:hover {
	background: #eee;
}

.language-toggle:hover .language-toggle--options {display: block;}

@media screen and (max-width: 1300px) {
	.replay-logo {
		margin-left: 16px;
	}
}

@media screen and (max-width: 640px) {
	.header-profile-image.profile {
		margin-right: 16px;
	}

	.game-link {
		margin-right: 12px;
	}
	.language-toggle {
		margin-right: 62px;
	}

}
</style>
