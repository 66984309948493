<!--
TODO:

Good practices
[ ] environement configs: credentials, global urls
[ ] move css imports to components only

Improvements
  [ ] aws tracking: make it a plugin
  [ ] verify reactivity for auth0: whats the best way to manage state
-->

<template>
	<div class="landing-container">
		<replay-header :landingStyle=true></replay-header>
		<div class="landing-page-atf">
			<div class="landing-content-wrapper">
				<div class="landing-text-wrapper">
					<div class="landing-text-wrapper--title">{{ $t('landing.cta_title')}}</div>
					<div class="landing-text-wrapper--subtitle">
						MyReplay {{ $t('landing.cta_description')}}
					</div>
				</div>
			</div>
			<div class="dome-picker">
				<div class="modal-content--title">{{$t('segmentModal.select_arena')}}</div>
				<div class="dome-picker--option-wrapper">
					<div class="dome-picker--option" v-for="dome in domes" :key="dome.key" @click="selectDome(dome.key)" :style="'background-image: url(' + dome.thumb +')'">
						<div class="dome-picker--name">{{dome.name}}</div>
						<div class="dome-picker--image-wrapper">
							<img class="dome-picker--image" :src="dome.img">
						</div>
					</div>
				</div>
			</div>
			<svg class="landing-bottom-image parallax" data-depth='0.10' style="fill: url(#linear-gradient)" viewBox="0 0 1593 356.12" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<linearGradient id="linear-gradient" gradientTransform="matrix(1440, 0, 0, -320, 471117, 280146)" gradientUnits="userSpaceOnUse" x1="-327.16" x2="-326.06" y1="874.9" y2="874.9">
						<stop offset="0" stop-color="#ff0054"/>
						<stop offset="1" stop-color="#610888"/>
					</linearGradient>
				</defs>
				<path class="cls-1" d="M0,0,37.94,5.86c38,6.31,113.62,17.37,190,47.24C303.44,83,379.44,129.43,454.67,129.76,531,129.43,607.33,83,682.56,70.8,758.56,58.63,834.11,83,910.44,118c75.67,35.73,151.56,82.19,227.89,82.52,75.34-.33,151.56-46.79,226.78-64.82,76.22-17.37,151.56-6.31,190.28,0L1593,141.6V354h-37.94C1430,352,1437.33,320,1361,320c-75.56,0-147.44,34-222.67,34C1062,354,985.23,322,910,322c-76,0-124.19,24-227.44,32C592,361,562.4,349,456,343c-125-7-119.78-6-195-6-76.22,0-128,17-223.39,17H0Z" fill-opacity="0.87"/>
			</svg>

			<svg class="landing-bottom-image-behind parallax" data-depth='0.20' viewBox="0 0 1593 316.76" xmlns="http://www.w3.org/2000/svg">
				<path class="cls-1" d="M0,74.12H37.94c38,0,113.62,0,190,5.87,75.55,6.3,151.55,17.36,226.78,29.53,76.33,12.17,152.66,23.23,227.89,23.57,76-.34,151.55-11.4,227.88-41.27C986.11,62,1062,15.49,1138.33,3.32c75.34-12.17,151.56,12.17,226.78,23.57,76.22,11.83,151.56,11.83,190.28,11.83H1593v247.8h-37.94c-38,0-113.73,17.24-190.06,17.24-75.56,0-151.44-17.24-226.67-17.24-76.33,0-158.1,30.24-233.33,30.24-76,0-146.11-30.24-222.44-30.24-75.67,0-154.23,30.24-230.56,30.24-75.34,0-148.77-43-224-43-76.22,0-151.67,12.76-190.39,12.76H0Z" fill="#FF0054" fill-opacity="0.5"/>
			</svg>
		</div>
		<!-- BELOW THE FOLD -->
		<div class="feature-container">
			<div class="feature-container--title">{{ $t('landing.how_it_works')}}</div>
			<div class="feature-section">
				<div class="feature-text">
					<div class="feature-text-header">
						<div class="feature-number">1</div>
						<div class="feature-title-container">
							<div class="feature-title-intro">{{ $t('landing.feat_1_title')}}</div>
							<div class="feature-title-title">{{ $t('landing.feat_1_subtitle')}}</div>
						</div>
					</div>
					<div class="feature-description">
						{{ $t('landing.feat_1_description')}}
					</div>
				</div>
				<div class="feature-image">
					<div class="feature-image-wrapper">
						<div class="feature-image-bubble"></div>
					</div>
				</div>
			</div>
			<div class="feature-section">
				<div class="feature-text feature-text-2">
					<div class="feature-text-header">
						<div class="feature-number">2</div>
						<div class="feature-title-container">
							<div class="feature-title-intro">{{ $t('landing.feat_2_title')}}</div>
							<div class="feature-title-title">{{ $t('landing.feat_2_subtitle')}}</div>
						</div>
					</div>
					<div class="feature-description">
						{{ $t('landing.feat_2_description')}}
					</div>
				</div>
				<div class="feature-image feature-image-2">
					<div class="feature-image-wrapper">
						<div class="feature-image-bubble feature-image-bubble-2"></div>
					</div>
				</div>
			</div>
			<div class="feature-section">
				<div class="feature-text">
					<div class="feature-text-header">
						<div class="feature-number">3</div>
						<div class="feature-title-container">
							<div class="feature-title-intro">{{ $t('landing.feat_3_title')}}</div>
							<div class="feature-title-title">{{ $t('landing.feat_3_subtitle')}}</div>
						</div>
					</div>
					<div class="feature-description">
						{{ $t('landing.feat_3_description')}}
					</div>
				</div>
				<div class="feature-image">
					<div class="feature-image-wrapper">
						<div class="feature-image-bubble feature-image-bubble-3"></div>
					</div>
				</div>
			</div>
		</div>
		<!-- END OF FEATURE SECTION -->
		<!-- <div class="stats-container">
			<div class="stat-section">
				<div class="stat-title">{{ $t('landing.stat_1')}}</div>
				<div class="stat-image">
					<svg id="60e21fbe-e09d-4856-b904-62b7762606e2" data-name="Livello 1" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<title>prime</title>
						<path id="31625ee0-a24e-42ee-acc5-7a19af8dfb28" d="M20.27,6.38L16,11V8a2,2,0,0,0-2-2H4A2,2,0,0,0,2,8V18a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V15.5l4.29,4.29A1,1,0,0,0,22,19.09v-12A1,1,0,0,0,20.27,6.38Z" data-name="video"/>
					</svg>
				</div>
			</div>
			<div class="stat-section">
				<div class="stat-title">{{ $t('landing.stat_2')}}</div>
				<div class="stat-image">
					<svg id="Capa_1" fill="#ffffff" style="enable-background:new 0 0 51.798 51.798;" version="1.1" viewBox="0 0 51.798 51.798" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">

              <circle cx="30.563" cy="4.754" r="4.754"/>
						<circle cx="8.493" cy="36.964" r="4.002"/>
						<path d="M47.259,26.37c-1.654-9.193-8.684-14.797-17.738-15.824c-0.416-0.047-0.778-0.007-1.092,0.098
              c-0.293-0.092-0.627-0.132-1.007-0.098c-4.129,0.367-7.68,3.463-10.246,6.538c-2.151,2.576,1.538,6.308,3.708,3.708
              c1.098-1.315,2.765-3.258,4.546-4.296c0.015,0.049,0.023,0.099,0.04,0.149c1.153,3.42,1.483,6.792,1.15,10.368
              c-1.289,0.502-2.549,1.099-3.786,1.742c-1.655,0.862-3.702,1.751-4.357,3.61c-1.314,3.729-2.629,7.457-3.943,11.185
              c-1.127,3.198,3.941,4.562,5.058,1.396c1.223-3.469,2.445-6.938,3.668-10.404c1.814-1.16,3.834-2.289,5.926-2.986
              c0.652,0.342,1.371,0.543,2.08,0.557c0.049,0,0.094-0.004,0.142-0.006c0.301,5.703,0.534,11.41,0.778,17.113
              c0.145,3.361,5.391,3.48,5.244,0.094c-0.332-7.789-0.646-15.584-1.138-23.366c-0.012-0.176-0.039-0.341-0.075-0.499
              c0.104-2.572-0.102-5.165-0.625-7.686c3.479,2.052,5.803,5.52,6.607,10.006C42.8,31.09,47.852,29.677,47.259,26.37z"/>
          </svg>
				</div>
			</div>
			<div class="stat-section">
				<div class="stat-title">{{ $t('landing.stat_3')}}</div>
				<div class="stat-image">
					<svg enable-background="new 0 0 301.091 301.091" fill="#ffffff" version="1.1" viewBox="0 0 301.091 301.091" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<g>
							<g>
								<path d="m282.908,40.25v7.153l-24.222-1.94v-5.213h-23.553c-0.711-4.182-4.342-7.37-8.727-7.37h-18.386v-5.048c0-1.055-0.855-1.91-1.91-1.91h-10.932c-1.055,0-1.91,0.855-1.91,1.91v5.048h-3.138c-4.894,0-8.862,3.968-8.862,8.861v19.951l6.129-8.945c7.805-11.389 23.365-14.295 34.754-6.491 11.39,7.805 14.296,23.365 6.491,34.755l-4.281,6.248h2.045c4.443,0 8.112-3.274 8.752-7.538h23.527v-5.213l24.222-1.94v7.153h18.184v-39.471h-18.183z"/>
								<circle cx="142.128" cy="53.519" r="27.59"/>
								<path d="m215.088,56.568c-5.692-3.901-13.475-2.449-17.378,3.245l-24.442,35.669-38.343-.546-26.184-9.97c-9.616-3.672-20.388,1.147-24.06,10.763 0,0-23.387,61.807-32.01,83.835-1.1,2.81-1.877,6.012-1.421,8.982 2.173,14.156 8.693,56.623 8.693,56.623h-44.943c-8.284,0-15,6.716-15,15s6.716,15 15,15h62.421c4.376,0 8.533-1.911 11.383-5.231 2.85-3.32 4.107-7.72 3.443-12.045l-8.579-55.878 38.456,9.685-10.657,45.01c-1.908,8.062 3.079,16.144 11.141,18.053 1.162,0.275 2.324,0.407 3.469,0.406 6.794,0 12.95-4.647 14.584-11.547l14.064-59.399c1.89-7.98-2.98-15.999-10.933-18.002l-16.591-4.178 21.936-57.451-16.751-4.689 47.227,.672c0.06,0.001 0.119,0.001 0.178,0.001 4.119,0 7.979-2.03 10.311-5.434l28.23-41.196c3.903-5.695 2.45-13.475-3.244-17.378z"/>
							</g>
						</g>
					</svg>

				</div>
			</div>
		</div> -->
		<!-- END OF STAT SECTION -->
		<div class="customer-container">
			<div class="customer-container--title">{{ $t('landing.our_customers')}}</div>
			<div class="customer-list">
				<div class="customer-image">
					<img src="./assets/customers/district5.png">
				</div>
				
				<div class="customer-image">
					<img src="./assets/laredkocha.png">
				</div>
				<div class="customer-image">
					<img src="./assets/mrgol.png">
				</div>
	
				<div class="customer-image">
					<img src="./assets/customers/urbanindoorfutbol.png">
				</div>
				<div class="customer-image">
					<img src="./assets/customer-1.png">
				</div>
			</div>
		</div><!--

         -->
		<div class="footer-transition"><!--
           -->
			sdfsss
			<svg style="opacity:1;fill:url(#turned-gradient);" viewBox="0 0 1920 394.67" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				sdfsdf<defs>
					<linearGradient id="turned-gradient" gradientTransform="matrix(1440, 0, 0, -320, 864, 286138)" gradientUnits="userSpaceOnUse" x1="0.28" x2="0.28" y1="894.18" y2="892.04">
						<stop offset="0" stop-color="#FF0054"/>
						<stop offset="0.5" stop-color="#762998"/>
					</linearGradient>
				</defs>
				<title>footer_2</title>
				<path class="cls-1" d="M0,686.67V0L45.73,7.07C91.47,14.67,182.67,28,274.67,64c91.06,36,182.66,92,273.33,92.4,92-.4,184-56.4,274.67-71.07,91.6-14.66,182.66,14.67,274.66,56.94,91.2,43.06,182.67,99.06,274.67,99.46,90.8-.4,182.67-56.4,273.33-78.13,91.87-20.93,182.67-7.6,229.34,0l45.33,7.07v516Z"/>
				ok
			</svg>
				bye		
         </div>
		<div class="footer">
			&#169; {{ $t('landing.inc_name')}}
		</div>

	</div>

</template>

<script>

import ReplayHeader from './components/ReplayHeader';
import {gsap, Power2, ScrollTrigger} from "gsap/all";
import api from "@/api";
import { mapGetters } from 'vuex'


//import {aws, app} from "/config.json";


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Power2);

export default {
	name: 'LandingPage',
	components: {
		ReplayHeader,
	},
	props: {},
	data() {

		return {
			games: [],
			teamName: "",
			domes: this.getDomeList().filter(x => x.key !== "test"),
		}
	},
	computed: {
		...mapGetters([
			'isLoggedIn',
			'userMetadata'
		])
	},
	mounted() {
		const that = this;

		const segment = this.userMetadata.segment;
		let dome;
        if (segment) {
			dome = this.findDomeByKey(segment)
			if (!dome.key) {
                dome = this.findDomeByName(segment);
            }
        }
		if (!dome) {
			const domes = this.getDomeList().filter(x => x.key !== "test");
			dome = domes[0]; // default to one
		}
		
        api.fetchGames(dome.key, (err, res) => {
            if (err) {
                console.log("ERROR FETCHING GAMES", err);
            } else if (res) {

                const gameList = []
				let gameCount = 0;

                res.slice().forEach(x => {
                    if (x.Key.includes('thumbnail') && gameCount < 2) {
                        gameList.push(x);
						gameCount += 1;
                    } 
                });
    
                const games = gameList.sort((a, b) => {
					return b.Key.localeCompare(a.Key);
				});

                that.games = games.map(x => {
					// Done by reference
                    this.setGameDataFromAWSObject(x);
					return x;
                });
			}
        
        });



		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: ".landing-container",
				start: "top top",
				end: "bottom bottom",
				scrub: 0.3,
			}
		});

		gsap.utils.toArray(".parallax").forEach(layer => {
			const depth = layer.dataset.depth;
			// const movement = -(innerHeight * depth);
			tl.to(layer, {yPercent: -150 * depth}, 0)
		});
	},
	methods: {
		goToSearch() {
			this.$router.push({name: 'search'});
		},
		goToFeed(domeKey) {
			this.$router.push({name: 'userFeed', params: {arena: domeKey}});
		},
		selectDome(dome) {
			this.$store.commit("SET_USER_SEGMENTATION", dome);
			this.goToFeed(dome);
		},
	},
	metaInfo: {
		title: 'MyReplay - Your Game Highlights',
		meta: [
			{'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
			{
				name: 'description',
				content: 'MyReplay films all your games and makes your best plays available for sharing.'
			}
		]
	},
}
</script>

<style src="./assets/reset.css">

</style>

<style>
	.swal2-title, .swal2-actions, .swal2-html-container, .swal2-styled {
		font-family: Nunito !important;
	}
	.swal2-confirm {
		background-color: #610888 !important;
	}
</style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');

.landing-container {
	background: #fff;
}

.landing-page-atf {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	background: #fff;
	position: relative;
	z-index: 0;
}

.game-search-wrapper {
	position: absolute;
	right: 25%;
	top: 50%;
	transform: translate(50%, -50%);
	width: 500px;
}

.search-title {
	font-family: 'Russo One';
	font-size: 1.5vw;
	text-align: center;
}

.landing-image-wrapper {
	position: absolute;
	right: 25%;
	top: 50%;
	width: 300px;
	padding-top: 664px;
	transform: translate(50%, -50%);
	z-index: 3;
}

.landing-image-1 {
	z-index: 1;
	width: 100%;
	right: -13%;
	top: -5%;
	width: 378px;
	position: absolute;
}

.landing-image-2 {
	position: absolute;
	top: 0%;
	width: 100%;
	border-radius: 24px;
}

.landing-bottom-image {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: -11%;
	transform: translateZ(0);
}

.landing-bottom-image-behind {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: -13%;
	z-index: -1;
	transform: translateZ(0);
}

.landing-content-wrapper {
	max-width: 40%;
	vertical-align: top;
	margin: 10% 0px 0px 10%;
	display: inline-block;
}

.landing-text-wrapper {
	margin-bottom: 24px;
	z-index: 1;
}

.landing-text-wrapper--title {
	font-family: 'Russo One';
	font-size: 3vw;
	margin-bottom: 16px;
}

.landing-text-wrapper--subtitle {
	font-family: 'Nunito';
	font-size: 1.8vw;
}

.call-to-actions {
	margin-top: 16px;
}

.log-in {
	margin-left: 16px;
}

.feature-container {
	padding-top: 12%;
	background: #fff;
}

.feature-container--title {
	width: 100%;
	text-align: center;
	font-size: 42px;
	color: #FF0054;
	font-family: 'Russo One';
}

.feature-section {
	display: table;
	margin-top: 36px;
	width: 100%;
}

.feature-text {
	width: 50%;
	padding-left: 15%;
	display: table-cell;
	margin-top: 36px;
	vertical-align: middle;
}

.feature-text-2 {
	float: right;
	padding-left: 0;
	padding-right: 15%;
}

.feature-number {
	background: #FF0054;
	border-radius: 40px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	font-size: 40px;
	text-align: center;
	color: #fff;
	font-family: 'Russo One';
	font-style: italic;
	padding-right: 8px;
	display: inline-block;
	vertical-align: top;
}

.feature-title-container {
	display: inline-block;
	font-family: Nunito;
	margin-left: 16px;
}

.feature-title-intro {
	font-size: 20px;
}

.feature-title-title {
	font-family: 'Russo One';
	font-size: 32px;
	text-transform: uppercase;
	padding-top: 6px;
}

.feature-description {
	font-family: Nunito;
	font-size: 18px;
	color: #333;
	padding-top: 24px;
}

.feature-image {
	display: table-cell;
	width: 50%;
}

.feature-image-2 {
	float: left;
}

.feature-image-bubble {
	width: 300px;
	background: url(./assets/camera.png);
	height: 300px;
	border-radius: 300px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0 auto;
}

.feature-image-bubble-2 {
	background: url(./assets/iphoneMockup.png);
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.feature-image-bubble-3 {
	background: url(./assets/profileUsage.png);
	background-position: center;
	background-size: cover;
}

.stats-container {
	width: 100%;
	display: table;
	margin-top: 120px;
	background: #fff;
}

.stat-section {
	width: 33%;
	display: table-cell;
}

.stat-section {
	text-align: center;
	padding: 24px;
	background: #FF0054;
	border: 2px solid #FF0054;
	color: #fff;
}

.stat-section:hover {
	background: #fff;
	color: #FF0054;
}

.stat-section:hover svg {
	fill: #FF0054;
}

.stat-title {
	font-family: Nunito;
	font-weight: 700;
	font-size: 22px;
}

.stat-image svg {
	margin-top: 16px;
	width: 100px;
	height: 100px;
}

.customer-container {
	width: 100%;
	margin-top: 86px;
	background: #fff;
}

.customer-container--title {
	text-align: center;
	font-size: 42px;
	color: #FF0054;
	font-family: 'Russo One';
}

.customer-list {
	display: table;
	width: 100%;
	padding: 0 10%;
}

.customer-image {
	display: table-cell;
	width: 20%;
	height: 300px;
	position: relative;
}

.customer-image img {
	width: 120px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}


.footer-transition {
	width: 100%;
	font-size: 0;
}

.footer {
	width: 100%;
	height: 50px;
	background: #762998;
	font-family: Nunito;
	text-align: center;
	color: #fff;
}

.signup-cta {
	cursor: pointer;
	font-family: 'Nunito';
	font-weight: 600;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 32px;
	padding: 12px;
	width: 200px;
	font-size: 20px;
	border-style: none;
	text-align: center;
}

.signup-cta:focus {
	box-shadow: 0 0 0 2pt #FF0054;
	outline: none;
}

.dome-picker {
	width: 25%;
	display: inline-block;
	margin-left: 12%;
	margin-top: 10%;
	position: relative;
	z-index: 10;
}

.modal-content--title {
	text-align: center;
	font-size: 26px;
	font-family: 'Russo One';
}

.dome-picker--option-wrapper {
    padding-top: 34px;
    padding-bottom: 16px;
    min-width: 150px;
    border-radius: 16px;
    line-height: 50px;
    font-family: Nunito;
}

.dome-picker--option {
	background-size: cover !important;
    text-align: left;
    padding-left: 12px;
    background: #eeeeee;
    user-select: none;
    height: 62px;
    border: 2px solid #fafafa;
    transition: transform 0.4s ease-in-out;
    transform: translateZ(0);
	cursor: pointer;
}

.dome-picker--option > * {
	z-index: 100;
	position: absolute;
}

.dome-picker--option > .dome-picker--image {
	z-index: 0;
}

.dome-picker--option::before {
	content: "";
    background: #fff;
    opacity: 0.8;
    z-index: 1;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
	border: 2px solid #fff;
}

.dome-picker--option:hover, .dome-picker--option:active{
    transform: scale(1.02) translateZ(0);
    /* background: #dddddd !important; */
}

.dome-picker--option:last-child, .dome-picker--option:last-child::before {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.dome-picker--option:first-child, .dome-picker--option:first-child::before {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.dome-picker--option:hover {
    background: #eee;
}

.dome-picker:hover .dome-picker--option-wrapper {
    opacity: 1;
    pointer-events: auto;
}

.dome-picker--image-wrapper {
    width: 58px;
    height: 58px;
    display: inline-block;
    right: 0;
}

.dome-picker--image {
    margin: 0 auto;
    display: block;
    padding: 4px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.dome-picker--name {
    display: inline-block;
    font-size: 18px;
    line-height: 62px;
    vertical-align: top;
    font-weight: 600;
    user-select: none;
}


@media only screen and (max-width: 1560px) {
	.game-search-wrapper >>> .game-preview {
		max-width: 350px;
		margin: 12px auto;
	}

	.game-search-wrapper {
		width: 350px;
	}
}

@media only screen and (max-width: 1360px) {

	.landing-image-wrapper {
		top: 70%;
		width: 220px;
	}
	.landing-image-1 {
		top: -4%;
		width: 280px;
	}

	.search-title {
		font-size: 20px;
	}

	.game-search-wrapper {
		top: 54%;
	}

}

@media only screen and (max-width: 1200px) {
	.feature-text {
		padding-left: 8%;
	}

	.feature-text-2 {
		padding-right: 8%
	}
}

@media only screen and (max-width: 1080px) {
	.landing-image-wrapper {
		right: 18%;
		top: 55%;
		width: 250px;
		padding-top: 553.33px;
	}

	.landing-image-1 {
		width: 316px;
	}

	.landing-content-wrapper {
		max-width: 55%;
	}

	.landing-text-wrapper--title {
		font-size: 4.5vw;
	}

	.landing-text-wrapper--subtitle {
		font-size: 2.5vw;
	}

	.feature-text, .feature-image {
		display: block;
		width: 100%;
		margin: 0 auto;
		padding: 0 !important;
		max-width: 60%;
		margin-bottom: 24px;
	}

	.feature-text-2, .feature-image-2 {
		float: initial;
	}

	.game-search-wrapper {
		padding-left: 5%;
		padding-right: 5%;
		padding-top: 82vh;
		position: initial;
		transform: none;
		right: unset;
		top: unset;
		width: auto;
	}

	.landing-bottom-image {
		bottom: 28%;
	}
	.landing-bottom-image-behind {
		bottom: 28%;
	}

	.search-title {
		font-size: 30px;
	}

	.dome-picker {
		width: 76%;
		margin-left: 12%;
		margin-top: 6%;
	}
}

@media only screen and (max-width: 860px) {
	.landing-image-wrapper {
		right: 50%;
		top: 74%;
		width: 200px;
		padding-top: 442.66px;
	}

	.landing-image-1 {
		width: 252px;
	}

	.feature-text, .feature-image {
		max-width: 80%;
	}
	.game-preview {
		margin: 14px auto;
	}
	
}

@media only screen and (max-width: 640px) {

	.landing-content-wrapper {
		max-width: 80%;
		margin-top: 10%;
	}

	.landing-text-wrapper--title {
		font-size: 10vw;
	}

	.modal-content--title {
		font-size: 24px;
	}

	.landing-text-wrapper--subtitle {
		font-size: 4.5vw;
	}

	.feature-container {
		padding-top: 24%;
	}

	.feature-text, .feature-image {
		max-width: 90%;
	}

	.feature-title-intro {
		font-size: 16px;
	}

	.feature-title-title {
		font-size: 22px;
	}

	.feature-title-container {
		margin-left: 10px;
	}

	.stat-section, .customer-image {
		display: block;
		width: 100%;
	}

	.customer-image {
		height: 200px;
	}

	.search-title {
		font-size: 6.5vw;
	}
}

@media screen and (max-width: 640px) and (max-height: 800px) {
	.landing-image-wrapper {
		top: 82%;
	}
}

@media screen and (max-width: 640px) and (max-height: 733px) {
	.landing-image-wrapper {
		top: 87%;
	}
}


@media only screen and (max-device-width: 824px)
and (orientation: landscape) {
	.left-container {
		width: 100%;
		height: auto;
	}

	.right-container {
		width: 100%;
		height: 100vh;
		margin-top: 16px;
	}

	.main-container {
		max-width: 100%;
	}
}
</style>
