<template>
	<div id="replay-app">
		<replay-header :footageStyle="true"></replay-header>
		<div class="main-container">
			<div class="search-filters">
				<div class="search-filters--title">{{$t('footage.title')}}{{titleDate}}</div>
				<div class="search-filters--options">
					<!--todo: <dropdown :title="time" :list="hours" :fitContent="true" @select="selectTime"></dropdown>-->
					<dropdown :title="field" :list="fields" :fitContent="true" @select="selectField"></dropdown>
				</div>
			</div>
			<div class="content-container">
				<div class="left-container">
					<div v-if="!included" class="purchase-flag" :class="{purchased: purchasedGame}">{{ purchasedGame ? $t('titleContainer.purchased') : $t('titleContainer.preview', {time: this.checkQuotaStatus()}) }}</div>
					<video-player 
						v-on:viewing-timeout="paywall"
						:options="videoOptions"
						@timeupdate="timeUpdate"
						:preview="preview"
						:purchased="purchasedGame"
						ref="videoComponent"
						:paymentDate="gameData.paymentDateString"
						:currentMarkerId="currentMarkerId" 
						:markers="markers"
					>
					</video-player>
					<video-controller :preview="preview" :gameReady="gameReady" :curTime="curTime" :link="videoOptions.sources[0].src" :purchased="purchasedGame" :subscribed="subscribed"></video-controller>
					<div v-if="integrity < 0.9" class="footage-integrity">{{$t('footage.low_integrity')}}</div>
					
				</div>
			</div>
		</div>
		<conversion-modal :showModal="showModal" ref="conversionComponent" :purchased="purchasedGame"></conversion-modal>
		<success-modal :showModal="showModalSuccess" :showCode="showCode"></success-modal>

		<video-editor :src="videoOptions.sources[0].src" :displayEditor="displayEditor" :curTime="curTime"></video-editor>
		<input type="hidden" id="game-code" :value="gameLink">

		<div class="modal-background" :class="{hidden: socialModalHidden}"></div>
		<div class="download-modal social-modal" :class="{hidden: socialModalHidden}">
			<div class="modal-top-info pt-0 fz-lg">
				{{ $t('feed.share_this_game') }}
			</div>
			<div class="modal-body-info">
				<!-- WhatsApp -->
				<span @click="socialIconClicked('whatsapp')">
					<ShareNetwork
						class="social-media-icon whatsapp-icon"
						network="whatsapp"
						:title="$t('videoEditor.share_your_highlight_1') + this.userMetadata.form_name + $t('videoEditor.share_your_highlight_2')"
						:url="this.gameLink ? this.gameLink : ''"
						tag="a"
					>
						<i class="fab fah fa-lg fa-whatsapp"></i>
					</ShareNetwork>
				</span>

				<!-- Facebook -->
				<span @click="socialIconClicked('facebook')">
					<ShareNetwork
						class="social-media-icon facebook-icon"
						network="facebook"
						:title="$t('videoEditor.share_your_highlight_1') + this.userMetadata.form_name + $t('videoEditor.share_your_highlight_2')"
						:url="this.gameLink ? this.gameLink : ''"
						hashtags="myreplay"
						tag="a"
					>
						<i class="fab fah fa-lg fa-facebook-square"></i>
					</ShareNetwork>
				</span>
			</div>
			<div class="modal-bottom-info pb-0">
				<div class="button-container">
					<div class="control-container--button" @click="closeModal">{{$t('videoController.close')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VideoPlayer from './VideoPlayer';
import ReplayHeader from './ReplayHeader';
import VideoController from './VideoController';
import ConversionModal from './ConversionModal';
import SuccessModal from './SuccessModal';
import VideoEditor from './VideoEditor';
import Dropdown from './Dropdown';
import { mapGetters } from 'vuex';

import api from '../api/server.js';
import {app, stripe} from "/config.json";

export default {
	name: 'App',
	components: {
		VideoPlayer,
		ReplayHeader,
		VideoController,
		SuccessModal,
		ConversionModal,
		VideoEditor,
		Dropdown,
	},
	data() {

		const gameData = this.getGameDataFromPath(this.$route.path);

		return {
			curTime: 0,
			final: false,
			gameReady: false, // Whether or not to show the call to action
			preview: false,
			userPreview: false,
			showModal: false,
			showModalSuccess: false,
			showCode: null,
			integrity: 1,
			gameData,
			displayEditor: false,
			hours: [],
			fields: [],
			time: `${this.convertTime(gameData.hour)} - ${this.convertTime(gameData.hour, true)}`,
			gameLink: null,
			socialModalHidden: true,
			markers: [],
			currentMarkerId: 0,
		}
	},
	computed: {
		videoOptions: function () {
			let src = "";
			if (this.final) {
				src = `${this.getCloudProviderBase(true,  this.gameData.gameKey)}/${app.filenames.gameRoot}/${this.gameData.domeKey}/${this.gameData.yyyymmdd}/${this.footageId}/${app.filenames.final}`;
			} else if (this.userPreview) {
				src = `${this.getCloudProviderBase(true, this.gameData.gameKey)}/${app.filenames.gameRoot}/${this.gameData.domeKey}/${this.gameData.yyyymmdd}/${this.footageId}/${app.filenames.userPreview}`;
			} else if (this.preview) {
				src = `${this.getCloudProviderBase(true, this.gameData.gameKey)}/${app.filenames.gameRoot}/${this.gameData.domeKey}/${this.gameData.yyyymmdd}/${this.footageId}/${app.filenames.preview}`;
			}

			return {
				autoplay: false,
				controls: true,
				footage: true,
				controlBar: {
					'pictureInPictureToggle': false
				},
				sources: [
					{
						src,
						type: "video/mp4"
					}
				]
			}
		},
		footageId : function() {
			const params = this.$route.params;
			return params && params.footageId;
		},
		footageDate () {
			return this.gameData.paymentDateString;
		},
		...mapGetters([
			'user',
			'userSubId',
			'userMetadata',
			'username',
			'isLoggedIn',
			'purchases',
			'subscriptions'
		]),
		purchasedGame() {
			const that = this;

			if (this.subscriptions.length > 0) {
				return true;
			}

			if (this.included) {
				return true;
			}

			return !!this.purchases.find(x => {
				const dateMatch = x.footageDate === that.footageDate;
				if (!x.arena) return dateMatch; // legacy only had date
				const arenaMatch = x.arena === that.gameData.domeKey;
				return dateMatch && arenaMatch;
			});
		},
		subscribed() {
			return (this.subscriptions.length > 0);
		},
		included() {
			return stripe[this.gameData.domeKey].included;
		},
		field() {
			return this.$i18n.messages[this.$i18n.locale].search.field + " " + this.gameData.field;
		},
		titleDate() {
			if (!this.gameData) { 
				return "";
			}

			let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

			if (this.$i18n.locale === 'en') {
				return this.gameData && this.gameData.jsDate.toLocaleString('en-US', options);
			} else if (this.$i18n.locale === 'es') {
				return this.gameData && this.gameData.jsDate.toLocaleString('es-US', options);
			} else if (this.$i18n.locale === 'fr') {
				return this.gameData && this.gameData.jsDate.toLocaleString('fr-CA', options);
			}
			return "";
		},
	},
	watch: {
		user: {
			handler() {
				if (this.isLoggedIn && !this.userMetadata.segment && !this.preview) {
					this.$store.commit("SET_USER_SEGMENTATION", this.gameData.domeKey);
				}
			},
			immediate: true
		}
	},
	methods: {
		checkQuotaStatus(date) { //returns number of seconds left

			if (!date) date = this.footageDate;

			let timeLeft = app.previewQuota;

			if (this.user && !this.purchasedGame) {
				const quotas = this.userMetadata["quotas"];

				const quotaIndex = quotas && quotas.findIndex(x => x.date === date);

				if (quotas && quotaIndex !== -1) {
					timeLeft = app.previewQuota - quotas[quotaIndex].time;
				}
			}

			if (timeLeft < 0) return 0;

			return timeLeft;

		},
		capitalize(string) {
			if (!string) return "";
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		timeUpdate(curTime) {
			this.curTime = curTime;
		},
		paywall() {
			window.heap.track("paywallHit");
			this.showModal = true;
		},
		goToFullGame() {
			const query = this.$route.query;
			const gameCode = query && query.gameCode;
			console.log("GO TO FULL GAME GAMECODE", gameCode);
			if (this.gameReady) {
				this.$router.push({name: "footage", params: this.footageId, query: {gameCode}});
			}
		},
		selectTime(time) {
			if(time.key != '0') {
				console.log("SELECT ", this.gameData.hour+'-', time.key+'-')
				this.$router.push({name: "footage", params: {footageId: this.footageId.replace(this.gameData.hour+'-', time.key+'-')}});
			}
				
		},
		selectField(field) {
			this.$router.push({name: "footage", params: {footageId: this.footageId.replace('-'+this.gameData.field, '-'+field.key)}});
		},
		shareCode() {
			this.myReplaySlackHook(
				"log-users",
				"FOOTAGE_SHARING",
				"- User: " + this.userMetadata.form_name +
				"\n- URL: " + this.gameLink
            );
            this.shareWithoutSweetalert(this.gameLink);
            this.socialModalHidden = false;
		},
		closeModal() {
			this.socialModalHidden = true;
		},
		socialIconClicked(media) {
            if (media === "whatsapp")
                this.myReplaySlackHook(
                    "log-users",
                    "FOOTAGE_SHARING_WHATSAPP_LINK",
                    "- User: " + this.userMetadata.form_name +
                    "\n- URL: " + this.gameLink
                );
			else
                this.myReplaySlackHook(
                    "log-users",
                    "FOOTAGE_SHARING_FACEBOOK_LINK",
                    "- User: " + this.userMetadata.form_name +
                    "\n- URL: " + this.gameLink
                );
		}
	},
	async mounted() {
		
		const that = this;

		/*api.fetchGameMarkers(this.footageId), function(data) {
			console.log("DONE" , data)
			that.markers = data
		});*/

		this.markers = await api.fetchGameMarkers(this.footageId)
		console.log("DONE" , this.markers)

		console.log("testss 1")
		console.log(this.footageId)
		console.log(this.markers)
		console.log("testss 2")

		this.gameLink =  window.location.href + "?gameCode=" + this.$refs.conversionComponent.generateCode(this.footageId);

		
		this.$on('hideModal', () => {
			that.showModal = false;
		});
		this.$on('hideSuccessModal', () => {
			that.showModalSuccess = false;
		});
		this.$on('showSuccessModal', () => {
			that.showModalSuccess = true;
		});
		this.$on('showCode', (code) => {
			that.showCode = code;
			that.showModalSuccess = true;
		});
		this.$on('showEditor', () => {
			that.displayEditor = true;
			this.$refs.videoComponent.pause(); // pause the child component

			document.body.style.overflow = "hidden";
			// Hack to make page scrollable for desktop
			history.pushState({}, document.title, window.location.href + "?edit=true")
		});

		this.$on('hideEditor', () => {
			that.displayEditor = false;

			document.body.style.overflow = "auto";
		});
		

		api.fetchGameFiles(this.footageId, function(err, res) {
			res.Contents.forEach(x => {
				if (x.Key.includes('final.mp4') || x.Key.includes('preview.mp4')) {
					// final exists
					if (that.isLoggedIn) {
						that.final = true;
					}
					that.gameReady = true;
				}

				if (x.Key.includes('user-preview')) {
					// User preview exists, use this as the preview video
					that.userPreview = true;
				}

			});
			
			if (!that.final) {
				that.preview = true;
			}

			if (that.isLoggedIn && that.$route.path.includes("preview") && that.final) {
				that.goToFullGame();
			}

			const gd = that.gameData;
			const gameType = that.final ? app.filenames.final : app.filenames.userPreview; 
			const key = `${app.filenames.gameRoot}/${gd.domeKey}/${gd.yyyymmdd}/${gd.yyyymmdd}${gd.hour}-${gd.domeKey}-${gd.field}/${gameType}`
			api.fetchHead(key, (err, res) => {
				console.log(err, res && res.Metadata)
				if (res && res.Metadata && res.Metadata.integrity > 0) {
					that.integrity = res.Metadata.integrity;
				}
			});

			api.fetchGameSiblingsByDate(gd.domeKey, gd.yyyymmdd, gd.hour, (err, res) => {
				if (err) console.log(err);
				if (!err && res) {
					var obj = that.setHoursAndFields(res);

					console.log("dfdf", obj)
					that.hours = obj.hours;
					that.fields = obj.fields;
				}
			});

		});

	}
}
</script>

<style src="../assets/reset.css">

</style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');

body {
	background: #efeff1 !important;
	height: 100%;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

#replay-app {
	background: #efeff1;
	position: relative;
	overflow: hidden;
	min-height: 100vh;
}

.main-container {
	height: calc(100% - 68px);
	max-height: 100%;
	max-width: max(110vh, 980px);
	margin: 0 auto;
}

.content-container {
	height: calc(100% - 88px);
	padding: 16px;
	margin: 0 auto;
}

.left-container {
	width: 100%;
	float: left;
	height: 100%;
	display: flex;
	flex-flow: column;
	position: relative;
}

.right-container {
	width: calc(32% - 16px);
	float: right;
	display: inline-block;
	height: 100%;
	overflow: hidden;
	background: #fff;
	margin: 0 0 0 16px;
	border-radius: 24px;
	box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
	/*border-radius: 8px;*/
	position: relative;
}

.search-filters {
    width: calc(100% - 32px);
    background: #fff;
    border-radius: 12px;
    margin: 12px auto 0px;
    padding: 16px;
    box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
    text-align: center !important;
}

.hourpicker {
    font-family: 'Nunito';
    text-align: center;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    border-radius: 24px;
    padding: 9px 12px;
    font-weight: 600;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
    margin-top: 6px;
}

.search-filters--title {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Nunito';
    margin-bottom: 6px;
}

.search-filters--options {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.footage-integrity {
	flex: 1 1 auto;
    width: 100%;
    background: #fff;
    padding: 14px;
    margin: 8px 0px -8px 0px;
    border-radius: 8px;
    box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
    font-family: 'Nunito';
    color: #f44336;
}

.purchase-flag {
	font-family: 'Nunito';
    position: absolute;
    right: 12px;
    background: red;
    color: white;
    padding: 6px;
    border-radius: 8px;
    z-index: 10;
    top: 10px;
}
.purchase-flag.purchased {
	background: green;
}

.modal-background {
	background: rgba(0, 0, 0, 0.66);
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	pointer-events: auto;
	display: auto;
}

.download-modal {
	width: 90%;
	height: 400px;
	background: #fff;
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 12px;
	pointer-events: auto;
	display: auto;
}

.social-modal {
	width: 450px;
	height: 250px;
}

.download-modal.hidden, .modal-background.hidden {
	display: none;
	pointer-events: none;
}

.modal-top-info, .modal-bottom-info, .modal-body-info {
	font-family: Nunito;
	text-align: center;
	width: 100%;
	position: absolute;
	padding: 24px;
	font-size: 20px;
}

.modal-top-info {
	top: 10%;
}

.modal-bottom-info {
	bottom: 10%;
}

.pb-0 {
	padding-bottom: 0px;
}

.pt-0 {
	padding-top: 0px;
}

.fz-lg {
	font-size: 24px;
}

.modal-body-info {
	top: 18%;
	margin-top: 10px;
	text-align: center;
	align-items: center;
}

.social-media-icon {
	font-size: 48px;
}

.whatsapp-icon {
	padding-right: 20px;
	color: #25d366;
}

.facebook-icon {
	color: #1877f2;
}

@media only screen and (max-width: 980px) {
	.left-container {
		width: 100%;
		height: auto;
	}

	.right-container {
		width: 100%;
		max-height: 100%;
	}

	.search-filters--title {
		font-size: 18px;
	}
}
</style>