<template>
	<div id="replay-app">
		<replay-header></replay-header>
		<div class="profile-container">
			<div class="profile-content-wrapper">
				<div class="profile-info-container">
					<div :style="{background:'url('+(publicUser && publicUser.picture)+')', backgroundSize: 'cover'}" class="profile-picture"></div>
					<div class="profile-info--content-wrapper">
						<div class="profile-name">{{ publicUsername }}</div>
						<div class="profile-num-plays">{{ profileHighlights && profileHighlights.length }} {{$t('profile.highlights')}}</div>
					</div>
					<div class="share-profile" @click="share"><i class="fas fa-upload"></i> {{$t('profile.share')}}</div>
					<div v-if="ownsThisProfile || publicInstagramHandle || publicProfileDescription" class="profile-bio-container" :class="{owns: ownsThisProfile}">
						<div v-if="ownsThisProfile || publicProfileDescription" class="profile-bio--description" @click="editDescription('profileDescription')">
							<i class="fas fa-pen"></i>{{publicProfileDescription || "Your team names, awards, favourite player, anything about your style"}}
						</div>
						<div v-if="ownsThisProfile || publicInstagramHandle" class="profile-bio--instagram-handle" @click="openInstagram">
							<img class="profile-bio--instagram-icon" src="../assets/insta.png">@{{publicInstagramHandle || "yourInstagramHandle"}}
						</div>
					</div>
				</div>
				<loading-gif :showGif="loading"></loading-gif>
				<div v-if="(!profileHighlights || !profileHighlights.length) && !loading" class="no-highlights">
					<div>{{$t('profile.no_saved')}}</div>
					<div>{{$t('profile.no_saved_cta')}}</div>
					<div v-if="ownsThisProfile" @click="viewGames">{{$t('profile.view_games')}}</div>
				</div>
				<div v-for="h in paginatedHighlights" :key="h.url">
					<feed-element
						:picture="publicUser && publicUser.picture"
						:description="publicUsername"
						:arena="h.arena"
						:timeAgo="h.timeAgo"
						:ownsProfile="ownsThisProfile"
						:videoUrl="h.url"
						:shareLink="h.shareLink"
						:publicUsername="h.username"
						:views="h.Views"
						@highlight-viewed="incrementViews"
						@delete-play="deletePlay"
						:shared="h.shared"
						:id="h.id"
					>
					</feed-element>
				</div>
				<div class="load-more-wrapper">
					<div @click="loadMore" v-if="paginationLength < (profileHighlights && profileHighlights.length)" class="button load-more-button">
						{{$t('profile.load_more')}}
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import ReplayHeader from './ReplayHeader';
import api from "@/api/index.js";
import {mapGetters} from 'vuex';
import FeedElement from './FeedElement.vue';
import LoadingGif from './LoadingGif.vue';


//import {_} from 'vue-underscore';

export default {
	name: 'UserProfile',
	components: {
		ReplayHeader,
		FeedElement,
		LoadingGif
	},
	mounted() {
		// Scroll to top of profile on load
		document.body.scrollTo(0, 0);
		// fetch *public* user inf
		let that = this;

		this.loading = true;

		const filters = {arena: null, type: "clip", status: null, username: this.$route.params.username};

		
		api.getVideoAssets(filters, function(err, data) {
			that.l(data)
			if (err) console.log(err)
			that.publicHighlights = data;
			that.loading = false;
		});

		api.fetchUser("username", this.$route.params.username, function (err, u) {
			if (u == null) {
				console.log("user not found")
				// probably not found, redirect to his profile or something
			} else {
				that.publicUser = u;
			}
		});
	},
	data() {
		return {
			publicUser: null,
			publicUsername: this.$route.params.username,
			publicHighlights: null,
			paginationLength: 10,
			loading: false,
		}
	},
	computed: {
		ownsThisProfile() {
			return this.username === this.$route.params.username;
		},
		...mapGetters([
			'user',
			'username',
			'highlights',
			'profileDescription',
			'instagramHandle',
		]),
		publicProfileDescription() {
			if (this.ownsThisProfile) {
				return this.profileDescription;
			} else {
				return this.publicUser && this.publicUser.user_metadata.profileDescription;
			}
		},
		publicInstagramHandle() {
			if (this.ownsThisProfile) {
				return this.instagramHandle;
			} else {
				return this.publicUser && this.publicUser.user_metadata.instagramHandle;
			}
		},
		profileHighlights() {
			const query = this.$route.query;
			const sharedPlay = query && query.play;
			return this.publicHighlights && this.publicHighlights.map(x => {
				x.url = x.URL;
				const playData = this.getPlayData(x);
				Object.assign(x, playData);
				x.shared = x.suffix === sharedPlay;
				x.shareLink = `${window.location.origin}/#${this.$route.path}?play=${x.suffix}`;

				return x;
			}).sort((a, b) => {
				// First play is the shared one
				if (a.shared) {
					return Number.NEGATIVE_INFINITY;
				} else if (b.shared) {
					return Number.POSITIVE_INFINITY;
				}
				// Rest sorted by date
				return b.dateObj - a.dateObj;
			});
		},
		paginatedHighlights() {
			return this.profileHighlights && this.profileHighlights.slice(0, this.paginationLength);
		}
	},
	methods: {
		incrementViews(url) {
			// do not log for current user
			window.heap.track("viewPlay", {url: url, profile: this.publicUsername});
		},
		share() {
			this.generalShare(`${window.location.origin}/#${this.$route.path}`);
		},
		viewGames() {
			this.$router.push({name: "search"});
		},
		loadMore() {
			this.paginationLength = this.paginationLength + 10;
		},
		deletePlay(id) {
			const index = this.publicHighlights && this.publicHighlights.findIndex(x => x.id === id);
			this.publicHighlights.splice(index, 1);
		},
		openInstagram() {
			if (!this.ownsThisProfile && this.instagramHandle) {
				window.open(`https://instagram.com/${this.instagramHangle}/`, '_blank');
			} else if (this.ownsThisProfile) {
				this.editDescription("instagramHandle");
			}
		},
		editDescription(type) {
			if (this.ownsThisProfile) {
				const that = this;
				const title = type === "instagramHandle" ? "Edit your instagram handle" : "Edit your profile description" ;
				const metadataName = type || "profileDescription";
				const input = type === "instagramHandle" ? "text" : "textarea" ;
				const confirmText = "Confirm";
				this.$swal({
					title,
					input,
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: confirmText,
					preConfirm: (text) => {
						that.$store.commit("UPDATE_METADATA_PROP", {name: metadataName, value: text});
						that.myReplaySlackHook("log-users", "PLAYER_EDIT_PROFILE" , `Player ${this.username} edited: ${metadataName} to ${text}`)
					},
				});
			}
		},

	}
}
</script>

<style src="../assets/reset.css">

</style>

<style scoped>
body {
	background: #fff !important;
}
</style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');
	
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#replay-app {
	background: #efeff1;
}

.profile-container {
	font-family: Nunito;
	min-height: 100vh;
}

.profile-content-wrapper {
	max-width: 500px;
	margin: auto;
}

.profile-picture {
	height: 64px;
	width: 64px;
	background-size: cover !important;
	border-radius: 64px;
	display: inline-block;
}

.profile-info-container {
	margin: 16px 0;
	width: 100%;
	background: #fff;
	padding: 12px;
	border: 1px solid #e0e0e0;
	border-radius: max(0.0001px, min(24px, ((100vw - 4px) - 100%) * 9999 )) / 24px;
}

.profile-info--content-wrapper {
	display: inline-block;
	margin-left: 22px;
	height: 62px;
	vertical-align: top;
}

.profile-name {
	font-size: 20px;
	margin-bottom: 8px;
}

.profile-num-plays {
	font-size: 16px;
	margin-bottom: 8px;
}

.profile-bio-container {
	min-height: 32px;
    margin-top: 16px;
    color: #000000;
    font-size: 14px;
	padding: 0 6px;
}

.profile-bio-container.owns {
	cursor: pointer;
}

.profile-bio--description {
	white-space: pre-wrap;
}

.profile-bio--instagram-handle {
	margin-top: 8px;
	cursor: pointer;
}

.profile-bio--instagram-icon {
	width: 16px;
	vertical-align: bottom;
	margin-right: 8px;
	cursor: pointer;
}

.fa-pen {
	margin-right: 8px;
	color: #424242;
}

.share-profile {
	cursor: pointer;
	color: #ffffff;
	margin-bottom: 12px;
	display: inline-block;
	background: linear-gradient(45deg, #FF0054, #610888);
	width: fit-content;
	font-size: 13px;
	border-radius: 16px;
	padding: 10px;
	display: inline-block;
	float: right;
}

.no-highlights {
	box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
	padding: 24px;
	border-radius: 24px;
	font-family: 'Nunito';
	text-align: center;
	width: 100%;
	background: #fff;
	border-radius: max(0.0001px, min(24px, ((100vw - 4px) - 100%) * 9999 )) / 24px;
	border: 1px solid #e0e0e0;
}

.no-highlights > div:first-child {
	font-size: 24px;
	margin-bottom: 24px;
}

.no-highlights > div:nth-child(2) {
	margin-bottom: 24px;
	font-size: 16px;
}

.no-highlights > div:nth-child(3) {
	cursor: pointer;
	color: #ffffff;
	margin-bottom: 12px;
	display: inline-block;
	background: linear-gradient(45deg, #FF0054, #610888);
	width: fit-content;
	font-size: 13px;
	border-radius: 16px;
	padding: 10px;
	font-size: 16px;
}


.load-more-wrapper {
	padding: 16px 0 24px;
}


.button {
	cursor: pointer;
	font-family: 'Nunito';
	font-weight: 600;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 32px;
	padding: 8px;
	width: 140px;
	border-style: none;
	text-align: center;
}

.load-more-button {
	width: fit-content;
	padding: 12px;
	margin: 0 auto;
}

@media only screen and (max-width: 640px) {
	.profile-info-container {
		border-left: 0px solid #e0e0e0 !important;
		border-right: 0px solid #e0e0e0 !important;
	}
}

</style>
