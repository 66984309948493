<template>
	<div class="display-inline" style="margin-left: 8px">
		<input class="file-drop--input input-paste" placeholder="Paste" @onpaste='pasteImage($event)'>
		<div class="drop align-center" @drop="onDrop" @dragover.prevent>
			<label v-if="!image" class="btn display-inline">
				+
				<input class="file-drop--input" name="image" type="file" @change="onChange">
			</label>
			<div v-else class="hidden display-inline align-center" v-bind:class="{ 'image': true }">
				<img :src="image" alt="" class="img"/>
				<button class="btn" @click="removeFile">REMOVE</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

//import axios from "axios";
import AWS from "aws-sdk";
import {aws} from "../../config.json";

export default {
	name: "FileDrop",
	props: {
		team: {
			type: String,
			default() {
				return "";
			}
		}
	},
	data() {
		return {
			image: ''
		}
	},
	methods: {
		pasteImage(event) {
            console.log(event);
			const items = event.clipboardData.items;
			const file = items[0].getAsFile();
			this.createFile(file);
		},
		onDrop: function (e) {
			e.stopPropagation();
			e.preventDefault();
			const files = e.dataTransfer.files;
			this.createFile(files[0]);
		},
		onChange(e) {
            console.log(e);
			const files = e.target.files;
			this.createFile(files[0]);
		},
		createFile(file) {
			if (!file.type.match('image.*')) {
				alert('Select an image');
				return;
			}
			const reader = new FileReader();
			const that = this;

			reader.onload = function (e) {
				that.uploadFile(e.target.result, function (res) {
					that.$emit('newPlayer', res && res.Location, that.team);
				});
			}
			reader.readAsDataURL(file);

		},
		removeFile() {
			this.image = '';
		},
		async uploadFile(file, cb) {

			AWS.config.update({
				region: aws.s3.region,
				accessKeyId: aws.s3.accessKey,
				secretAccessKey: aws.s3.secretKey
			});

			const buf = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""), 'base64');

			var upload = new AWS.S3.ManagedUpload({
				params: {
					Bucket: aws.s3.bucket,
					Key: Date.now() + ".png",
					Body: buf,
					ContentEncoding: 'base64',
					ContentType: 'image/jpeg'
				}
			});
			var promise = upload.promise();

			promise.then(
				function (res) {
					console.log("Success!");
					cb(res);
				},
				function (err) {
					console.log("There was an error uploading your photo: ", err.message);
				}
			);
		}
	},
	mounted() {
		this.$el.addEventListener('paste', this.pasteImage);
	},
	beforeDestroyed() {
		this.$el.addRemoveListener('paste', this.pasteImage);
	}
};

</script>


<style scoped>

.btn {
	background-color: #d3394c;
	border: 0;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	padding: 12px;
	position: relative;
	border-radius: 100px;
	width: 40px;
	height: 40px;
}

.btn:hover {
	background-color: #722040;
}

input[type="file"] {
	position: absolute;
	opacity: 0;
	z-index: -1;
	left: 0;
}

.align-center {
	text-align: center;
}

.hidden {
	display: none !important;
}

.hidden.image {
	display: inline-block !important;
}

.display-inline {
	display: inline-block;
	vertical-align: top;
}

.img {
	border: 1px solid #f6f6f6;
	display: inline-block;
	height: auto;
	max-height: 80%;
	max-width: 80%;
	width: auto;
}

.drop {
	background-color: #f2f2f2;
	border: 4px dashed #ccc;
	background-color: #f6f6f6;
	border-radius: 2px;
	float: left;
	clear: both;
}

.file-drop--input {
	width: 48px;
	float: left;
}

</style>