<template>
    <div class="video-container" :class="{'shared-play': shared}">
        <div v-if="ranking">
            <img :src="rankingImg" class="ranking-img">
        </div>
        <div class="video-container--info">
            <router-link :to="{name: 'profile', params : { username: publicUsername}}">
                <div :style="{background:'url('+(publicUserPicture || picture)+')',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '50% 50%'}" class="play--profile-picture"></div>
            </router-link>
            <div class="play-description-container" :class="{userFeed}">
                <router-link :to="{name: 'profile', params : { username: publicUsername}}">
                    <div class="play-description"><span>{{ description }} </span> </div>
                </router-link>
                <div class="play-sub-description">
                    <span v-if="arena">
                        <span class="play-sub-description--arena">{{ arena }}</span>
                        <span> - </span>
                    </span>
                    <span v-if="ownsProfile">{{ timeAgo }}</span>
                    <span v-if="!ownsProfile">00:{{ timeMarkerValue ? timeMarkerValue : $t('ownerFeed.play') }} {{ $t('ownerFeed.on_field') }} {{ gameKey.split('-')[2] }}</span>
                    <div class="play-top-5" v-if="top5Users.length > 0">
                        {{$t('feed.featuring')}}: <span v-for="(top5user, i) in top5Users" :key="i"><router-link :to="{name: 'profile', params : { username: top5user}}">@{{top5user}}</router-link><span v-if="i != top5Users.length - 1">, </span></span>&#127942; &#127942; &#127942;
                    </div>
                </div>
				<div class="play-caption" v-if="playerTags">
					<br>
					<i>Top 5 of the week featuring {{ playerTags }} 🏆🏆🏆 </i>
					<br><br>
				</div>
            </div>
            <div class="action-container">
                <!-- <div v-if="!owner" :href="videoUrl" download @click="download(videoUrl)"> -->
                    <div v-if="!owner" class="video-action" @click="download(videoUrl)"><i class="fas fa-download"></i></div>
                <!-- </div> -->
                <div v-if="!owner" id="share-video-link" class="video-action" @click="share(shareLink)">
                    <i class="fas fa-share-alt"></i>
                </div>
            </div>
        </div>
        <video-player :options="{
            autoplay: false,
            controls: true,
            profile: true,
            sources: [
                {
                src: videoUrl,
                type: 'video/mp4'
                }
            ]
        }" v-on:highlight-viewed="highlightViewed"></video-player>
        <div class="sub-video-container">
            <div class="video-views" :style="[!ownsProfile ? {'color': 'grey'} : {'color': 'black'}]">{{ displayedViews }} {{$t('profile.views')}}</div> {{'\xa0\xa0'}}
            <div v-if="!ownsProfile" class="downloads">{{ downloadsCount }} {{$t('ownerFeed.downloads')}}</div>
            <div v-if="ownsProfile" @click="deletePlay(id)" class="delete-play">{{$t('profile.delete_play')}}</div>
            <span v-if="!ownsProfile" class="time-ago">{{ timeAgo }}</span>
        </div>
        <slot></slot>

		<div class="modal-background" :class="{hidden: socialModalHidden}"></div>
		<div class="download-modal social-modal" :class="{hidden: socialModalHidden}">
			<div class="modal-top-info pt-0 fz-lg">
				{{ ownsProfile ? $t('profile.share_my_play') : $t('feed.share_this_game') }}
			</div>
			<div class="modal-body-info">
				<!-- WhatsApp -->
				<span @click="socialIconClicked('whatsapp')">
					<ShareNetwork
						class="social-media-icon whatsapp-icon"
						network="whatsapp"
						:title="$t('videoEditor.share_your_highlight_1') + (ownsProfile ? this.publicUsername : this.username) + $t('videoEditor.share_your_highlight_2')"
						:url="videoClip"
						tag="a"
					>
						<i class="fab fah fa-lg fa-whatsapp"></i>
					</ShareNetwork>
				</span>

				<!-- Facebook -->
				<span @click="socialIconClicked('facebook')">
					<ShareNetwork
						class="social-media-icon facebook-icon"
						network="facebook"
						:title="$t('videoEditor.share_your_highlight_1') + (ownsProfile ? this.publicUsername : this.username) + $t('videoEditor.share_your_highlight_2')"
						:url="videoClip"
						hashtags="myreplay"
						tag="a"
					>
						<i class="fab fah fa-lg fa-facebook-square"></i>
					</ShareNetwork>
				</span>
			</div>
			<div class="modal-bottom-info pb-0">
				<div class="button-container">
					<div class="control-container--button" @click="closeModal">{{$t('videoController.close')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VideoPlayer from './VideoPlayer';
import api from "@/api";
import { mapGetters } from 'vuex';
import {_} from 'vue-underscore';

export default {
	name: 'FeedElement',
	components: {
		VideoPlayer,
	},
    props: {
        picture: {
            type: String,
            default() {
                return require("../assets/myreplayicon.png");
            }
        },
        description: {
            type: String,
            default() {
                return "";
            }
        },
        id: {
            type: String,
            default() {
                return "";
            }
        },
        arena: {
            type: String,
            default() {
                return "MyReplay";
            }
        },
        timeAgo: {
            type: String,
            default() {
                return "";
            }
        },
		playerTags: {
			type: String,
			default() {
				return "";
			}
		},
        ownsProfile: {
            type: Boolean,
            default() {
                return false;
            }
        },
        owner: {
            type: Boolean,
            default() {
                return false;
            }
        },
        userFeed: {
            type: Boolean,
            default() {
                return false;
            }
        },
        videoUrl: {
            type: String,
            default() {
                return "";
            }
        },
        shareLink: {
            type: String,
            default() {
                return window.location.href;
            }
        },
        views: {
            type: Number,
            default() {
                return 0;
            }
        },
        shared: {
            type: Boolean,
            default() {
                return false;
            }
        },
        publicUsername: {
            type: String,
            default() {
                return "MyReplay User";
            }
        },
        top5Users: {
            type: Array,
            default() {
                return [];
            }
        },
        ranking: {
            type: Number,
            default() {
                return 0;
            }
        },
        timeMarker: {
            type: Number,
            default() {
                return 0;
            }
        },
        gameKey: {
            type: String,
            default() {
                return "";
            }
        },
        downloads: {
            type: String,
            default() {
                return "[]";
            }
        },
        type: {
            type: String,
            default() {
                return "";
            }
        }
    },
	mounted() {
        const that = this;

        if (!this.ownsProfile) {
            if (!this.publicUsername) {
                console.log("Missing username for public data fetching");
            } else {
                // Look for cached user data before fetching user again
                const publicUser = this.publicUsers.find(u => u.username === this.publicUsername);

                if (publicUser) {
                    that.publicUserPicture = publicUser.picture
                    that.publicUser = publicUser;
                } else {
                    api.fetchUser("username", this.publicUsername, function (err, u) {
                        if (u == null) {
                            console.log("user not found 1", u)
                        } else {
                            that.publicUserPicture = u.picture;
                            that.publicUser = u;
                            that.$store.commit('ADD_PUBLIC_USER', u);
                        }
                    });
                }

                
            }
        }
	},
	data() {
		return {
			publicUserPicture: null,
            publicUser: null,
            computedViews: null,
			socialModalHidden: true,
			videoClip: "",
            downloadCount: 0,
		}
	},
	computed: {
        ...mapGetters([
			'publicUsers',
			'username'
		]),
        displayedViews: {
            get() {
                return this.computedViews || this.views;
            },
            set(newValue) {
                this.computedViews = (this.computedViews || this.views) + newValue;
            }
        },
        rankingImg() {
            if (this.ranking) {
                return require(`../assets/${this.ranking}.png`)
            } else {
                return "";
            }
        },
        timeMarkerValue() {
            return this.timeMarker ? this.convertHMS(this.timeMarker) : '';
        },
        downloadList: {
            get() {
                return this.downloads;
            },
            set(newValue) {
                this.downloads = newValue;
            }
        },
        downloadsCount() {
            return JSON.parse(this.downloadList).length + this.downloadCount;
        }
	},
	methods: {
        download(url) {
            this.myReplaySlackHook(
                "log-users",
                (this.ownsProfile ? "DOWNLOAD_PROFILE" : "DOWNLOAD_FEED"),
                "- User: " + (this.ownsProfile ? this.publicUsername : this.username) +
                "\n- URL: " + url, null, this.arena
            );

            console.log("-------",this.ownsProfile)
            if (!this.ownsProfile) {
                let data = JSON.parse(this.downloadList);
                data.push({ username: this.username, timestamp: new Date() });
                this.downloadCount += 1
                console.log("**********",this.downloadCount, data)
                //this.downloadList = JSON.stringify(data); BAD PRACTICE BECAUSE WE ARE MUTATING A PROP
                console.log("new balue ", this.downloadsCount)
                console.log("|||||||||||",this.downloadList, data)
                api.updateVideoDownloads(this.id, { "Downloads": JSON.stringify(data) }, function(err, record) {
                    if (err && record == null) this.myReplaySlackHook('log-users', 'FeedElement - updateVideoDownloads', err);
                });
            }
                    
            if (this.type == "button") {
                this.$emit('download', this.videoUrl, true);
            } else {
                this.downloadVideoFile(this.videoUrl, "my-replay-clip.mp4");
                this.$emit('download', this.videoUrl, false);
            }
        },
		highlightViewed(url) {
            this.$emit('highlight-viewed', url);

            if (!this.ownsProfile) {
                this.displayedViews = 1;

                this.myReplaySlackHook("log-users", "INCREMENT_VIEWS | " + "(" + (this.ownsProfile ? this.publicUsername : this.username) + ")", url, true)

                api.updateVideoAssetStatus(this.id, {
                    "Views": (this.computedViews || 0)
                }, () => {});
            }
		},
		share(url) {
			this.myReplaySlackHook(
				"log-users",
				(this.ownsProfile ? "PROFILE_SHARING_POPUP" : "FEED_SHARING_POPUP"),
                "- User: " + (this.ownsProfile ? this.publicUsername : this.username) +
                "\n- URL: " + url, null, this.arena

            );
            this.shareWithoutSweetalert(url);
            this.videoClip = url;
            this.socialModalHidden = false;
		},
		deletePlay(id) {
            const that = this;
			this.$swal({
				title: this.$i18n.messages[this.$i18n.locale].profile.delete_confirm,
				showCancelButton: true,
				confirmButtonColor: '#FF0054',
                heightAuto: false,
			}).then((result)  => {
				if (result.isConfirmed) {
					api.updateVideoAssetStatus(id, {"HideFromProfile": "true"}, function(err) {
                        if (!err) {
                            that.$emit('delete-play', id);
                        }
                    });
				}
			});
		},
		closeModal() {
			this.socialModalHidden = true;
		},
		socialIconClicked(media) {
            if (media === "whatsapp")
                this.myReplaySlackHook(
                    "log-users",
                    (this.ownsProfile ? "PROFILE_SHARING_WHATSAPP_LINK" : "FEED_SHARING_WHATSAPP_LINK"),
                    "- User: " + (this.ownsProfile ? this.publicUsername : this.username) +
                    "\n- URL: " + this.videoClip, null, this.arena
                );
			else
                this.myReplaySlackHook(
                    "log-users",
                    (this.ownsProfile ? "PROFILE_SHARING_FACEBOOK_LINK" : "FEED_SHARING_FACEBOOK_LINK"),
                    "- User: " + (this.ownsProfile ? this.publicUsername : this.username) +
                    "\n- URL: " + this.videoClip
                );
		}
	},
    watch: {
        publicUsers: _.debounce(function(newVal) {
            console.log(newVal && newVal.map(x => x.username));

            const thisUser = newVal && newVal.find(u => u.username === this.publicUsername);

            if (thisUser) {
                this.publicUser = thisUser; // update the reference to the right user
            }
        }, 300)
    }
}
</script>

<style src="../assets/reset.css"></style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');

.video-container {
	border: 1px solid #e0e0e0;
	margin: 12px 0;
	padding: 12px 0;
	width: 100%;
	border-radius: max(0.0001px, min(24px, ((100vw - 4px) - 100%) * 9999 )) / 24px;
	background: #fff;
}

.video-container.shared-play {
	border: 4px dotted #762998;
}

.video-container--info {
	margin: 0 12px 6px;
}

.ranking-img {
    height: 32px;
    margin: 2px auto 12px;
    display: block;
}

.sub-video-container {
	margin: 12px 16px 0px;
	font-size: 14px;
}

.video-views {
	display: inline-block;
}

.downloads {
	display: inline-block;
    color: black;
}

.delete-play {
	display: inline-block;
	float: right;
	cursor: pointer;
	text-decoration: underline;
}

.time-ago {
	display: inline-block;
	float: right;
}

.play--profile-picture {
	height: 38px;
	width: 38px;
	background-size: cover !important;
	border-radius: 32px;
	display: inline-block;
    vertical-align: top;
}

.play-description-container {
	display: inline-block;
	padding-left: 12px;
	max-width: calc(85% - 90px);
    text-decoration-color: #FF0054;
}

.play-description-container.userFeed {
    max-width: calc(85% - 50px);
}

.play-description {
	margin-bottom: 8px;
    color: black;
}

.play-description > span {
	font-weight: 600;
}

.play-sub-description {
	margin-bottom: 10px;
	font-size: 12px;
}

.play-sub-description--arena {
	font-weight: 600;
}

.play-top-5 {
    margin-top: 16px;
    font-size: 14px;
}

.action-container {
	display: inline-block;
	vertical-align: top;
	float: right;
}

.video-action {
	display: inline-block;
	width: 36px;
	height: 36px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 36px;
	text-align: center;
	line-height: 36px;
	color: #fff;
	cursor: pointer;
	vertical-align: top;
	margin-left: 12px;
	transition: transform 0.3s ease-in-out;
	transform: scale(1.0);
}

.video-action:hover {
	transform: scale(1.1);
}

.modal-background {
	background: rgba(0, 0, 0, 0.66);
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	pointer-events: auto;
	display: auto;
}

.download-modal {
	width: 90%;
	height: 400px;
	background: #fff;
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 12px;
	pointer-events: auto;
	display: auto;
}

.social-modal {
	width: 450px;
	height: 250px;
}

.download-modal.hidden, .modal-background.hidden {
	display: none;
	pointer-events: none;
}

.modal-top-info, .modal-bottom-info, .modal-body-info {
	font-family: Nunito;
	text-align: center;
	width: 100%;
	position: absolute;
	padding: 24px;
	font-size: 20px;
}

.modal-top-info {
	top: 10%;
}

.modal-bottom-info {
	bottom: 10%;
}

.pb-0 {
	padding-bottom: 0px;
}

.pt-0 {
	padding-top: 0px;
}

.fz-lg {
	font-size: 24px;
}

.modal-body-info {
	top: 18%;
	margin-top: 10px;
	text-align: center;
	align-items: center;
}

.social-media-icon {
	font-size: 48px;
}

.whatsapp-icon {
	padding-right: 20px;
	color: #25d366;
}

.facebook-icon {
	color: #1877f2;
}

@media only screen and (max-width: 640px) {
	.video-container, .profile-info-container, .no-highlights {
		border-left: 0px solid #e0e0e0 !important;
		border-right: 0px solid #e0e0e0 !important;
	}
}
</style>
