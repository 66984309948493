<template>
	<div id="play-viewer">
        <video oncontextmenu="return false;"
            ref="videoPlayer"
            class="video-js vjs-theme-city vjs-big-play-centered vjs-16-9 rounded-video"
        >
        </video>
	</div>
</template>

<script>
import videojs from 'video.js';

export default {
	name: 'PlayViewer',
	components: {
	},
	data() {
	
		return {
            player: null,
            src: `${this.getCloudProviderBase(true)}/users-clips/${this.$route.params.footageId}`
		}
	},
	computed: {
		videoOptions: function () {
			return {
				autoplay: false,
				controls: true,
				controlBar: {
					'pictureInPictureToggle': false
				},
				sources: [
					{
						src: this.src,
						type: "video/mp4"
					}
				]
			}
		},
	},
	watch: {
        
	},
	methods: {
	},
	mounted() {

		console.log(this.$refs.videoPlayer)
        const video = videojs(this.$refs.videoPlayer, this.videoOptions);
        video.playsinline(true);
        this.player = video;
	}
}
</script>

<style src="../assets/reset.css">

</style>


<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');

body {
	background: #efeff1 !important;
	height: 100%;
}

#play-viewer {
	background: #212121;
	position: absolute;
	overflow: hidden;
	min-height: 100vh;
    z-index: 15;
    width: 100%;
    top: 0;
    opacity: 1;
    pointer-events: auto;
}
</style>