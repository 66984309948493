import mock from './mock';
import server from './server';

let api = server;

// technical debt: shouln't have to hardcode the mode, it's defiting the purpose. How to make this dynamic?
if (process.env.VUE_APP_DATA_SOURCE === "mock") {
	api = mock;
}

export default api;
