<template>
	<div class="control-container">

		<div v-if="previewGame">
			<div class="button--full-game" :class="{disabled: !gameReady}" @click="goToFullGame">{{$t('videoController.cta')}}</div>
		</div>
		<div v-else>
			<div class="control-container--title">{{ $t('videoController.title') }}</div>
			<!-- <a v-on:click="download" :style="{pointerEvents: (link ? 'auto' : 'none')}" download id="download-link" :href="link">
				<div class="control-container--button"><i class="fas fa-download"></i> Download Play</div></a> -->
			<!-- <div v-on:click="share" class="control-container--button" id="share-link"><i class="fas fa-share-alt"></i> Share Play</div> -->

			<div class="control-container--button-container">
				
				<div v-if="!purchased" class="button-container">
					<div class="button--purchase" @click="$parent.paywall()">{{$t('videoController.purchase_cta')}}</div>
				</div>
				
				<div class="button-container">
					<!-- todo: feature-toggle
					<div class="submit-explainer">{{$t('videoController.submit_play')}} &#128071;</div>
					-->
					<div id="save-link" class="control-container--button" :class="{disabled: !purchased}" v-on:click="save(15)"><i class="fas fa-cut"></i> {{$t('videoController.download_15')}}</div>
					<br><span>{{$t('videoController.trim_explainer')}}</span>
				</div>
				<div class="button-container" style="vertical-align:top">
					<div v-if="purchased" class="control-container--button" @click="$parent.shareCode()"><i class="fas fa-share"></i> {{$t('videoController.share_game')}}</div>
				</div>
				
			</div>
			
		</div>


		<div class="modal-background" :class="{hidden: modalHidden}"></div>
		<div class="download-modal" :class="{hidden: modalHidden}">
			<div class="modal-top-info">{{$t('videoController.modal_info')}}</div>
			<img class="loading-gif" src="../assets/loading.gif">
			<div class="modal-bottom-info">{{$t('videoController.wait')}}</div>
		</div>
	</div>
</template>

<script>

import { en, es, fr } from "../data/locals.json";

export default {
	name: 'VideoController',
	props: {
		link: {
			type: String,
			default() {
				return "";
			}
		},
		curTime: {
			type: Number,
			default() {
				return 0;
			}
		},
		preview: {
			type: Boolean,
			default() {
				return false;
			}
		},
		gameReady: {
			type: Boolean,
			default() {
				return false;
			}
		},
		purchased: {
			type: Boolean,
			default() {
				return false;
			}
		},
		subscribed: {
			type: Boolean,
			default() {
				return false;
			}
		}
	},
	data() {
		const preview = this.preview;
		const user = this.$parent.$store.getters;
		
		return {
			modalHidden: true,
			previewGame: preview,
			username: user.username
		}
	},
	methods: {
		save(length) {

			/*this.myReplaySlackHook(
				"log-users",
				"TRIMMING | User: " + this.username,
				"\n\t" + this.link
			);*/

			if (!this.purchased) {
				let message = "";
				if (this.$i18n.locale === "fr") {
					message = fr.search.plays_available; 
				} else if (this.$i18n.locale === "es") {
					message = es.search.plays_available;
				} else {
					message = en.search.plays_available;
				}

				this.$swal({
					title: message,
					heightAuto: false,
				});
				return;
			}

			console.log(length, this.link, this.curTime);

			this.$parent.$emit('showEditor')

		},
		goToFullGame() {
			const params = this.$route.params;
			const footageId = params && params.footageId;
			if (this.gameReady) {
				// Catch error thrown by vue router for blocking this
				this.$router.push({name: "footage", params: {footageId}}).catch(() => {});
			} else {
				let message = "";

				const currentDate = new Date();
				var videoDate = this.getDateObject(footageId.split('-')[0], "0000");
				var hours = Math.abs(currentDate.getTime() - videoDate.getTime()) / 36e5;

				// urban indoor footable field 2 takes 2.5h in average...
				if (hours > 3) {
					if (this.$i18n.locale === "fr") {
						message = fr.search.sorry_message; 
					} else if (this.$i18n.locale === "es") {
						message = es.search.sorry_message;
					} else {
						message = en.search.sorry_message;
					}

					/*setTimeout(
						function () {
							window.Intercom('showNewMessage', 'Sorry for the inconvenience.\nPlease share with us this link (' + window.location.href.toString() + ') for us to diagnostic and help improve the experience.')
						},
						1500
					);*/
				} else {
					if (this.$i18n.locale === "fr") {
						message = fr.search.full_game_processing; 
					} else if (this.$i18n.locale === "es") {
						message = es.search.full_game_processing;
					} else {
						message = en.search.full_game_processing;
					}
				}

				this.$swal({
					title: message,
					heightAuto: false,
				});

				const date = new Date();
				const numMinutesWait = (((15 - date.getMinutes()) % 60) + 60) % 60;
				const delay = Math.ceil(numMinutesWait / 15) * 15;
				this.$store.commit("SET_GAME_REMINDER", {link: window.location.href, delay});				
			}
		}
	},
	mounted() {
		const path = this.$route.path;
		const that = this;
		if (path.includes("preview")) {
			that.previewGame = true;
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.control-container {
	width: calc(100% - 32px);
	flex: 1 1 auto;
	width: 100%;
	background: #fff;
	padding: 32px;
	margin: 16px 0px 16px 0px;
	border-radius: 24px;
	box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
}

.control-container--title {
	font-family: 'Nunito';
	font-weight: 700;
	font-size: 22px;
	margin: 0px 0px 8px;
	text-align:center;
}


.button-container {
	display: inline-block;
	width: calc(50% - 8px);
	margin: 0 4px;
	text-align:center
}

.control-container--button {
	cursor: pointer;
    font-family: 'Nunito';
    color: #ffffff;
    background: linear-gradient(45deg, #FF0054, #610888);
    border-radius: 22px;
    padding: 12px;
    margin: 16px auto 0px;
    font-size: 20px;
    text-align: center;
    width: fit-content;
}

.control-container--button.disabled {
	background: linear-gradient(45deg, #9e9e9e, #BDBDBD);
}

.modal-background {
	background: rgba(0,0,0,0.66);
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	pointer-events: auto;
	display: auto;
}

.download-modal {
	width: 400px;
	height: 400px;
	background: #fff;
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 12px;
	pointer-events: auto;
	display: auto;
}

.download-modal.hidden, .modal-background.hidden {
	display: none;
	pointer-events: none;	
}

.loading-gif {
	width: 200px;
	height: 200px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.modal-top-info, .modal-bottom-info {
	font-family: Nunito;
	text-align: center;
	width: 100%;
	position: absolute;
	padding: 24px;
	font-size: 20px;
}

.modal-top-info {
	top: 10%;
}

.modal-bottom-info {
	bottom: 10%;
}

.button--full-game, .button--purchase {
	margin: 0 auto;
    font-size: 18px;
    cursor: pointer;
	font-family: 'Nunito';
	font-weight: 600;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 22px;
	padding: 8px;
	max-width: 300px;
	border-style: none;
	text-align: center;
}

.button--purchase {
	font-size: 18px;
    width: auto;
    max-width: 300px;
    margin: 16px auto;
    padding: 12px;
}

.button--full-game.disabled {
	background: linear-gradient(45deg, #9e9e9e, #BDBDBD);
}

.submit-explainer {
	text-align: center;
    font-size: 14px;
    font-family: Nunito;
    max-width: 120px;
    margin: 8px auto -8px;
}

@media only screen and (max-width: 980px) {
	.control-container {
		font-size: 20px;
		padding: 16px;
	}
	
	.control-container--title {
		font-size: 18px;
	}

	.button--purchase {
		font-size: 16px;
		margin: 12px auto;
		font-weight: 400;
	}

	.control-container--button {
		font-size: 16px;
		margin: 12px auto 0px;
	}
}
</style>
