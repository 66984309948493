<template>
	<div id="game-search">
		<replay-header></replay-header>
        <!-- <div class="live-again">{{$t('search.live_again')}}</div> -->
        <router-link :to="{name: 'userFeed', params : { arena: selectedDome && selectedDome.key}}"><div @click="log(selectedDome && selectedDome.key)" class="user-feed--button button"><i class="fas fa-arrow-left"></i> {{$t('search.view_feed')}}</div></router-link>
        <div class="search-filters">
            <div class="search-filters--title">{{$t('search.title')}}</div>

            
            <div class="search-filters--options">
                <!-- <div class="purchased-filter" :class="{selected: purchaseFilter}" @click="filterPurchase">{{$t('search.purchased')}}</div> -->
                <dropdown class="adjust" :title="selectedDome && selectedDome.name" :list="domes" @select="selectDome" :gameSearch=true></dropdown>
                <datepicker @selected="selectDate" input-class="datepicker-input" class="datepicker adjust" :value="latestGameDate" calendar-class="datepicker-calendar" :highlighted="highlighted"></datepicker>
				<dropdown class="adjust" :title="time && time.name " :list="hours" :fitContent="true" @select="selectTime" :gameSearch=true></dropdown>
                
            </div><br>
            <h3 v-if="selectedDome && selectedDome.name == 'Urban Indoor Futbol'" class="search-filters--title fz-15"><a href="https://chat.whatsapp.com/HQgNh964HC5GlCjOxupuhT" target="_blank" style="background:chartreuse">NEW: JOIN THE MYREPLAY & URBAN WHATSAPP GROUP</a></h3>
            <div class="recording-timings" v-if="schedule"><br>{{ schedule }}</div> <!-- +1 (786) 688-2447 https://stackoverflow.com/questions/30344476/web-link-to-specific-whatsapp-contact-->
        </div>
        <div :id="banner.id + '-banner-' + selectedDome.key" v-for="(banner, i) in bannersList" :key="i" class="search-filters close-icon">
            <span @click="closeBanner(banner.id)"><i :title="$t('search.hide')" class="fa fa-times-circle"></i></span>
            <div class="search-filters--title fz-15">{{ banner.content.text }}</div>
            <span v-if="banner.content.image">
                <img class="banner-image" :src="banner.content.image" alt="" />
            </span>
        </div>
        <div class="search-wrapper">
            <loading-gif :showGif="loading"></loading-gif>
            <game-search-list :games="games"></game-search-list>
            <div v-if="games.length === 0 && !loading" class="game-preview">
                <div class="no-games">
                    {{selectedDome? (purchaseFilter ? $t('search.no_purchased_games') : $t('search.no_games')) : $t('search.no_selected_dome')}}
                </div>
            </div>
            <div class="load-more-wrapper">
                <div @click="loadMore" v-if="paginationLength < allGamesAvailable.length" class="button load-more-button">
                    {{$t('search.load_more')}}
                </div>
            </div>
        </div>
        <segment-modal :showModal="showSegmentModal" :domes="domes" @domeSelected="selectDome" @hideSegmentModal="hideSegmentModal"></segment-modal>
	</div>

</template>

<script>
import ReplayHeader from './ReplayHeader';
import GameSearchList from './GameSearchList';
import SegmentModal from './SegmentModal';
import api from '../api/server.js';
import { app } from "/config.json";
import Datepicker from 'vuejs-datepicker';
import { mapGetters } from 'vuex';
import LoadingGif from './LoadingGif.vue';
import Dropdown from './Dropdown.vue';
import { DaysEnum } from '../helpers/Utils.js';
import { en, es, fr } from '../data/locals.json';


export default {
	name: 'GameSearch',
	components: {
		ReplayHeader,
        GameSearchList,
        Datepicker,
        SegmentModal,
        LoadingGif,
        Dropdown,
	},
	mounted() {
        if (this.$route.query && this.$route.query["arena"]) {
            this.selectDome(this.findDomeByKey(this.$route.query["arena"]));
        }

        const segment = this.$store.getters.userMetadata.segment;
        if (segment) {
            this.selectDomeBySegment(segment);
        }
	},
	data() {
        var state = {
            date: new Date()
        }
		return {
            allGames: [], // This is always all of the data available for games
            state,
            paginationLength: 10,
            domes: this.getDomeList().filter(x => x.key !== "test"),
            selectedDome: null,
            selectedDate: null,
            purchaseFilter: false,
            showSegmentModal: false,
            loading: false,
            banners: [],
            schedules: [],
            time: { "name": "Select Time", "key": "0" },
			hours: []
		}
	},
	computed: {
        allGamesAvailable: function() {
            const that = this;

            return this.allGames.filter(x => {
                let keepGame = true;
                if (that.selectedDate) {
                    const diff = that.daysBetween(new Date(new Date( new Date(that.selectedDate).setHours(0) ).setMinutes(0)), new Date(x.jsDate));
                    keepGame = keepGame && (diff < 1 && diff >= 0);
                }
                if (that.selectedDome) {
                    keepGame = keepGame && (x.dome == that.selectedDome.name)
                }
                if (that.purchaseFilter) {
                    keepGame = keepGame && (this.subscriptions.length > 0 || !!this.purchases.find(y => y.footageDate === x.paymentDateString));
                }
                if (that.time.key != "0") {
                    keepGame = keepGame && (x.time == that.time.key);
                }
                return keepGame;
            });
        },
        games: function() {
            if (this.allGamesAvailable && this.allGamesAvailable[0])
                api.fetchGameSiblingsByDate(this.allGamesAvailable[0].domeKey, this.allGamesAvailable[0].yyyymmdd, this.allGamesAvailable[0].time, (err, res) => {
                    if (err) this.myReplaySlackHook('log-errors', 'Game Search Page - fetchGameSiblingsByDate', err);
                    if (!err && res) {
                        var obj = this.setHoursAndFields(res);
                        this.hours = obj.hours;
                        this.hours.unshift({ "name": "Select Time", "key": "0" });
                    }
                });

            return this.allGamesAvailable.slice(0, this.paginationLength);
        },
        ...mapGetters([
			'purchases',
            'subscriptions',
            'user',
            'userMetadata',
            "username"
		]),
        highlighted() {
            return {dates: this.purchases.map(x => new Date(x.footageDate + "T00:00:00"))}
        },
        currentBanner: function() {
            return this.getCurrentBanners("search", this.selectedDome.key, this.selectedDate, this.banners);
        },
        schedule: function() {
            let index = 0, message = "";
            // After you merge previous PRs, then I would see about this
            // let date = this.selectedDate;
            let date = new Date();
            const i18n = this.$i18n;

            function buildString(day, start, duration) {
                duration += start;
                let start_meridiem = "am", end_meridiem = "am";
                if (start === 0) {
                    start = 12;
                } else if (start > 11) {
                    start -= 12;
                    start_meridiem = "pm";
                }

                // Discuss: 1. lets say if start time is 21 (9pm) and the duration is 4,
                // then the end time time would add upto 25 => 1am of next day - Do you want to handle this Case or is this an invalid scenario?
                if (duration > 12) {
                    duration -= 12;
                    end_meridiem = "pm";
                }
                if (i18n.locale === "fr") {
                    return fr.search.recordings_schedule_1 + fr.weekdays[DaysEnum[day]] + " " + date.getDate() + ", " + fr.search.recordings_schedule_2 + start + start_meridiem + fr.search.recordings_schedule_3 + duration + end_meridiem + ".";
                } else if (i18n.locale === "es") {
                    return es.search.recordings_schedule_1 + es.weekdays[DaysEnum[day]] + " " + date.getDate() + "," + es.search.recordings_schedule_2 + start + start_meridiem + es.search.recordings_schedule_3 + duration + end_meridiem + ".";
                } else {
                    return en.search.recordings_schedule_1 +  en.weekdays[DaysEnum[day]] + " " + date.getDate() + "th, " + en.search.recordings_schedule_2 + start + start_meridiem + en.search.recordings_schedule_3 + duration + end_meridiem + ".";
                }
            }

            for (let n = 0; n < this.schedules.length; n++) {
                if (Object.keys(this.schedules[n])[0] === this.selectedDome.key) {
                    index = n;
                    break;
                }
            }
            if (this.schedules.length && this.schedules[index][this.selectedDome.key]) {
                for (let i = 0; i < this.schedules[index][this.selectedDome.key].length; i++) {
                    let obj = this.schedules[index][this.selectedDome.key][i];
                    for (let j = 0; j < obj["days"].length; j++) {
                        // The value returned by getDay() is an integer corresponding to the day of the week:
                        // 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.
                        if (obj["days"][j] === (date.getDay() === 0 ? 7 : date.getDay())) {
                            message = buildString(obj["days"][j], obj["start"], obj["duration"]);
                            break;
                        }
                    }
                }
            }
            return message;
        },
        latestGameDate: function() {
            if (this.games && this.games[0] && this.games[0]["jsDate"])
                return this.games[0]["jsDate"];
            else
                return new Date();
        },
        bannersList: function() {
            return this.getBannersList("search", this.currentBanner, this.selectedDome.key, this.username);
        }
	},
    watch: {
        user: {
			handler() {
                const segment = this.userMetadata.segment;
				if (!segment) {
					this.showSegmentModal = true;
				} else {
                    this.showSegmentModal = false;
                    this.selectDomeBySegment(segment);
                }
			},
			immediate: true,
            deep: true,
        }
    },
	methods: {
        async log(dome) {
            console.log(dome)
            //let ip = await this.getClientIP()
			/*this.myReplaySlackHook(
				"log-users",
				"VIEW_TOP_PLAY | User: " + this.username + " (" + ip + "), Arena: " + dome, ""
			);*/
		},
        selectDate(dateInput) {
            this.selectedDate = dateInput;
            this.purchaseFilter = false;
            this.paginationLength = 10;
            this.fetchGames(this.selectedDome, dateInput);
            this.fetchBanner();
            this.fetchSchedules();
        },
        selectDome(dome) {
            this.fetchGames(dome, this.selectedDate);
            this.fetchBanner();
            this.fetchSchedules();
            this.selectedDome = dome;
            this.paginationLength = 10;
            this.$store.commit("SET_USER_SEGMENTATION", dome.key);
        },
		selectTime(time) {
            this.fetchGames(this.selectedDome, this.selectedDate);
            this.time = time;
		},
        selectDomeBySegment(segment) {
            let dome = this.findDomeByKey(segment)
            if (!dome.key) {
                dome = this.findDomeByName(segment);
            }
            if (!dome.key) return;
            this.selectDome(dome);
        },
        filterPurchase() {
            this.purchaseFilter = !this.purchaseFilter;
        },
        loadMore() {
            this.paginationLength = this.paginationLength + 10;
        },
        hideSegmentModal() {
            this.showSegmentModal = false;
        },
        fetchGames(dome, date) {
            const that = this;
            that.allGames = [];
            this.loading = true;
            
            const fetchCallback = (err, res) => {

                that.loading = false;
                if (err) {
                    this.myReplaySlackHook('log-errors', 'Game Search Page - ERROR FETCHING GAMES', err);
                } else if (res) {

                    const gameList = []
                    const previewDict = {}
                    const finalDict = {}
                    const newThumbnailDict = {}

                    res.forEach(x => {
                        if (x.Key.includes('thumbnail.png')) {
                            // The list of thumbnails is the list of games (1 per game)
                            gameList.push(x);
                        // Then construct dictionaries for reference    
                        } else if (x.Key.includes('preview')) {
                            previewDict[x.Key] = true;
                            previewDict[x.Key.replace(app.filenames.userPreview, app.filenames.preview)] = true;
                            // This is to say the game is a preview, whether it has preview.mp4 or user-preview.mp4
                        } else if (x.Key.includes('final')) {
                            finalDict[x.Key] = true;
                        } else if (x.Key.includes('jpg')) {
                            newThumbnailDict[x.Key] = true;
                        }
                    });

                    const games = gameList.sort((a, b) => b.Key.localeCompare(a.Key));

                    that.allGames = games.map(x => {
                        // Prepare data for each game
                        // Done by reference
                        this.setGameDataFromAWSObject(x);
                        // Check if final exists or if preview exists
                        if (newThumbnailDict[x.Key.replace(app.filenames.thumbnail, app.filenames.newThumbnailPrefix + "0" + app.filenames.newThumbnailExt)]) {
                            this.setGameDataFromAWSObject(x, true);
                        } else {
                            this.setGameDataFromAWSObject(x);
                        }
                        x.preview = false; //!finalDict[x.Key.replace(app.filenames.thumbnail, app.filenames.final)] && previewDict[x.Key.replace(app.filenames.thumbnail, app.filenames.preview)]
                        return x;
                    });
                }
            };

            if (!date) {
                api.fetchGames(dome.key, fetchCallback);
            } else if (date) {
                api.fetchGamesByDate(dome.key, this.yyyymmdd(date), fetchCallback);
            }
        },
        fetchBanner() {
            const fetchCallback = (err, res) => {
                if (err)
                    this.myReplaySlackHook('log-errors', 'Game Search Page - ERROR FETCHING BANNER', err);
                else if (res)
                    this.banners = JSON.parse(JSON.stringify(res.data));
            }
            api.fetchBanner(fetchCallback);
        },
        fetchSchedules() {
            const fetchCallback = (err, res) => {
                if (err)
                    this.myReplaySlackHook('log-errors', 'Game Search Page - ERROR FETCHING SCHEDULES', err);
                else if (res)
                    this.schedules = JSON.parse(JSON.stringify(res.data));
            }
            api.fetchSchedules(fetchCallback);
        },
        closeBanner(id) {
            localStorage.setItem(id + '-banner-' + this.selectedDome.key + '-' + (this.username ? this.username : ''), 'hide');
            document.getElementById(id + '-banner-' + this.selectedDome.key).style.display = "none";

            this.myReplaySlackHook(
                "log-users",
                "SEARCH-BANNER-CLOSE",
                "- User: " + this.username +
                "\n- Banner_id: " + id
            );
        }
	}
}
</script>

<style src="../assets/reset.css">

</style>

<style>
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.datepicker {
    font-family: 'Nunito';
    text-align: center !important;
    margin-top: 6px;
}

.datepicker-input {
    text-align: center;
    background: linear-gradient(45deg, #FF0054, #610888) !important;
    border: none;
    border-radius: 24px;
    background: #eee;
    padding: 8px;
    font-family: 'Nunito';
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
}

.datepicker-input:focus {
    box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
}

.datepicker-calendar {
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
}

.vdp-datepicker__calendar .cell.highlighted {
    background: initial !important;
    border-radius: 50% !important;
    border: 4px solid #e73466 !important;
    line-height: 34px !important;
}

.vdp-datepicker__calendar .cell.selected {
    background: rgb(122, 48, 155, 0.68) !important;
}



</style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');

body {
	background: #fff !important;
}

.adjust {
    margin: 0px !important;
    margin-top: 4px !important;
}

#game-search {
	background: #efeff1;
    min-height: 100vh;
}

.search-wrapper {
	max-width: 500px;
	margin: auto;
}

.search-filters {
    width: 500px;
    max-width: 95%;
    background: #fafafa;
    border-radius: 12px;
    margin: 12px auto;
    padding: 16px;
    box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
    text-align: center !important;
}

.search-filters--options {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.purchased-filter {
    font-family: 'Nunito';
    text-align: center;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    border-radius: 24px;
    padding: 9px 12px;
    font-weight: 600;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
    margin-top: 6px;
}

.purchased-filter.selected {
    padding: 7px 10px;
    border: 2px solid #762998;
    box-sizing: border-box;
    color: #762998;
}

.live-again {
    width: 500px;
    max-width: 95%;
    border-radius: 12px;
    margin: 12px auto;
    padding: 16px;
    box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
    text-align: center !important;
    font-family: Nunito;
    font-weight: 600;
    background: #388e3c;
    color: #fff;
}

.search-filters--title {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Nunito';
    margin-bottom: 6px;
}

.game-preview {
    position: relative;
    width: 500px;
    background: #fafafa;
    border-radius: 12px;
    margin: 12px 0;
    padding: 16px;
    cursor: pointer;
    box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
}

.preview-tag {
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    background: #610888;
    position: absolute;
    top: 24px;
    right: 36px;
    z-index: 1;
    padding: 6px;
    font-family: 'Nunito';
}

.game-preview img {
    width: 100%;
    display: inline-block;
    border-radius: 12px;
}

.game-preview-info {
    width: 160px;
    display: inline-block;
    vertical-align: top;
    font-family: Nunito;
    color: #000;
    line-height: 22px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-right-radius: 12px;
    background: #fafafa;
    padding: 12px;
}

.dome-name {
    font-size: 20px;
    font-weight: 600;
}

.user-feed--button {
    padding: 8px 32px !important;
    width: fit-content !important;
    margin: 16px auto 4px !important;
    display: block;
}

.button {
    cursor: pointer;
	font-family: 'Nunito';
	font-weight: 600;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 32px;
	padding: 8px;
	width: 140px;
	border-style: none;
	text-align: center;
}

.view-game-button {
    margin-top: 64px;
}

.load-more-wrapper {
    padding: 16px 0 24px;
}

.load-more-button {
    width: fit-content;
    padding: 12px;
    margin: 0 auto;
}

.no-games {
    font-family: Nunito;
    font-weight: 600;
    text-align: center;
}

.recording-timings {
    font-size: 15px;
	font-style: italic;
    font-family: 'Nunito';
    margin-top: 10px;
}

.banner-image {
    padding-top: 5px;
    max-width: 100%;
    max-height: 100%;
}

.fz-15 {
    font-size: 15px;
}
.close-icon {
    position: relative;
}

.close-icon .fa-times-circle {
    position: absolute;
    top: -5px;
    right: -5px;
    color: rgb(158 158 158);
    cursor: pointer;
}

.close-icon .fa-times-circle:hover {
    color: grey;
}

@media only screen and (max-width: 639px) {
    .view-game-button {
        margin: 2px auto 8px;
        display: block;
    }
    .game-preview {
        text-align: center;
        width: auto;
    }
    .search-wrapper {
        max-width: 95%;
    }
    .search-filters {
        width: auto;
    }
    .game-preview img {
        width: 100%;
        display: block;
    }
    .preview-tag {
        top: 144px;
    }
}

</style>

<style>
@media only screen and (max-width: 639px) {
    .datepicker-input {
        max-width: 130px;
    }
}
</style>
