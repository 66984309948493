import Vue from 'vue/dist/vue.js';
import VueI18n from 'vue-i18n'
import Vuex from 'vuex';
import VueRouter from 'vue-router';

import underscore from 'vue-underscore';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import Toasted from 'vue-toasted';
import auth0Plugin from '@/plugins/auth';
import Router from '@/router/router';
import store from './store/store';
import Meta from 'vue-meta';
import { Utils } from './helpers/Utils.js';
import messages from './data/locals.json';
import axios from "axios";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { mapGetters } from 'vuex';
import VueSocialSharing from 'vue-social-sharing';
import './helpers/errorHandlers.js';

// setup dayjs
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault("EST")

Vue.config.devtools = true;
Vue.config.productionTip = false
Vue.use(underscore);
Vue.use(Vuex);
Vue.use(Toasted);
Vue.use(Meta, {refreshOnceOnNavigation: true});
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.mixin(Utils);
Vue.use(VueSweetalert2);
Vue.use(VueSocialSharing);


const i18n = new VueI18n({
	locale: 'en',
	messages,
});

const router = Router;

router.beforeEach((to, from, next) => {
	if (to.query && to.query.lang) {
		i18n.locale = to.query.lang
	}
	next();
});

Vue.use(auth0Plugin);

new Vue({
	el: "#app",
	store,
	i18n,
	router,
	mounted() {
		//const that = this;
		axios.get("https://api.ipify.org/?format=json").then(function (response) {

			console.log("ipify ", response)
			/*axios.get(`https://api.ipbase.com/v2/info?ip=${response.data.ip}&apikey=9MsfF0GC5APswxdRyWOzWUlD1FcVnAdDXrQIfFV6`).then(function(response2) {


				console.log("response2", response2)
				if (response2 && response2.data && response2.data.data.location) {
					
					// First try haversine distance
					if (response2.data.data.location.latitude && response2.data.data.location.longitude) {
						const domeList = that.getDomeList();
						let closest;
						let minDistance = 10000;
						domeList.forEach(x => {
							const dist = that.haversineDistance(response2.data.data.location.latitude, response2.data.data.location.longitude, x.lat, x.lng, "K");
							if (dist < minDistance) {
								minDistance = dist;
								closest = x;
							} 
						});

						// This should likely be in a watch on the user object to wait for auth0 to get the user
						if (!store.getters.userMetadata.language) {
							if (minDistance < 100 && closest) {
								that.$i18n.locale = closest.lang;
							} else {
								if (response2.data.data.location.region.alpha2 === "CA-QC") {
									that.$i18n.locale = "fr";
								} else if (response2.data.data.location.city.name === "Miami" || response2.data.data.location.city.name === "Orlando") {
									that.$i18n.locale = "es";
								} else {
									that.$i18n.locale = "en";
								}
							}
						} else {
							console.log("Lang already set");
						}
					}					
				}
			}).catch(
				function (error) {
					console.log('Show error notification!', error)
				}
			)*/
		});

		// throw "Vue.config.errorHandler will capture me!";
	},
	computed: {
		...mapGetters([
			'userMetadata'
		])
	},
	watch: {
		userMetadata() {
			console.log(this.$i18n.locale);
		}
	}
});
