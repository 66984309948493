<template>
	<div id="game-editor" :class="{hidden: !displayEditor}">
		<replay-header :footageStyle="true"></replay-header>
		<div class="content-container">

			<div class="text-explainer">
				<span :title="$t('videoEditor.back_to_game')" style="float: left; cursor: pointer;" @click="backToGame">
					<i class="fas fa-arrow-left" style="font-size:xx-large;"></i>
				</span>
				Create a clip to easily keep it for you, share it with friends or upload on social medias!
			</div>
			<div id="slider"></div>
			<!--todo: feature toggle
			<div class="submit-option">
				<div class="submit-option--description">
					<div class="submit-option--description-title">{{$t('videoEditor.submit_play')}}</div>
					<div class="submit-option--description-subtitle">{{$t('videoEditor.submit_description')}}</div>
				</div>
				<div class="toggle-container">
					<input id="cmn-toggle-2" class="cmn-toggle cmn-toggle-round" type="checkbox" v-model="toggleOption">
					<label for="cmn-toggle-2"></label>
				</div>
			</div>


			<div class="download-play btn" @click="download">{{$t('videoEditor.download_play')}}</div>

			<div class="back-to-game btn" @click="backToGame">{{$t('videoEditor.back_to_game')}}</div>
			-->
			<br>
			<div>
				<div class="btns control-container--button" style="width:25%; float:left" @click="download('left')">
					Left &nbsp;
					<i class="fas fa-download"></i>
				</div>
				<div class="btns control-container--button" style="width:25%; float:right" @click="download('right')">
					Right &nbsp;
					<i class="fas fa-download"></i>
				</div>

				<div class="btns control-container--button" style="width:25%; float:left; margin-left:12.5%" @click="download('full')">
					Full view &nbsp;
					<i class="fas fa-download"></i>
				</div>
			</div>

			<div style="clear:both"><br></div>
			<video oncontextmenu="return false;" ref="videoPlayer" class="video-js vjs-theme-city vjs-big-play-centered vjs-16-9 rounded-video remove-black-area"
				style="height:100%">
			</video>

			<!--<div class="text-overlay">
				<img class="overlay-logo" src="../assets/myreplayicon.png" />
				<span class="overlay-text">{{ $t('videoEditor.video_overlay_text') }}</span>
			</div>-->
		</div>

		<div class="modal-background" :class="{hidden: modalHidden}"></div>
		<div class="download-modal" :class="{hidden: modalHidden}">
			<div class="modal-top-info">{{ $t('videoController.modal_info') }}</div>
			<loading-gif :showGif="true"></loading-gif>
			<div class="modal-bottom-info">{{ $t('videoController.wait') }}</div>
		</div>

		<div class="modal-background" :class="{hidden: socialModalHidden}"></div>
		<div class="download-modal social-modal" :class="{hidden: socialModalHidden}">
			<div class="modal-top-info pt-0 fz-lg">
				{{ $t('videoEditor.download_title') }}
			</div>
			<div class="modal-body-info">
				<!-- WhatsApp -->
				<span @click="socialIconClicked('whatsapp')">
					<ShareNetwork
						class="social-media-icon whatsapp-icon"
						network="whatsapp"
						:title="$t('videoEditor.share_your_highlight_1') + this.username + $t('videoEditor.share_your_highlight_2')"
						:url="videoClip"
						tag="a"
					>
						<i class="fab fah fa-lg fa-whatsapp"></i>
					</ShareNetwork>
				</span>

				<!-- Facebook -->
				<span @click="socialIconClicked('facebook')">
					<ShareNetwork
						class="social-media-icon facebook-icon"
						network="facebook"
						:title="$t('videoEditor.share_your_highlight_1') + this.username + $t('videoEditor.share_your_highlight_2')"
						:url="videoClip"
						hashtags="myreplay"
						tag="a"
					>
						<i class="fab fah fa-lg fa-facebook-square"></i>
					</ShareNetwork>
				</span>
			</div>
			<div class="modal-bottom-info pb-0">
				{{ $t('videoEditor.no_download') }} <a :href="videoClip">{{ $t('videoEditor.download_cta') }}</a>

				<div class="button-container">
					<div class="control-container--button" @click="closeModal">{{$t('videoController.close')}}</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import ReplayHeader from './ReplayHeader';
import noUiSlider from 'nouislider';
import videojs from 'video.js';
import api from "@/api";
import LoadingGif from './LoadingGif.vue';
import {slack, clips} from "/config.json";
import axios from "axios";
import {mapGetters} from "vuex";


export default {
	name: 'VideoEditor',
	components: {
		ReplayHeader,
		LoadingGif,
	},
	props: {
		src: {
			type: String,
			default() {
				return "";
			}
		},
		seedTime: {
			type: Number,
			default() {
				return 0;
			}
		},
		displayEditor: {
			type: Boolean,
			default() {
				return false;
			}
		},
		curTime: {
			type: Number,
			default() {
				return 0;
			}
		},
	},
	data() {
		return {
			lastValues: [],
			player: null,
			slider: null,
			initialized: false,
			videoInitialized: false,
			gradientSet: false,
			videoDuration: 3600,
			modalHidden: true,
			socialModalHidden: true,
			toggleOption: false, // todo: feature-toggle set to true
			videoClip: ""
		}
	},
	computed: {
		...mapGetters([
			'user', 'username'
		]),
		videoOptions: function () {
			return {
				autoplay: false,
				controls: true,
				controlBar: {
					'pictureInPictureToggle': false
				},
				sources: [
					{
						src: this.src,
						type: "video/mp4"
					}
				]
			}
		},
	},
	watch: {
		displayEditor(val) {
			const that = this;
			if (val && !this.initialized) {
				var slider = document.getElementById('slider');

				const numberFormat = {
					// 'to' the formatted value. Receives a number.
					to: function (value) {
						return that.mmss(value);
					},
					// 'from' the formatted value.
					// Receives a string, should return a number.
					from: function (value) {
						const splitTime = value.split(':');
						return splitTime[0] * 60 + splitTime[1];
					}
				}

				noUiSlider.create(slider, {
					start: [that.start(that.curTime, 7.5), that.end(that.curTime, 7.5)],
					connect: true,
					range: {
						'min': that.start(that.curTime, 30),
						'max': that.end(that.curTime, 30)
					},
					margin: 1,
					tooltips: [numberFormat, numberFormat],
					padding: 1,
				});

				this.slider = slider;

				this.lastValues[0] = that.start(that.curTime, 7.5);
				this.lastValues[1] = that.end(that.curTime, 7.5);
				this.player.currentTime(that.start(that.curTime, 7.5));

				slider.noUiSlider.on('update', this.updateTime);
				document.getElementsByClassName('noUi-connect')[0].style.backgroundImage = "#FFF";

				this.player.on('timeupdate', function () {
					// track!
					const curTime = that.player.currentTime();
					const diff = that.calculatePercentDone(curTime);
					if (diff < 0) {
						that.player.currentTime(that.lastValues[0]);
					}
				});
				this.initialized = true;
			} else if (this.initialized) {
				this.slider.noUiSlider.updateOptions({
					start: [that.start(this.curTime, 7.5), that.end(this.curTime, 7.5)],
					range: {
						'min': that.start(this.curTime, 30),
						'max': that.end(this.curTime, 30)
					},
				});
			}
		},
		videoOptions(val) {
			if (val && val.sources && val.sources.length > 0 && !this.videoInitialized) {
				const video = videojs(this.$refs.videoPlayer, val);
				video.playsinline(true);
				this.player = video;
				this.videoInitialized = true;
			}
		}
	},
	methods: {
		updateTime(time) {
			const that = this;
			for (let index = time.length - 1; index >= 0; index--) {
				if (time[index] !== this.lastValues[index]) {

					if (this.lastValues[index] && that.player) {
						// change the time if the primary values were set
						that.player.currentTime(time[index]);
					}
					// el is the updated value
					that.lastValues[index] = time[index];

				}
			}
		},
		pause() {
			this.player.pause();
		},
		play() {
			this.player.play();
		},
		mmss(seconds) {
			if (seconds > 3600) {
				const mmss = new Date((seconds - 3600) * 1000).toISOString().substr(14, 5);
				const parts = mmss.split(":");
				return (parseInt(parts[0]) + 60) + ":" + parts[1];
			}

			return new Date(seconds * 1000).toISOString().substr(14, 5);
		},
		setGradient(percent) {
			document.getElementsByClassName('noUi-connect')[0].style.backgroundImage = `linear-gradient(to right, #8540a3 ${percent}%, #f3e5f5 ${percent + 1}%)`;
		},
		calculatePercentDone(time) {
			const range = this.lastValues[1] - this.lastValues[0];
			const diff = this.lastValues[1] - time;
			const percentDone = ((1 - diff / range) * 100);
			this.setGradient(percentDone);
			return diff;
		},
		start(curTime, extension) {
			const val = (curTime - extension) > 0 ? (curTime - extension) : 0;
			return val;
		},
		end(curTime, extension) {
			const duration = this.player.duration() || 3845;
			const val = (curTime + extension) < duration ? (curTime + extension) : duration;
			return val;
		},
		unsupportedFeature(view) {
			let that = this;

			that.$swal({
				title: "Comming soon...",
				html: (that.toggleOption ? "<div> Sorry this feature is still under construction, stay tuned!</div>" : ""),
				heightAuto: false,
			});

			axios.get(slack.slackUrl + '&type=laredkocha&event=' + "CUT_PLAY-" + view + '&text=FEATURE REQUEST; ' + that.username);

		},
		download(view) {

			const sliderValues = this.slider.noUiSlider.get();
			const length = parseFloat(sliderValues[1]) - parseFloat(sliderValues[0]);
			const startTime = parseFloat(sliderValues[0]);
			const that = this;

			this.modalHidden = false;

			//console.log(that.$parent.gameData.domeKey)
			const options = {
				url: this.src,
				length,
				startTime,
				username: that.username,
				arena: that.$parent.gameData.domeKey,
				view: view,
				//type: this.toggleOption ? "submitted" : "not_submitted" todo: feature-toggle
				type: clips.STATUS_APPROVED,
				gameKey: that.$parent.gameData.gameKey
			}

			api.createClip(options, function (err, res) {
				console.log(err, res);
				if (err) {
					console.log("Error creating clip", err);
				}

				that.videoClip = res.url;

				that.myReplaySlackHook("log-users", "CUT_PLAY-" + view,
					"- https://myreplay.co/#/profile/" + that.username + "/?play=" + res.file +
					"\n- Start: " + startTime + " (" + length + "s)"
				)
				const download = function download(url, filename) {

					fetch(url).then(function (t) {
						return t.blob().then((b) => {
							let a = document.createElement("a");
							a.href = URL.createObjectURL(b);
							a.setAttribute("download", filename);
							a.click();
						});
					});
					that.modalHidden = true;
					that.socialModalHidden = false;

					// const title = that.$i18n.messages[that.$i18n.locale].videoEditor.download_title;
					// const text = that.$i18n.messages[that.$i18n.locale].videoEditor.download_success;
					// const no_download = that.$i18n.messages[that.$i18n.locale].videoEditor.no_download;
					// const download_cta = that.$i18n.messages[that.$i18n.locale].videoEditor.download_cta;

					// let el = document.getElementById('swalHTML');
					// swal({
					// 	html: el.innerHTML
					// });
					// + el.innerHTML +

					// that.$swal({
					// 	title,
					// 	text,
					// 	html: (that.toggleOption ? "<div>" + text + "</div>" : "") + "<br>" + no_download + " <a href='" + url + "'>" + download_cta + "</a>",
					// 	heightAuto: false,
					// });
				}
				//console.log(res.url, "myreplay-" + that.$parent.gameData.domeKey + "-" + that.$parent.gameData.yyyymmdd + "-" + startTime + "s.mp4");
				download(res.url, "myreplay-" + that.$parent.gameData.domeKey + "-" + that.$parent.gameData.yyyymmdd + "-" + startTime + "s.mp4");
			});
		},
		backToGame() {
			this.pause();
			history.back();
		},
		closeModal() {
			this.socialModalHidden = true;
		},
		socialIconClicked(media) {
			console.log(media)
			/*if (media === "whatsapp")
                this.myReplaySlackHook(
                    "log-users",
                    "TRIMMING_SHARING_WHATSAPP_LINK",
                    "- User: " + this.username +
                    "\n- URL: " + this.videoClip
                );
			else
                this.myReplaySlackHook(
                    "log-users",
                    "TRIMMING_SHARING_FACEBOOK_LINK",
                    "- User: " + this.username +
                    "\n- URL: " + this.videoClip
                );*/
		}
	},
	mounted() {
		const that = this;
		window.onpopstate = function () {
			that.$parent.$emit('hideEditor');
		};
	}
}
</script>

<style src="../assets/reset.css"></style>

<style src='nouislider/distribute/nouislider.css'></style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');

body {
	background: #efeff1 !important;
	height: 100%;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

#game-editor {
	background: #212121;
	position: fixed;
	min-height: 100vh;
	z-index: 15;
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 1;
	overflow-y: auto;
}

#game-editor.hidden {
	opacity: 0;
	pointer-events: none;
}

.content-container {
	padding: 16px;
	width: 100%;
}

.btn {
	width: 100%;
	padding: 12px;
	text-align: center;
	text-transform: uppercase;
	border-radius: 6px;
	font-family: Nunito;
	font-weight: 800;
	cursor: pointer;
}

.back-to-game {
	background: #c4c4c4;
}

.download-play {
	background: #772b99;
	font-size: medium;
	margin-top: 6px;
	color: #fff;
}

</style>

<style>
.noUi-target {
	margin-top: 65px;
	font-family: "Nunito";
}

.noUi-connect {
	background: linear-gradient(45deg, #FF0054, #610888) !important;
}

.noUi-handle {
	border: 1px solid #D9D9D9;
	border-radius: 6px;
}

.noUi-horizontal .noUi-handle {
	width: 18px;
	height: 44px !important;
	top: -14px;
}

.noUi-horizontal .noUi-tooltip {
	bottom: 110%;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
	right: -9px;
	left: auto;
}

.noUi-handle:before, .noUi-handle:after {
	left: 5px;
	top: 14px;
}

.noUi-handle:after {
	left: 10px;
}

.noUi-tooltip {
	border: none;
	padding: 2px 5px;
}

.remove-black-area {
	padding-top: 35% !important;
}

.btns {
	padding: 10px !important;
	margin-top: 6px !important;
}

.modal-background {
	background: rgba(0, 0, 0, 0.66);
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	pointer-events: auto;
	display: auto;
}

.download-modal {
	width: 90%;
	height: 400px;
	background: #fff;
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 12px;
	pointer-events: auto;
	display: auto;
}

.social-modal {
	width: 450px;
	height: 250px;
}

.download-modal.hidden, .modal-background.hidden {
	display: none;
	pointer-events: none;
}

.loading-gif {
	width: 200px;
	height: 200px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.modal-top-info, .modal-bottom-info, .modal-body-info {
	font-family: Nunito;
	text-align: center;
	width: 100%;
	position: absolute;
	padding: 24px;
	font-size: 20px;
}

.modal-top-info {
	top: 10%;
}

.modal-bottom-info {
	bottom: 10%;
}

.pb-0 {
	padding-bottom: 0px;
}

.pt-0 {
	padding-top: 0px;
}

.fz-lg {
	font-size: 24px;
}

.modal-body-info {
	top: 18%;
	margin-top: 10px;
	text-align: center;
	align-items: center;
}

.text-overlay {
  z-index: 1;
  position: absolute;
  width: 300px;
  height: 20px;
  top: 246px;
  left: 35px;
  color: white;
  display: flex;
}

.overlay-logo {
	height: 20px;
}

.overlay-text {
	margin-left: 10px;
	padding-top: 3px;
	font-family: Nunito;
}

.submit-option {
	color: #fff;
	font-family: 'Nunito';
	display: flex;
	flex-direction: row;
	max-width: 360px;
	margin: 72px auto 16px;
}

.submit-option--description {
	display: inline-block;
	flex-grow: 3;
}

.submit-option--description-title {
	font-size: 18px;
	text-align: left;
	margin-bottom: 6px;
}

.submit-option--description-subtitle {
	font-size: 14px;
	color: #bdbdbd;
}

.toggle-container {
	width: 60px;
	display: inline-block;
	flex-grow: 1;
}

.cmn-toggle {
	position: absolute;
	visibility: hidden;
}

.cmn-toggle + label {
	display: block;
	position: relative;
	cursor: pointer;
	outline: none;
	user-select: none;
}

input.cmn-toggle-round + label {
	padding: 2px;
	width: 60px;
	height: 32px;
	background: #dddddd;
	border-radius: 60px;
	display: inline-block;
}

input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
	display: block;
	position: absolute;
	top: 1px;
	left: 1px;
	bottom: 1px;
	content: "";
}

input.cmn-toggle-round + label:before {
	right: 1px;
	background-color: #f1f1f1;
	border-radius: 60px;
	transition: background 0.4s;
}

input.cmn-toggle-round + label:after {
	width: 30px;
	background-color: #fff;
	border-radius: 100%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	transition: margin 0.4s;
}

input.cmn-toggle-round:checked + label:before {
	background: #610888;
}

input.cmn-toggle-round:checked + label:after {
	margin-left: 28px;
}

.text-explainer {
	text-align: center;
	color: white;
	font-size: 14px;
	font-family: Nunito;
	/* max-width: 300px; */
	margin: 0px auto -8px;
}

.button-container {
	display: inline-block;
	width: calc(50% - 8px);
	margin: 0 4px;
}

.control-container--button {
	cursor: pointer;
    font-family: 'Nunito';
    color: #ffffff;
    background: linear-gradient(45deg, #FF0054, #610888);
    border-radius: 22px;
    padding: 12px;
    margin: 16px auto 0px;
    font-size: 18px;
    text-align: center;
    width: fit-content;
}

.social-media-icon {
	font-size: 48px;
}

.whatsapp-icon {
	padding-right: 20px;
	color: #25d366;
}

.facebook-icon {
	color: #1877f2;
}

@media only screen and (max-width: 980px) {
	.control-container--button {
		font-size: 16px;
		margin: 12px auto 0px;
	}
}

@media only screen and (min-width: 800px) and (max-width: 980px) {
	.text-overlay {
		top: 240px;
	}

	.overlay-logo {
		height: 18px;
	}

	.overlay-text {
		font-size: 15px;
	}
}

@media only screen and (min-width: 640px) and (max-width: 799px) {
	.text-overlay {
		top: 237px;
	}

	.overlay-logo {
		height: 16px;
	}

	.overlay-text {
		font-size: 14px;
		padding-top: 2px;
	}
}

@media only screen and (min-width: 593px) and (max-width: 639px) {
	.text-overlay {
		top: 235px;
	}

	.overlay-logo {
		height: 14px;
	}

	.overlay-text {
		font-size: 12px;
		padding-top: 2px;
	}
}

@media only screen and (max-width: 592px) {
	.text-overlay {
		top: 230px;
		left: 30px;
	}

	.overlay-logo {
		height: 14px;
	}

	.overlay-text {
		font-size: 12px;
		padding-top: 2px;
	}

	.fa-arrow-left {
		font-size: x-large !important;
	}

	.control-container--button {
		font-size: 14px;
	}

	.noUi-tooltip {
		font-size: 14px;
	}
}

@media only screen and (max-width: 410px) {
	.control-container--button {
		font-size: 13px;
	}
}

@media only screen and (max-width: 585px) {
	.text-overlay {
		top: 240px;
	}
}

@media only screen and (max-width: 388px) {
	.control-container--button {
		font-size: 12px;
	}
}
</style>