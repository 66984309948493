import Vue from 'vue/dist/vue.js';
import store from "@/store/store"; // store needs to be instanciated. What if it's not?
//import auth0Plugin from "@/plugins/auth";
import {app, plans} from "../../config.json";
import server from "@/api/server";

// q: why do i need to do this in order to call    Vue.prototype.$auth ^, It's already done in main.js..
//Vue.use(auth0Plugin, this.$vm.$root._router);

export const guardLogout = () => {
	Vue.prototype.$auth.logout();
}
export const guardProfile = (to, next) => {

	next();
	let u = store.getters.user;
	if (u.email == null) {
		console.log("user is public")
		next()
	} else {
		if (!to.params.username) {
			// Empty profile linke (/profile), meaning direct login from Landing -> send to his own profile
			next("/profile/" + store.getters.username);
		}
		next()
	}

}
export const guardConvert = async (to, next) => {

	let u = store.getters.user;

	console.log("Convert guard: " + to.name, to.params, u);
	console.log("CONVERT GUARD  1");

	if (u.email == null) {
		next();
	} else if (u.meta.purchase == null && !plans.free.includes(to.params.team.toLowerCase())) {
		console.log("CONVERT GUARD  2");
		// 60197736056dfc0071c6527c
		if (to.params.player && to.params.authsub === u.subId) { // he just paid!

			console.log("CONVERT GUARD  3")

			await server.persistPaymentLegacy(u, to.params.player, to.params.team.toLowerCase(), to.params.date, function () {

				let cohort = app.cohorts[app.cohorts.length - 1].id;
				window.heap.track("convertToPaid", {
					username: store.getters.username,
					cohort: cohort,
					price: process.env.VUE_APP_STRIPE_PRICE
				});

				console.log("ROUTER: " + "/profile/" + store.getters.username)

				store.commit("UPDATE_USER", u);

				next("/profile/" + store.getters.username)
			})


		} else if (to.params.player && u.meta.loginsCount === 1) {
			console.log("Hey '" + to.params.player + "', just created your account bro. Now let's continue to stripe")
			next()
		} else {

			console.log("I recognize your account, but you didn't pay yet...")
			next()
		}


	} else {
		// he paid, continue his profile
		// extra validation on the puchase object if needed

		next("/profile/" + u.username)
	}
}

export const guardGame = async (to, next) => {

	console.log("GUARD GAME 1")
	// if no account and never paid, then your not authorized to view this page, redirect to the convert page
	if (store.getters.user != null && (store.getters.user.meta.purchase != null || plans.free.includes(to.params.team.toLowerCase()))) {
		console.log("GUARD GAME 2")


		console.log("In game ! Validated that he has an account and paid. Now apply authorization logic...")
		next()
		// Q: how come I am able to use this variable in this scope (not even defined..
		// let metadata = store.getters.user[namepace + "_user_metadata"];
		//
		//
		// if (metadata.team === team) {
		//     next()
		// } else {
		//     next(false)
		//     alert(metadata.team + "Sorry. You don't have access to the " + team + " team ")
		// }

	} else {

		next("/convert/" + to.params.team + "/" + to.params.date)


	}
}

export const guardFootage = async (to, next) => {
	if (store.getters.isLoggedIn) {
		// For now any user can see any game
		next();
	}  else {
		next(false);

		const gameCode = to.query && to.query.gameCode;
		Vue.prototype.$auth.loginWithRedirect(to.path, gameCode);
	}
}

export const pass = async (to, next) => {
	next();
}

export default (to, from, next, logic) => {

	if (from.query && from.query["discount"]) {
		to.query["discount"] = true;
	}

	//logic(to, next);
	if (!Vue.prototype.$auth || Vue.prototype.$auth.isLoading) {
		let killWatcher = store.watch(
			(state) => {
				return state.user.loaded
			}, () => {
				console.log("Watcher finish");
				killWatcher();
				logic(to, next)
			},
			{deep: true}
		);
	} else {
		logic(to, next)
	}
}