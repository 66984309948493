<template>
	<div id="replay-app" class="replay-app-editor">
		<div class="left-container left-container-editor">
			<video-player :options="videoOptions" style="" @timeupdate="timeUpdate"></video-player>
			<!-- -->
			<!-- LEGEND AREA -->
			<!-- -->
		</div>
		<!-- -->
		<div class="right-container right-container-editor">
			<div class="right-container-editor--title">Play Markers</div>
			<div class="done-button" @click="done()">DONE!</div>
			<li v-for="(playMarker, index) in playMarkers" :key="index"
				class="play-marker-list" @click="$root.$emit('clickEditorMarker', $event, playMarkers[index].time)"
				@focusout="removeFocus">
				<form>
					<span>{{ index }}</span>
					<input v-model="playMarker.time" class="table-input auto-select" placeholder="TIME" @change="updateField(index)" @focus="focus($event, index)"> |
					<div class="radio-wrapper">
						<input :id='`${teams[0]}-${index}`' v-model="playMarker.team" :value='teams[0]' class="radio" name="team" type="radio" @change="updateField(index)" @focus="focus($event, index)"><label :for='`${teams[0]}-${index}`' class="radio-label">{{ teams[0] }}</label>
						<input :id='`${teams[1]}-${index}`' v-model="playMarker.team" :value='teams[1]' class="radio auto-select" name="team" type="radio" @change="updateField(index)" @focus="focus($event, index)"><label :for='`${teams[1]}-${index}`' class="radio-label">{{ teams[1] }}</label>
					</div>
					|
					<input v-model="playMarker.playerId" :class="{invalid: !isPlayerTag(playMarker)}" class="table-input auto-select" pattern="[^' ']+" placeHolder="PLAYERID" @change="updateField(index)" @focus="focus($event, index)" @input="isValid($event)">
					<div class="remove-item" @click='remove($event, index, playMarkers[index].id)'>X</div>
				</form>
			</li>
		</div>
		<!-- -->
		<div class="legend">
			<div class="game-time"><span>{{ title }}</span> - {{ formatTime(curTime) }}</div>
			<div class="hide-shortcuts" @click='hideShortcuts'>
				{{ shortcutHide ? "Show Shortcuts" : "Hide Shortcuts" }}
			</div>
			<div :class="{hidden: shortcutHide}" class="shortcuts-container">
				<div v-for="shortcut in shortcuts" :key="shortcut.key" :class="{hidden: shortcutHide}" class="shortcuts-wrapper">
					<div class="shortcut-key">{{ shortcut.key }}</div>
					<div class="shortcut-action">{{ shortcut.action }}</div>
				</div>
			</div>

			<div class="teams-container"><!--
                -->
				<div v-for='team in playerTeams' :key="team.name" :class="{team: team.name != 'None'}" class="player-container">
					<div>{{ team.name }}</div>
					<div v-for='(player, index) in team.players' :key='index' class="player-wrapper">
						<div class="remove-player" @click="removePlayer($event, player.id, player.airtableId)">x</div>
						<img :src='player.src' class="player-image">
						<input :value='player.tag' class="player-input" name="playerId" placeholder="Player Id" type="text" @input='setPlayerTag($event, player.id)'>
					</div>
					<file-drop :team="team.name" @newPlayer="insertPlayer"></file-drop>
					<!--PASS THE DATA DOWN AND THEN IN EVENT for team name-->
				</div>

			</div>
		</div>
	</div>

</template>

<script>
import VideoPlayer from './VideoPlayer';
import FileDrop from "@/components/FileDrop";
import {airtable, slack} from "/config.json";
import axios from "axios";

export default {
	name: 'GameEditor',
	components: {
		VideoPlayer,
		FileDrop,
	},
	data() {
		const params = this.$route.params;
		const url = `${this.getCloudProviderBase(true)}/${params.url}/${params.url}.mp4`
		const teams = this.$route.params.url.split("-");
		teams.shift();

		return {
			title: params.url,
			players: [],
			date: "",
			videoOptions: {
				autoplay: true,
				controls: true,
				editor: true,
				playbackRates: [0.8, 1, 1.2],
				sources: [
					{
						src: url,
						type: "video/mp4"
					}
				]
			},
			currentEditingIndex: -1, // Index of currently selected line to be editing
			teams,
			curTime: 0,
			playMarkers: [],
			airtable: {},
			airtablePlayers: {},
			listenerAttached: false,
			shortcutHide: false,
			shortcuts: [{key: 'Space', action: 'Play / Pause Game'}, {
				key: 'Enter',
				action: 'Make New Marker at Play Time'
			}, {key: 'Tab', action: 'Advance Field'}, {key: 'Shift + Tab', action: 'Backup Field'}, {
				key: 'CTRL + Left',
				action: 'Rewind 5s'
			}, {key: 'CTRL + Right', action: 'Forward 5s'}, {key: 'Arrows', action: 'Select Team When on Field'}],
		}
	},
	computed: {
		playerTeams() {
			const team1 = [];
			const team2 = [];
			const that = this;

			this.players.forEach(x => {
				const teamName = x.team.replace(/\s/g, "");
				if (teamName == that.teams[0]) {
					team1.push(x);
				} else {
					team2.push(x);
				}
			});

			const ret = [];
			ret.push({name: this.teams[0], players: team1});
			ret.push({name: this.teams[1], players: team2});

			console.log("RET", ret);
			return ret;
		},
		playerTags() {
			const playerTags = this.players.map(x => {
				if (x.tag) return x.tag;
			}) || [];
			console.log("Player Tags", playerTags)
			return playerTags;
		}
	},
	methods: {
		hideShortcuts() {
			this.shortcutHide = !this.shortcutHide;
		},
		selectTeam(event, playerId) {
			const team = event.target.value.toLowerCase();

			if (team == this.teams[0] || team == this.teams[1]) {
				this.players.forEach(player => {
					if (player.id == playerId) {
						this.airtablePlayers.update(player.airtableId, {
							"Team": "" + team.replace(/\s/g, ""),
						}, (err, record) => {
							if (err) {
								console.error(err);
								return;
							}
							console.log("UPDATE RESPONSE 6", record);
							player.team = team;
						});

					}
				});
			}
		},
		focus(event, index) {
			this.currentEditingIndex = index;
			this.$root.$emit('clickEditorMarker', event, this.playMarkers[index].time);
		},
		removeFocus() {
			console.log("Lost focus");
			this.currentEditingIndex = -1;
		},
		isValid(evt) {
			evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 122))) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		isPlayerTag(playMarker) {
			return this.playerTags.includes(playMarker.playerId);
		},
		setPlayerTag(event, playerId) {
			const playerTag = event.target.value.toLowerCase();

			this.players.forEach(player => {
				if (player.id == playerId) {
					player.tag = playerTag.replace(/\s/g, "");

					this.airtablePlayers.update(player.airtableId, {
						"Tag": "" + playerTag.replace(/\s/g, ""),
					}, (err, record) => {
						if (err) {
							console.error(err);
							return;
						}
						console.log("UPDATE RESPONSE 7", record);
					});

				}
			});
		},
		pad(num, size) {
			num = num.toString();
			while (num.length < size) num = "0" + num;
			return num;
		},
		formatTime(time) {
			return new Date(time * 1000).toISOString().substr(11, 8)
		},
		timeUpdate(curTime) {
			this.curTime = curTime;
			if (this.currentEditingIndex > 0) {
				this.playMarkers[this.currentEditingIndex].time = this.formatTime(curTime);
			}
		},
		_keyListener(e) {
			if (e.keyCode === 13) {

				this.insertPlay();

			}
		},
		insertPlay() {
			const playMarkers = this.playMarkers;
			const lastPlayMarker = playMarkers && playMarkers[playMarkers.length - 1];
			const url = this.$route.params.url;

			this.airtable.create({
				"Timestamp": this.formatTime(this.curTime),
				"Team": lastPlayMarker ? lastPlayMarker.team.replace(/\s/g, "") : "",
				"PlayerId": "",
				"GameId": url,
			}, (err, record) => {
				if (err) {
					alert(err);
					return;
				}
				const playMarkers = this.playMarkers;
				console.log(record, "PUSHED TO PLAYMARKERS")
				playMarkers.push({
					time: record.fields.Timestamp,
					team: record.fields.Team || "",
					playerId: record.fields.PlayerId || "",
					id: record.getId()
				});
				this.playMarkers = playMarkers;
				// auto-focus on few field
				setTimeout(function () {
					const focusEls = document.getElementsByClassName('auto-select');
					focusEls && focusEls[focusEls.length - 1].focus() && focusEls[focusEls.length - 1].select(); // Last element is the line to write in
				}, 50);
			});
		},
		remove(event, index, id) {
			event.stopPropagation();
			this.playMarkers.splice(index, 1);
			this.airtable.destroy(id, (err, deletedRecord) => {
				if (err) {
					console.error(err);
					return;
				}
				console.log('Deleted record', deletedRecord.id);
			});
		},
		removePlayer(event, playerId, airtableId) {
			this.players.forEach((player, index) => {
				if (player.id == playerId) {
					this.players.splice(index, 1);

					this.airtable.destroy(airtableId, (err, deletedRecord) => {
						if (err) {
							console.error(err);
							return;
						}
						console.log('Deleted record', deletedRecord.id);
					});

				}
			});


		},
		updateField(index) {
			const playMarkers = this.playMarkers;
			const updateMarker = playMarkers[index];

			this.airtable.update(updateMarker.id, {
				"Timestamp": "" + updateMarker.time,
				"Team": updateMarker.team.replace(/\s/g, ""),
				"PlayerId": updateMarker.playerId.replace(/\s/g, ""),
			}, (err, record) => {
				if (err) {
					console.error(err);
					return;
				}
				console.log("UPDATE RESPONSE 8", record);
			});
		},
		insertPlayer(img, team) {
			// Into players list from upload
			const url = this.$route.params.url;

			this.airtablePlayers.create({
				"Id": "" + (new Date().getTime()), // Increment on the size of the table
				"Team": team,
				"Attachments": [{"url": img}],
				"Tag": "",
				"GameId": url,
			}, (err, record) => {
				if (err) {
					alert(err);
					return;
				}
				// TODO: record id should be consistently named with the other .id
				console.log(record);
				this.players.push({
					id: record.fields.Id,
					src: record.fields.Attachments[0].url,
					tag: record.fields.Tag || "",
					team: record.fields.Team || "",
					airtableId: record.id
				});
				// setTimeout(function() {
				//   const focusEls = document.getElementsByClassName('auto-select');
				//   focusEls && focusEls[focusEls.length - 2].focus() && focusEls[focusEls.length - 2].select(); // Second to last element is the line to write in
				// }, 50);
			});

		},
		selectMarkers() {
			const playMarkers = this.playMarkers;
			const url = this.$route.params.url;

			this.airtable.select({
				// Selecting the first 3 records in Grid view:
				filterByFormula: `{GameId} = "${url}"`,
				view: "Grid view",
			}).eachPage(function page(records, fetchNextPage) {
				// This function (`page`) will get called for each page of records.
				records.forEach(function (record) {
					console.log("record", record, "PUSHED");

					playMarkers.push({
						time: record.fields.Timestamp,
						team: record.fields.Team || "",
						playerId: record.fields.PlayerId || "",
						id: record.getId()
					});
				});

				// If there are more records, `page` will get called again.
				// If there are no more records, `done` will get called.
				fetchNextPage();
			}, function done(err) {
				if (err) {
					console.error(err);

				}
			});
		},
		selectPlayers() {
			const url = this.$route.params.url;
			const players = this.players;

			this.airtablePlayers.select({
				// Selecting the first 3 records in Grid view:
				filterByFormula: `{GameId} = "${url}"`,
				view: "Grid view",
			}).eachPage(function page(records, fetchNextPage) {
				// This function (`page`) will get called for each page of records.
				records.forEach(function (record) {
					players.push({
						team: record.fields.Team || "",
						id: record.fields.Id,
						src: record.fields.Attachments[0].url,
						airtableId: record.getId(),
						tag: record.fields.Tag || "",
					});
				});

				// If there are more records, `page` will get called again.
				// If there are no more records, `done` will get called.
				fetchNextPage();
			}, function done(err) {
				if (err) {
					console.error(err);

				}
			});
		},
		done() {
			let error = "";
			const that = this;
			// Validate
			this.playMarkers.forEach(function (marker, index) {
				if (!marker.team) {
					error = "No team selected for some markers";
					return;
				}
				let truePlayerId = "";
				that.players.forEach(player => {
					if (player.tag == marker.playerId) {
						// Get the real player id from the players array with matching tag
						if (player.team == marker.team) {
							truePlayerId = player.id;
						}
					}
				});

				if (!truePlayerId) {
					error = "Player tag is wrong or teams aren't matching at index: " + index;

				}
			});

			if (error) {
				alert(error);
				return;
			}
			// Process Markers
			let result = "";
			this.playMarkers.forEach(marker => {
				let truePlayerId = "";
				that.players.forEach(player => {
					if (player.tag == marker.playerId) {
						// Get the real player id from the players array with matching tag
						if (player.team == marker.team) {
							truePlayerId = player.id;
						}
					}
				});
				if (!truePlayerId) {
					console.log("DIDN'T FIND MATCH, PROBLEM");
				}
				result += `${marker.time},${marker.team},${truePlayerId}\n`;
			});
			// Done process and logging
			console.log(result);

            // Process Teams for Images
            let team1 = "";
            let team2 = "";
            this.players.forEach(player => {
                const endUrl = player.src.split("/");
                if (player.team == that.teams[0]) {
                    team1 += `wget ${player.src}\n`;
                    team1 += `mv ${endUrl[endUrl.length - 1]} ${that.teams[0]}-${player.id}.png\n`;
                } else {
                    team2 += `wget ${player.src}\n`;
                    team2 += `mv ${endUrl[endUrl.length - 1]} ${that.teams[1]}-${player.id}.png\n`;
                }
            });
            console.log(this.teams[0]);
            console.log(team1);
            console.log(this.teams[1]);
            console.log(team2);

			const params = this.$route.params;
			const url = `${this.getCloudProviderBase(true)}/${params.url}/${params.url}.mp4`
			axios.get(slack.slackUrl + '&event=' + 'done' + '&text= Shahmir is done editing game: ' + encodeURIComponent(url));
			alert("Congrats! The team knows you're done");
		},
	},
	created() {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base('appVfohbIS5tO9zbO');
		this.airtable = base('playMarkers');
		this.airtablePlayers = base('players');

		// Airtable getters for the player references and saved markers
		this.selectMarkers();
		this.selectPlayers();


		if (!this.listenerAttached) {
			document.addEventListener("keyup", this._keyListener);
		}
		this.listenerAttached = true
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.eventListener);
	}
}
</script>

<style src="../assets/reset.css">

</style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');

body {
	background: #efeff1;
}

li {
	list-style-type: none;
}

.replay-app-editor {

}

.legend {
	background: #fff;
	box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
	position: fixed;
	width: 100%;
	float: left;
	top: 81vh;
	bottom: 0;
}

.game-time {
	margin-bottom: 4px;
	width: 100%;
	text-align: center;
	font-size: 32px;
}

.game-time > span {
	font-size: 14px;
	vertical-align: middle;
}

.shortcuts-container {
	transition: max-height 0.3s ease-out;
	max-height: 200px;
}

.shortcuts-container.hidden {
	max-height: 0px;
	overflow: hidden;
}

.hide-shortcuts {
	position: absolute;
	right: 12px;
	top: 12px;
	cursor: pointer;
	background: #bababa;
	padding: 4px;
	border-radius: 4px;
}

.shortcuts-wrapper {
	display: inline-block;
	margin: 0 10px;
	width: 140px;
	vertical-align: top;
	opacity: 1;
	transition: opacity 0.3s ease-in;
}

.shortcuts-wrapper.hidden {
	opacity: 0;
}

.shortcut-key {
	background: #333;
	color: #fff;
	font-weight: 700;
	font-size: 12px;
	border-radius: 8px;
	text-align: center;
	padding: 4px;
	margin-bottom: 4px;
	height: 32px;
	line-height: 24px;
}

.shortcut-action {
	font-size: 11px;
}

.no-teams-container {
	display: inline-block;
}

.teams-container {
	display: inline-block;
	margin-left: 16px;
	vertical-align: top;
	max-width: calc(50% - 60px);
	overflow-y: scroll;
    overflow-x: hidden;
    height: 150px;
}

.player-container {

}

.player-container.team {
	display: inline-block;
	vertical-align: top;
}

.player-container.team:nth-child(2) {
	position: fixed;
	left: 50%;
    overflow-y: scroll;
    height: 150px
}

.player-wrapper {
	display: inline-block;
	position: relative;
}

.remove-player {
	position: absolute;
	top: 0;
	right: 0;
	background: #eee;
	border-radius: 50px;
	width: 24px;
	height: 24px;
	text-align: center;
	line-height: 24px;
	cursor: pointer;
}

.remove-player:hover {
	background: #9e9e9e
}

.player-image {
	max-height: 100px;
	margin: auto;
	display: block;
	margin-bottom: 8px;
	margin-top: 8px;
}

.player-input {
	display: block;
	max-width: 66px;
	font-size: 11px;
	border: 0.5px solid #9e9e9e;
}


.left-container-editor {
	float: initial;
	height: auto;
	display: inline-block;
	flex-flow: initial;
	width: 73%;
}

.right-container-editor {
	position: relative;
	height: 81vh;
	overflow-y: scroll;
	width: calc(27% - 16px);
}

.done-button {
	position: absolute;
	top: 4px;
	right: 12px;
	background: #ff0054;
	color: #fff;
	border-radius: 12px;
	padding: 6px 12px;
	cursor: pointer;
	font-family: 'Russo One';
}

.right-container-editor li {
	padding-bottom: 4px;
}

.right-container-editor li:hover {
	background: #ddd;
}

.right-container-editor--title {
	font-size: 18px;
	margin: 8px 0;
	text-align: center;
	width: 100%;
	font-family: 'Russo One';
	top: 0;
}

.play-marker-list {
	margin-left: 6px;
	max-height: 100%;
}

.table-input {
	margin-top: 8px;
	margin-left: 4px;
	width: 20%;
}

.table-input.invalid {
	border: 1px solid red;
}

.radio-wrapper {
	display: inline-block;
}

.radio-wrapper:focus-within {
	border: 2px solid #333;
}

.radio {
	opacity: 0;
	display: inline-block;
}

.radio-label {
	display: inline-block;
	margin-left: -20px;
	border: 1px solid #999;
	padding: 4px;
	font-size: 14px;
}

.radio:checked + label {
	background: #9e9e9e;
}

.main-container-editor {
	max-width: 90%;
}

.remove-item {
	display: inline-block;
	text-align: center;
	border-radius: 8px;
	background: #eee;
	cursor: pointer;
	width: 22px;
	height: 22px;
	line-height: 22px;
	font-size: 12px;
	font-weight: 700;
	margin-left: 4px;
}

.remove-item:hover {
	background: #9e9e9e;
}

@media only screen and (max-width: 980px) {
	.left-container {
		width: 100%;
		height: auto;
	}

	.right-container {
		width: 100%;
		max-height: 80%;
	}
}
</style>