import Vue from 'vue/dist/vue.js'
import Vuex from 'vuex'
import {auth} from "../../config.json";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isAuthenticated: false,
		user: {
			email: null,
			email_verified: null,
			[auth.namespace + "user_metadata"]: {
				purchase: [],
				subscriptions: [],
				highlights: [],
				segment: null,
				language: null,
				profileDescription: null,
				instagramHandle: null,
			},
			name: null,
			nickname: null,
			picture: null,
			sub: null,
			updated_at: null,
			loaded: false,
			user_id: null,
		},
		publicUsers: []
	},
	getters: {
		user: state => {
			return state.user;
		},
		isLoggedIn: (state, getters) => {
			return !!getters.user.email;
		},
		userSubId: (state, getters) => {
			return getters.user.sub ? state.user.sub.replace("|", "-") : null;
		},
		username: (state, getters) => {
			return getters.user[auth.namespace + "username"] || getters.user.nickname;
		},
		userMetadata: (state, getters) => {
			return getters.user[auth.namespace + "user_metadata"] || {};
		},
		purchases: (state, getters) => {
			const purchases = getters.user[auth.namespace + "user_metadata"].purchase;
			// Guard against old account with non-array purchase obj
			return Array.isArray(purchases) ? purchases : [];
		},
		subscriptions: (state, getters) => {
			const subscriptions = getters.user[auth.namespace + "user_metadata"].subscriptions;
			// Guard against old account with non-array purchase obj
			return Array.isArray(subscriptions) ? subscriptions : [];
		},
		highlights: (state, getters) => {
			return getters.user[auth.namespace + "user_metadata"].highlights;
		},
		publicUsers: (state) => {
			return state.publicUsers;
		},
		profileDescription: (state, getters) => {
			return getters.user[auth.namespace + "user_metadata"].profileDescription;
		},
		instagramHandle: (state, getters) => {
			return getters.user[auth.namespace + "user_metadata"].instagramHandle;
		}
	},
	// to do later, when it will be needed
	// actions : {
	//     updateUser (context, user) {
	//         context.commit("UPDATE_USER", user)
	//     }
	// },
	mutations: {
		CLEAR_USER(state) {
			window.heap.resetIdentity();
			state.user.email = null;
			state.user.email_verified = null;
			state.user[auth.namespace + "user_metadata"] = {
				purchase: [],
				subscriptions: [],
				highlights: [],
				segment: null,
				language: null,
				profileDescription: null,
				instagramHandle: null,
			};
			state.user.name = null;
			state.user.nickname = null;
			state.user.picture = null;
			state.user.sub = null;
			state.user.updated_at = null;
			state.user.user_id = null;
		},

		UPDATE_USER(state, user) {
			// TODO ensure no usage
			const that = this;
			if (user && user.sub) {
				window.heap.identify(user.sub);
				window.heap.addUserProperties({email: user.email});
			}

			// Change the user prop by prop to ensure preserving reactivity
			if (user && typeof user === "object") {
				Object.keys(user).forEach(function(prop) {

					if (prop === (auth.namespace + "user_metadata")) {
						// Metadata update, add reactive property
						Object.keys(user[prop]).forEach(function(metaProp) {
							that.commit("UPDATE_METADATA_PROP", {name: metaProp, value: user[prop][metaProp]});
						});
					} else {
						state.user[prop] = user[prop];
					}

				});
			} else {
				console.log("ATTEMPTING TO COMMIT EMPTY OBJECT TO USER", user);
			}
			state.user.loaded = true;
		},

		UPDATE_USER_PROP(state, updateObject) {
			if (!updateObject.name || typeof updateObject.name !== "string") {
				throw new Error ("No object name for user prop update or not String");
			}
			state.user[updateObject.name] = updateObject.value;
		},


		UPDATE_METADATA_PROP(state, updateObject) {
			if (!updateObject.name || typeof updateObject.name !== "string") {
				throw new Error ("No object name for metadata update or not String");
			}
			Vue.set(state.user[auth.namespace + "user_metadata"], updateObject.name, updateObject.value);
			if (state.user.email) {
				Vue.prototype.$auth.updateCurrentUserMetadata({user_metadata: state.user[auth.namespace + "user_metadata"]});
			}
		},

		PURCHASE_GAME(state, purchasedGame) {
			const purchase = state.user[auth.namespace + "user_metadata"].purchase;
			if (Array.isArray(purchase)) {
				purchase.push(purchasedGame);
			} else {
				state.user[auth.namespace + "user_metadata"].purchase = [purchasedGame];
			}
		},

		PURCHASE_SUBSCRIPTION(state, sub) {
			const subcriptions = state.user[auth.namespace + "user_metadata"].subscriptions;
			if (Array.isArray(subcriptions)) {
				subcriptions.push(sub);
			} else {
				state.user[auth.namespace + "user_metadata"].subscriptions = [sub];
			}
		},

		SET_USER_SEGMENTATION(state, segment) {
			state.user[auth.namespace + "user_metadata"].segment = segment;
			window.heap.addUserProperties({segment});
			if (state.user.email) {
				Vue.prototype.$auth.updateCurrentUserMetadata({user_metadata: state.user[auth.namespace + "user_metadata"]});
			}
		},

		SET_USER_LANGUAGE(state, language) {
			state.user[auth.namespace + "user_metadata"].language = language;
			window.heap.addUserProperties({language});
			if (state.user.email) {
				Vue.prototype.$auth.updateCurrentUserMetadata({user_metadata: state.user[auth.namespace + "user_metadata"]});
			}
		},

		SET_GAME_REMINDER(state, reminderData) {
			state.user[auth.namespace + "user_metadata"].gameReminderLink = reminderData.link;
			state.user[auth.namespace + "user_metadata"].gameReminderDelay = reminderData.delay;
			if (state.user.email) {
				Vue.prototype.$auth.updateCurrentUserMetadata({user_metadata: state.user[auth.namespace + "user_metadata"]});
			}
		},

		ADD_PUBLIC_USER(state, user) {
			if (!user || !user.email) {
				console.log("INVALID PUBLIC USER");
				return;
			}
			const index = state.publicUsers.findIndex(u => u.email === user.email);
			if (index < 0) {
				Vue.set(state.publicUsers, state.publicUsers.length, user);
			} else {
				//console.log("USER ALREADY EXISTS IN PUBLIC USERS")
			}
		},

		UPDATE_PUBLIC_USER_METADATA(state, user) {
			if (!user || !user.email) {
				console.log("INVALID PUBLIC USER for update");
				return;
			}
			const index = state.publicUsers.findIndex(u => u.email === user.email);
			if (index >= 0) {
				Vue.set(state.publicUsers, index, user);
			} else {
				//console.log("USER ALREADY EXISTS IN PUBLIC USERS")
			}
		}
	},
})