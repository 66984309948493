import { app, aws, gcp, slack } from "/config.json";
import axios from "axios";

export const DaysEnum = Object.freeze({
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
});

export const Utils = {
    created() {
    },
    data() {
        return {
            defaultLang: true,
        }
    },
    methods: {

        async getCloudFileUrl(file, cached = true, provider = "", cb){
            if (file) {
                if (typeof cb == "function") {
                    cb(file)
                } else {
                    return file
                }   
            }
            console.log("ddddddddd", file, cached, provider)
            

            if (!cached){
                if (provider == "aws") {
                    return (aws.videoRootNoCache + "/" + file)
                } else if (provider == "gcp") {
                    return (gcp.videoRootNoCache + "/" + file)
                } else {
                    
                    let link = gcp.videoRootNoCache + "/" + file
                    // make a head request
                    try {
                        let response = await axios.head(link) //.then((response) => {

                        if (response) {
                            console.log("SUCCESSSSS" , response)
                            console.log(
                                `Status: ${response.status} - Server: ${response.headers.server} - Data: ${response.data}`
                                );
    
                            
                        } else {
                            console.log("nop" , response)
                        }
                    } catch (e) {
                        console.log("proooblem", e)
                        link = aws.videoRootNoCache + "/" + file
                    }

                    console.log("eeeee" , link)  
                    if (typeof cb == "function") {
                        cb(link)
                    } else{
                        return link
                    }
                    // if fails, try other provider
                }
                
                
            } else {
                if (provider == "aws") {
                    return aws.videoRoot
                } else if (provider == "gcp") {
                    return gcp.videoRoot
                } else {
                    axios.head(file).then((response) => {
                        console.log(
                          `Status: ${response.status} - Server: ${response.headers.server} - Data: ${response.data}`
                        );
                      });
                }

            }


        },
        getCloudProviderBase(cached = true, gameKey = "") {

            if (gameKey == ""){
                if (!cached){
                    return aws.videoRootNoCache
                } else {
                    return aws.videoRoot
                }
            } else {
                try {
                    let dateOfGame = this.getGameDataFromGameCode(gameKey)
                    let cutoff =  new Date(gcp.lastFullGamesTransfertAt)

                    // all games before the 27 march, go fetch on gcp, for new ones, aws
                    if (dateOfGame.jsDate <= cutoff || gcp.mainsource){
                        if (!cached){
                            return gcp.videoRootNoCache
                        } else {
                            return gcp.videoRoot
                        }
                    } else {
                        if (!cached){
                            return aws.videoRootNoCache
                        } else {
                            return aws.videoRoot
                        }
                    }
                } catch (e){
                    console.log("error ", e, cached, gameKey)
                    return this.getCloudProviderBase(cached, "")
                }
              
            }
           
            
            
        },
        getHoursInNextDay() {
            return 4;
        },
        getGameDataFromPath(path) {
            const urlExtensions = path.split("/");
            let gameData = "";
            if (urlExtensions.length >= 3) {
                gameData = urlExtensions[2];
            } else {
                console.log("Invalid URL to get game data, expects https://domain.ext/#/base/gameData/...")
                return null;
            }

            return this.getGameDataFromGameCode(gameData);
        },
        getGameDataFromGameCode(gameCode) {
            const ret = {}

            ret.year = gameCode.substr(0, 4);
            ret.month = gameCode.substr(4, 2);
            ret.day = gameCode.substr(6, 2);
            ret.hour = gameCode.substr(8, 2);
            const date = new Date(ret.year + "-" + ret.month + "-" + ret.day + "T" + ret.hour + ":00:00")
            ret.yyyymmdd = this.yyyymmdd(date);
            ret.date = (new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))).toDateString();
            ret.jsDate = date;
            ret.paymentDateString = (new Date(date.getTime() - this.getHoursInNextDay() * 60 * 60000 - (date.getTimezoneOffset() * 60000 )))
                    .toISOString()
                    .split("T")[0];
            ret.domeKey = gameCode.split('-')[1];
            ret.dome = this.transformDomeName(gameCode.split('-')[1]);
            ret.field = gameCode.split('-')[2];
            ret.gameKey = gameCode 
            
            return ret;
        },
        // convert to time but by leveraging the minutes
        convertTimeWithMinutes(time, endFlag) {
            var H = +time.substr(0, 2);
            var M = time.substr(2);

            if (M) {
                M = ":" + M
            } else {
                M = ":00"
            }

            if (endFlag) H = H + 1;
            var h = H % 12 || 12;
            var ampm = (H < 12 || H === 24) ? "am" : "pm";
            time = h + M + ampm;
            return time; // return adjusted time or original string
        },

        convertTime(time, endFlag) {
            
            var H = +time.substr(0, 2);

            var M = time.substr(2);

            if (M) {
                M = ":" + M
            } else {
                M = ":00"
            }

            if (endFlag) H = H + 1;
            var h = H % 12 || 12;
            var ampm = (H < 12 || H === 24) ? "am" : "pm";
            time = h + M + ampm;
            return time; // return adjusted time or original string
        },
        setGameDataFromAWSObject(x, newThumbnailFlag) {
            const splitKey = x.Key.split('/');
            // Not the last extension, but before last
            const footageId = splitKey[splitKey.length - 2];
            const datePart = footageId.split('-')[0];

            
            x.link = footageId;
            const date = (new Date(datePart.substr(0, 4) + "-" +
                            datePart.substr(4, 2) + "-" +
                            datePart.substr(6, 2) + "T12:00:00"))

            x.jsDate = date;
            x.yyyymmdd = this.yyyymmdd(date);
            x.date = date.toLocaleString(undefined, {
                weekday: 'short', month: "short", day: "numeric"
            });
            x.time = datePart.substr(8);
            x.domeKey = footageId.split('-')[1];
            x.dome = this.transformDomeName(footageId.split('-')[1]);
            x.field = footageId.split('-')[2];
            x.paymentDateString = (new Date(date.getTime() - this.getHoursInNextDay() * 60 * 60000 - (date.getTimezoneOffset() * 60000 )))
                    .toISOString()
                    .split("T")[0];
            // Check if final exists or if preview exists
            x.thumbnail = `${this.getCloudProviderBase(true)}/${app.filenames.gameRoot}/${x.domeKey}/${x.yyyymmdd}/${footageId}/${app.filenames.thumbnail}`;
            if (newThumbnailFlag) {
                x.thumbnail = `${this.getCloudProviderBase(true)}/${app.filenames.gameRoot}/${x.domeKey}/${x.yyyymmdd}/${footageId}/${app.filenames.newThumbnailPrefix + "0" + app.filenames.newThumbnailExt}`;
            }

            return x;
        },
        transformDomeName(dome) {
            const domeData = this.findDomeByKey(dome);
            return domeData && domeData.name;
        },
        getDomeList() {
            return [
                {
                    name: "La Redkocha",
                    key: "laredkocha",
                    img: require("../assets/laredkocha.png"),
                    thumb: require("../assets/laredkochathumb.jpg"),
                    lat: 25.9180652,
                    lng: -80.2819595,
                    city: "Miami",
                    state: "Florida",
                    country: "United States",
                    lang: "es",
                },
                {
                    name: "High Soccer Arena",
                    key: "highsoccerarena",
                    img: require("../assets/highsoccerarena.png"),
                    thumb: require("../assets/highsoccerarenathumb.jpg"),
                    lat: 28.4212451,
                    lng: -81.3818119,
                    city: "Orlando",
                    state: "Florida",
                    country: "United States",
                    lang: "en",
                },
                {
                    name: "Soccer 5",
                    key: "soccer5",
                    img: require("../assets/soccer5.png"),
                    thumb: require("../assets/soccer5thumb.jpg"),
                    lat: 45.5400132,
                    lng: -73.5773621,
                    city: "Montreal",
                    state: "Quebec",
                    country: "Canada",
                    lang: "fr",
                },
                {
                    name: "Mr. Gol",
                    key: "mrgol",
                    img: require("../assets/mrgol.png"),
                    thumb: require("../assets/mrgolthumb.jpg"),
                    lat: 25.4719196,
                    lng: -80.4888005,
                    city: "Homestead",
                    state: "Florida",
                    country: "United States",
                    lang: "es",
                },
                {
                    name: "Urban Indoor Futbol",
                    key: "urbanindoorfutbol",
                    img: require("../assets/urbanindoorfutbol.png"),
                    thumb: require("../assets/urbanindoorfutbol.jpg"),
                    lat: 25.9180652,
                    lng: -80.2819595,
                    city: "Miami",
                    state: "Florida",
                    country: "United States",
                    lang: "es",
                },
                {
                    name: "Test",
                    key: "test",
                    img: require("../assets/mrgol.png"),
                    thumb: require("../assets/mrgolthumb.jpg"),
                    lat: 35.4700298,
                    lng: -97.52194,
                    city: "Oklahoma City",
                    state: "Oklahoma",
                    country: "United States",
                    lang: "en",
                },
                {
                    name: "District 5",
                    key: "district5",
                    img: require("../assets/district5.png"),
                    thumb: require("../assets/district5thumb.jpg"),
                    lat: 45.5664563,
                    lng: -73.5391321,
                    city: "Montreal",
                    state: "Quebec",
                    country: "Canada",
                    lang: "fr",
                },
            ]
        },
        getDomeKeyList() {
            return this.getDomeList().map(x => x.key);
        },
        findDomeByKey(key) {
            const domes = this.getDomeList();
            for (var i = 0; i < domes.length; i++) {
                if (domes[i].key.toLowerCase() == key.toLowerCase()) {
                    return domes[i];
                } 
            }
            return {}; 
        },
        findDomeByName(name) {
            const domes = this.getDomeList();
            for (var i = 0; i < domes.length; i++) {
                if (domes[i].name.toLowerCase() == name.toLowerCase()) {
                    return domes[i];
                } 
            }
            return {}; 
        },
        yyyymmdd(date) {
            var x = new Date(date);
            var y = x.getFullYear().toString();
            var m = (x.getMonth() + 1).toString();
            var d = x.getDate().toString();
            (d.length == 1) && (d = '0' + d);
            (m.length == 1) && (m = '0' + m);
            var yyyymmdd = y + m + d;
            return yyyymmdd;
        },
        haversineDistance(lat1, lon1, lat2, lon2, unit) {
            if ((lat1 == lat2) && (lon1 == lon2)) {
                return 0;
            }
            else {
                var radlat1 = Math.PI * lat1/180;
                var radlat2 = Math.PI * lat2/180;
                var theta = lon1-lon2;
                var radtheta = Math.PI * theta/180;
                var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
                if (dist > 1) {
                    dist = 1;
                }
                dist = Math.acos(dist);
                dist = dist * 180/Math.PI;
                dist = dist * 60 * 1.1515;
                if (unit=="K") { dist = dist * 1.609344 }
                if (unit=="N") { dist = dist * 0.8684 }
                return dist;
            }
        },
        getPlayData(playObj) {
            const split1 = playObj.URL.split("/");
            const suffix = split1[split1.length - 1];


            const gameKeyParts = playObj.GameKey.split("-");

            let dateObj = null
            let pattern = null
            let patternReplace = ""
            // to uspport time marker
            if (gameKeyParts[0].length == (4+2+2+2)) {
                pattern = /(\d{4})(\d{2})(\d{2})(\d{2})/
                patternReplace = '$1-$2-$3 $4:00'
            } else if (gameKeyParts[0].length == (4+2+2+2+2)) { // it has the minutes
                pattern = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/
                patternReplace = '$1-$2-$3 $4:$5'
            }
            
            dateObj = new Date(gameKeyParts[0].replace(pattern,patternReplace));
            //const username = split2.length == 3 ? split2[1] : null;
            const username = playObj.Username
            const arena = this.transformDomeName(playObj.Arena);
            const ret = {arena, dateObj, suffix};

            if (username) ret.username = username;
            
            ret.timeAgo = this.getTimeAgo(dateObj);
            ret.date = (dateObj).toLocaleString(undefined, {weekday: 'short', month: "short", day: "numeric"});

            return ret;
        },
        getTimeAgo(input) {
            const date = (input instanceof Date) ? input : new Date(input);
            const formatter = new Intl.RelativeTimeFormat(this.$i18n.locale || 'en');
            const ranges = {
                years: 3600 * 24 * 365,
                months: 3600 * 24 * 30,
                weeks: 3600 * 24 * 7,
                days: 3600 * 24,
                hours: 3600,
                minutes: 60,
                seconds: 1
            };
            const secondsElapsed = (date.getTime() - Date.now()) / 1000;
            for (let key in ranges) {
                if (ranges[key] < Math.abs(secondsElapsed)) {
                    const delta = secondsElapsed / ranges[key];
                    return formatter.format(Math.round(delta), key);
                }
            }
        },
        generalShare(url) {
            if (screen && screen.width < 760) {
				navigator.share({url});
			} else {

                this.copyToClipboard(url, (err, res) => {
                    console.log(res);
                    let msg = "";
                    if (!err) {
                        msg = this.$i18n.messages[this.$i18n.locale].profile.copy_success;
                        this.$swal({title: msg,
                            timer: 2000,
                            heightAuto: false
                        });
                    } else {
                        msg = err;
                        this.$swal(err);
                    }
                });
			}
        },
        shareWithoutSweetalert(url) {
            if (screen && screen.width < 760) {
				navigator.share({url});
			} else {

                this.copyToClipboard(url, (err, res) => {
                    console.log(res);
                    let msg = "";
                    if (!err) {
                        msg = this.$i18n.messages[this.$i18n.locale].profile.copy_success;
                        this.$toasted.success(msg, { position: "bottom-center" }).goAway(2000);
                    } else {
                        msg = err;
                        this.$swal(err);
                    }
                });
			}
        },
        copyToClipboard(text, cb) {
            // This fails on IE
            navigator.clipboard.writeText(text).then(function() {
                console.log("Copied To Clipboard", text);
                if (typeof cb === "function") cb(null, text);
            }, function() {
                console.log("Copied To Clipboard Failed", text);
                if (typeof cb === "function") cb("Error copying text", text);
            });
        },
        getDateObject(dateString, appendString) {
            let date = dateString + appendString;
            return new Date(date.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,'$4:$5:$6 $2/$3/$1'));
        },
        l(logmsg) {
            console.log(logmsg);
        },
        myReplaySlackHook(channel, title, text, silent, arena) {
			this.l("calling myReplaySlackHook '" + channel + "' " + title + " arena: " + arena);

            if (channel == "log-users"){
                if (arena && (arena == "District 5" || arena == "district5")) {
                    channel = slack.user_channels["district5"]
                } else {
                    channel = slack.user_channels["default"]
                }
                this.l("calling myReplaySlackHook new channel '" + channel);
            }
            let payload = {
                'channel': channel,
                'username': 'fredbot',
                'text': "*" + title + "* | " + navigator.userAgent + "\n" + text,
                'icon_emoji': ':robot_face:'
			}
            if (!silent) {
                axios.post(slack.myReplaySlackHook, payload, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                });
            }
		},
        async getClientIP() {
            try {
                let r = await axios.get("https://www.geoplugin.net/json.gp");
                return JSON.stringify(r.data.geoplugin_request, null, 2);
            } catch {
                return "-";
            }
        },
        convertHMS(value) {
            const sec   = parseInt(value, 10); // convert value to number if it's string
            let hours   = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
            let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours   < 10) { hours   = "0" + hours;    }
            if (minutes < 10) { minutes = "0" + minutes;  }
            if (seconds < 10) { seconds = "0" + seconds;  }
            let secc = '';
            hours = hours == "00" ? '' : hours + ':';
            if (minutes == "00") { minutes = ''; secc = ' sec(s)'; }
            else minutes = minutes + ':';
            return hours + minutes + seconds + secc; // Return is HH : MM : SS
        },
        treatAsUTC(date) {
            var result = new Date(date);
            result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
            return result;
        },
        daysBetween(startDate, endDate) {
            var millisecondsPerDay = 24 * 60 * 60 * 1000;
            return (this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerDay;
        },
        makeFieldAndTimeDropdownObjects(data) {

            console.log("makeFieldAndTimeDropdownObjects " , data)
            const hours = [], fields = [];
            data.fields.forEach(field => {
                fields.push({name: this.$i18n.messages[this.$i18n.locale].search.field + " " + field, key: field});
            });
            data.hours.forEach(x => {
                const time = `${this.convertTime(x)} - ${this.convertTime(x, true)}`;
                hours.push({name: time, key: x});
            });
            const uniqueHours = hours.filter((v, i, a) => {return a.map(x => x.key).indexOf(v.key) === i});
            const uniqueFields = fields.filter((v, i, a) => {return a.map(x => x.key).indexOf(v.key) === i});

            const sortedHours = uniqueHours.sort((a, b) => (
                +(a.key.substring(0,2)) - +(b.key.substring(0,2))
            ))
            return {
                'fields': uniqueFields.sort((a, b) => (+a.key - +b.key)),
                'hours': sortedHours
            };
        },
        setHoursAndFields(data) {
            const hours = [];
            const fields = [];
            // {name: , key: }
            data.forEach(x => {
                const split = x.Prefix.split("-");

                if (split.length > 3) {
                    fields.push({name: this.$i18n.messages[this.$i18n.locale].search.field + " " + split[3][0], key: split[3][0]});
                } else {
                    const slashes = x.Prefix.split("/");
                    let datetime = slashes[slashes.length - 1]
                    datetime = datetime.substring(0, datetime.length - 1)
                    const hour = datetime.substring(8)
                    const time = `${this.convertTime(hour)} - ${this.convertTime(hour, true)}`;
                    
                    hours.push({name: time, key: hour});
                }
            });

            const uniqueHours = hours.filter((v, i, a) => {return a.map(x => x.key).indexOf(v.key) === i});
            // Make unique lists based on fields
            return {
                'fields': fields.filter((v, i, a) => {return a.map(x => x.key).indexOf(v.key) === i}),
                'hours': uniqueHours.sort((a, b) => (a.key < b.key))
            };
        },
        getCurrentBanners(workflow, arena, date, banners) {
            date = date == null ? new Date() : date;

            function isValidDuration(start, end) {
                start = new Date((start+"000000").replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,'$4:$5:$6 $2/$3/$1'));
                end = new Date((end+"000000").replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,'$4:$5:$6 $2/$3/$1'));
                if (start <= date && end >= date)
                    return true;
                else
                    return false;
            }

            let banner = banners.filter(function (banner) {
                return banner.arena.includes(arena) && isValidDuration(banner.date_start, banner.date_end);
            });
            if (banner != null && banner.length > 0) {
                if (workflow == "feed")
                    banner = banner.filter(function (b) {
                        if(b.nav && b.nav.find(x => x == 'feed')) return true; else return false;
                    });
                else if (workflow == "search")
                    banner = banner.filter(function (b) {
                        if(!b.nav) return true; if(b.nav && b.nav.find(x => x == 'search')) return true; else return false;
                    });
            } else banner = null;
            return banner;
        },
        getBannersList(workflow, currentBanner, arena, username) {
            let banners = [];
            if(currentBanner)
                currentBanner.filter(banner => {
                    // no need to add DOME name inthe key as IDs are unique amoung the Arenas
                    // if the user is not logged in, the username would be null and the banners would reappear if the user logs in
                    if (localStorage.getItem(banner.id + (workflow == "search" ? '-banner-' : '-feed-banner-') + arena + '-' + (username ? username : '')) &&
                        localStorage.getItem(banner.id + (workflow == "search" ? '-banner-' : '-feed-banner-') + arena + '-' + (username ? username : '')) == 'hide') {
                        // pass
                    } else {
                        banners.push(banner);
                    }
            });
            return banners;
        },
        downloadVideoFile(url, filename) {
            // var headers = {'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'};
            fetch(url, {
                mode: 'no-cors'
            }).then(function (t) {
                return t.blob().then(() => {
                    let a = document.createElement("a");
                    a.href = url;
                    a.setAttribute("download", filename);
                    a.click();
                });
            });
        }
    }
}