<template>
	<div class="contest-container">
        <div class="img-background"></div>
        <div class="contest-background">
            <img class="logo" src="../assets/logo.png">
            <div class="contest-header--title">
                <div class="contest-title">Nicest Play Wins</div>
                <div class="contest-deadline">Contest Ends Thu, Jan 20, 5:00PM</div>
                <img class="contest-prize-image" src="../assets/contest_prize.jpg">
                <div class="contest-prize-description">Nike Mercurial Superfly 8 Elite CR7 FG Soccer Cleats - $250 Value</div>
            </div>
            
            <!-- <div class="total-entry-container">
                
            </div> -->
            <div class="video-container">
                <div class="total-entry">{{totalSubmissions}}</div>
                <div class="total-entry--description">Total Entries</div>
                <div class="winning-video-description">Current Top Plays in Florida</div>
                <!-- TO CHANGE -->
                <div v-for="(t, index) in top10" :key="t.instagram + index">
                    <div class="entry-divider"></div>
                    <a target="_blank" :href='`https://www.instagram.com/${t.instagram}/`'><div class="winning-entry-profile">
                        <span class="play-number">Play #{{index + 1}}</span><img :src='t.profilePic' class="insta-img">
                        By <span class="insta-link">@{{t.instagram}}</span>    
                    </div></a>

                    <video-player :no-border="true" :options="{
                        autoplay: (index == 0),
                        muted: (index == 0),
                        controls: true,
                        profile: true,
                        sources: [
                            {
                                src: t.sourceURL,
                                type: 'video/mp4'
                            }
                        ]
                    }"></video-player>

                    <div class="vote-container">
                        <div class="vote-button-container">
                            <div class="vote-button" :class="{'checked': t.upvoted}" @click="upvote(t.sourceURL)"></div>
                        </div>
                        <div class="num-upvotes">{{t.numVotes}} <span class="upvotes-desc">Upvotes</span></div>
                    </div>
                    
                </div>
            </div>
            <div class="CTA-container">
                <div class="CTA-prompt">Can you beat them?</div>
                <button class="CTA-button" id="browse">Upload Your Submission</button>
                <div class="CTA-success" :class="{'hidden': !uploadSuccess}">Your play was successfully uploaded! Complete your entry below to enter the contest.</div>
            </div>
            <div class="hubspot-form-container">
                <div id="hubspot-form"></div>
            </div>
            <!--[if lte IE 8]>
            <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
            <![endif]-->
            
            <!-- <file-drop></file-drop> -->
            <!-- This pulls Uppy from our CDN. Alternatively use `npm i @uppy/robodog --save` -->
<!-- if you want smaller self-hosted bundles and/or to use modern JavaScript -->
            
        </div>
	</div>
</template>


            
<script>
//import FileDrop from './FileDrop.vue'
import VideoPlayer from './VideoPlayer';
import api from '../api/server.js';
import axios from "axios";

export default {
	name: 'Contest',
    components: {
        //FileDrop,
        VideoPlayer
    },
	props: {
        
	},
	data() {
		return {
            top10: [
                {
                    sourceURL: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/newTop1.mp4', // TO CHANGE
                    profilePic: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/profile1.jpg', // TO CHANGE
                    instagram: 'fbsfc', // TO CHANGE
                    upvoted: false,
                    numVotes: 0,
                },
                {
                    sourceURL: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/newTop2.mp4', // TO CHANGE
                    profilePic: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/profile3.jpg', // TO CHANGE
                    instagram: 'alessiosoccermiami', // TO CHANGE
                    upvoted: false,
                    numVotes: 0,
                },
                {
                    sourceURL: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/newTop3.mp4', // TO CHANGE
                    profilePic: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/profile1.jpg', // TO CHANGE
                    instagram: 'fbsfc', // TO CHANGE
                    upvoted: false,
                    numVotes: 0,
                },
                {
                    sourceURL: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/newTop4.mp4', // TO CHANGE
                    profilePic: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/profile4.jpg', // TO CHANGE
                    instagram: 'kendall.lionsfc', // TO CHANGE
                    upvoted: false,
                    numVotes: 0,
                },
                {
                    sourceURL: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/newTop5.mp4', // TO CHANGE
                    profilePic: 'https://replay-ott.s3.us-east-2.amazonaws.com/raw/profile5.jpg', // TO CHANGE
                    instagram: 'rodrigosanchez.gol', // TO CHANGE
                    upvoted: false,
                    numVotes: 0,
                }
            ],            
            uploadSuccess: false,
            totalSubId: "",
            totalSubmissions: 0,
            uniqueUserId: "",
            upvotedLoading: true,
		}
	},
    methods: {
        smoothScroll(element){
            document.querySelector(element).scrollIntoView({
                behavior: 'smooth'
            });
        },
        getUpvotes(url) {
            const that = this;
            api.getUpvotesForURL(url, (err, numVotes) => {
                if (err) {
                    console.log(err)
                } else {
                    const topIndex = that.top10.findIndex(x => (x.sourceURL === url));
                    console.log("TOP INDEX", topIndex, url, numVotes);
                    that.top10[topIndex].numVotes = numVotes;
                }
            });
        },
        getUserVoteStatus(url) {
            const that = this;
            api.getUserVoteStatus(this.uniqueUserId, url, (err, votedStatus) => {
                if (err) {
                    console.log(err)
                } else {
                    const topIndex = that.top10.findIndex(x => (x.sourceURL === url));
                    that.top10[topIndex].upvoted = votedStatus;
                    that.upvotedLoading = false;
                }
            });
        },
        fetchProfile(instagram) {
            const that = this;
            api.getInstagramInfo(instagram, (err, res) => {
                console.log(err, res, that);
                //that.profilePic = res.profile_pic_url_hd;
            });
        },
        upvote(url) {
            if (this.upvotedLoading) {
                alert("Can't vote yet! Try again now");
                return;
            }
            if (this.upvoted) {
                alert("You can only vote on this video once!");
                return;
            }

            const topIndex = this.top10.findIndex(x => (x.sourceURL === url));
            this.top10[topIndex].upvoted = true;
            this.top10[topIndex].numVotes = this.top10[topIndex].numVotes + 1;
            
            api.upvoteURL(this.uniqueUserId, url, (err, votedStatus) => {
                if (err) {
                    console.log(err)
                } else {
                    console.log(votedStatus);
                }
            });
        },
        makeid(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
                charactersLength));
            }
            return result;
        },
        queryAPI(end_cursor, cb, acc) {
            console.log("CALLING", end_cursor, cb, acc)
            const that = this;
            var options = {
                method: 'GET',
                url: 'https://instagram-data1.p.rapidapi.com/hashtag/feed',
                params: {
                    hashtag: 'miamisoccer',
                    end_cursor
                },
                headers: {
                    'x-rapidapi-host': 'instagram-data1.p.rapidapi.com',
                    'x-rapidapi-key': 'ca27cf97cfmshe43da4b3e9c0f6bp1dbebejsn9691ad6b2495'
                }
            };

            if (!end_cursor) {
                delete options.params.end_cursor;
            }

            axios.request(options).then(function (response) {
                //console.log(response.data);

                if (response.data.has_more) {
                    acc = (acc || []).concat(response.data.collector);
                    that.queryAPI(response.data.end_cursor, cb, acc);
                } else {
                    // END
                    cb(null, acc);
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    },
    created() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://js.hsforms.net/forms/v2.js')
        document.head.appendChild(recaptchaScript);

        let recaptchaScript2 = document.createElement('script')
        recaptchaScript2.setAttribute('src', 'https://releases.transloadit.com/uppy/robodog/v2.0.1/robodog.min.js')
        document.head.appendChild(recaptchaScript2);
    },
    mounted() {

        const that = this;

        let tries = 0;

        const getUserId = function() {
            console.log("Getting user ID");
            that.uniqueUserId = window.heap && window.heap.identity;
            tries += 1;
            if (!that.uniqueUserId && tries < 3) {
                setTimeout(function() {
                    getUserId();
                }, 300);
            } else {

                if (!that.uniqueUserId) {
                    that.uniqueUserId = localStorage['contest_id'];
                    if (!that.uniqueUserId) {
                        localStorage['contest_id'] = that.makeid(10);
                        that.uniqueUserId = localStorage['contest_id'];
                    }
                }

                that.top10.forEach(top => {
                    that.getUserVoteStatus(top.sourceURL);
                });
               
            }
        }

        getUserId();
        this.top10.forEach(top => {
            that.getUpvotes(top.sourceURL);
            that.fetchProfile(top.instagram);
        });
        

        // this.queryAPI(null, (err, res) => {
        //     console.log(err, "FINAL", res)
        // });

        // Get # votes
        // Get whether user voted or not

        api.checkSubmissions((err, id, count) => {
            if (err) {
                console.log(err)
            } else {
                that.totalSubId = id;
                that.totalSubmissions = count;
            }
        });

        document.getElementById('browse').addEventListener('click', function () {
            var uppy = window.Robodog.pick({
                providers: [ 'instagram', 'url', 'webcam', 'dropbox', 'google-drive', 'facebook', 'onedrive' ],
                waitForEncoding: false,
                params: {
                    // To avoid tampering, use Signature Authentication
                    auth: { key: '479def5aecae498496ab12d41d94269e' },
                    template_id: '774f9ec9e98940f986b8aa55ec185489'
                }
            }).then(function (bundle) {
                // Due to `waitForEncoding: true` this is fired after encoding is done.
                // Alternatively, set `waitForEncoding` to `false` and provide a `notify_url`
                // for Async Mode where your back-end receives the encoding results
                // so that your user can be on their way as soon as the upload completes.
                console.log(bundle.transloadit) // Array of Assembly Statuses
                console.log(bundle.results)     // Array of all encoding results
                that.uploadSuccess = true;

                api.incrementSubmissions(that.totalSubId, (parseInt(that.totalSubmissions) + 1) + "", (err, res) => {
                    console.log(err, res);
                });
                
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "19575772",
                    formId: "a4e21dbb-d979-46a4-abad-50411b587b15",
                    target: "#hubspot-form"
                });

                setTimeout(function() {
                    that.smoothScroll('#hubspot-form');
                    document.getElementById("hs-form-iframe-0").contentDocument.querySelector('input[name="assemblyid"]').value = bundle.transloadit[0].assembly_id;
                }, 500)

            }).catch(console.error)
            console.log(uppy);
        });

        
        
    }
}
</script>

<style src="../assets/reset.css">

</style>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Play&family=Squada+One&display=swap');
    @import url('https://releases.transloadit.com/uppy/robodog/v2.0.1/robodog.min.css');
    * {
        font-family: 'Squada One';
    }
    .img-background {
        background: linear-gradient(0deg, rgb(0 0 0 / 70%), rgb(0 0 0 / 51%)), url("../assets/contest_background.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100vh;
        width: 100vw;
        position: fixed;
    }
    .contest-background {
        /* height: 100vh;
        width: 100%;
        background-position: center;
        background-size: cover; */
        color: #fff;
        text-align: center;
        display: inline-block;
        position: absolute;
        width: 100%;
    }

    .logo {
        width: 150px;
        margin: 12px auto 0px;
    }

    .contest-header--title {
        background: rgba(255, 255, 255, 0.3);
        margin: 12px auto 24px;
        padding: 12px;
        max-width: 500px;
    }

    .video-container {
        background: rgba(255, 255, 255, 0.3);
        margin: 0 auto;
        padding: 12px;
        width: 100%;
        max-width: 500px;
    }
    .contest-title {
        font-size: 2.6em;
        padding: 12px;
    }
    .contest-prize-description {
        font-family: 'Play';
        padding: 0 12px;
    }
    .contest-prize-image {
        margin: -64px auto -30px;
        width: 57%;
        max-width: 450px;
    }
    .contest-deadline {
        font-family: 'Nunito';
        padding: 0 12px;
        font-style: italic;
    }
    .winning-video-description {
        margin: 20px 0 16px;
        padding: 0 12px;
        font-size: 1.5em;
        font-family: 'Play';
    }
    .winning-entry-profile {
        font-family: 'Nunito';
        color: #fff;
        margin: -22px 0 16px;
        text-align: initial;
    }
    .entry-divider {
        margin-top: 16px;
        margin-bottom: 16px;
        width: 100%;
        height: 1px;
        background: #fafafa;
    }
    .play-number {
        font-family: 'Nunito';
        margin-right: 20px;
        font-size: 22px;
    }
    .insta-link {
        font-family: 'Nunito';
        text-decoration: underline;
    }
    .insta-img {
        display: inline-block;
        width: 40px;
        border-radius: 50%;
        transform: translate(-5px, 33%);
    }
    .total-entry-container {
        background: rgba(255, 255, 255, 0.3);
        margin: 12px auto 24px;
        padding: 12px;
        max-width: 500px;
    }
    .total-entry {
        font-size: 3em;
    }
    .total-entry--description {
        font-family: Nunito;
    }
    .CTA-container {

    }
    .CTA-prompt {
        padding: 12px 12px;
        font-size: 2em;
    }

    .CTA-button {
        appearance: none;
        background-color: #ef3856;
        border: 2px solid #1A1A1A;
        border-radius: 15px;
        box-sizing: border-box;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        font-family: Play,Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        margin: 0;
        min-height: 60px;
        min-width: 0;
        outline: none;
        padding: 16px 24px;
        text-align: center;
        text-decoration: none;
        transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        will-change: transform;
    }

    .CTA-button:disabled {
        pointer-events: none;
    }

    .CTA-button:hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
        transform: translateY(-2px);
    }

    .CTA-button:active {
        box-shadow: none;
        transform: translateY(0);
    }

    .CTA-success {
        font-family: Nunito;
        padding: 24px 12px;
        margin: 12px;
        background: rgba(239, 56, 86, 0.3);
        border-radius: 12px;

        -webkit-animation: fadeInFromNone 0.3s ease-out;
        -moz-animation: fadeInFromNone 0.3s ease-out;
        -o-animation: fadeInFromNone 0.3s ease-out;
        animation: fadeInFromNone 0.3s ease-out;
    }

    .hidden {
        pointer-events: none;
        display: none;
    }

    .hubspot-form-container {
        max-width: 500px;
        margin: 0 auto;
        padding: 12px;
    }

    .vote-container {
        margin: 12px 0 0;
    }

    .vote-button-container {
        background: rgba(255, 255, 255, 0.5);
        padding: 8px 8px;
        border-radius: 50%;
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 10px;
    }
    .vote-button {
        border-bottom: 0.5rem solid;
        border-right: 0.5rem solid;
        display: inline-block;
        width: 100%;
        height: 100%;
        color: #fff;
        transform: rotate(-135deg);
        display: inline-block;
        margin-right: 16px;
        cursor: pointer;
        transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
        will-change: transform;
    }

    .vote-button.checked {
        color: #ef3856;
    }

    .vote-button:hover {
        transform: rotate(-135deg) translate(3px, 3px);
    }

    .num-upvotes {
        display: inline-block;
        font-size: 1.75em;
        line-height: 1.75em;
        vertical-align: super;
        font-family: 'Play';
    }

    .upvotes-desc {
        font-family: inherit;
    }

    @-webkit-keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }
        1% {
            display: block;
            opacity: 0;
        }
        100% {
            display: block;
            opacity: 1;
        }
    }

    @-moz-keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }
        1% {
            display: block;
            opacity: 0;
        }
        100% {
            display: block;
            opacity: 1;
        }
    }

    @-o-keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }
        1% {
            display: block;
            opacity: 0;
        }
        100% {
            display: block;
            opacity: 1;
        }
    }

    @keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }
        1% {
            display: block;
            opacity: 0;
        }
        100% {
            display: block;
            opacity: 1;
        }
    }
</style>
