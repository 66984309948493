import App from "@/App";
import Footage from "@/components/Footage";
import FootagePreview from "@/components/FootagePreview";
import guard, {guardConvert, guardLogout, guardProfile, guardFootage, pass} from "@/router/guards";
import Conversion from "@/components/ConversionPage";
import UserProfile from "@/components/UserProfile";
import GameEditor from "@/components/GameEditor";
import GameSearch from "@/components/GameSearch";
import PlayViewer from "@/components/PlayViewer";
import OwnerFeed from "@/components/OwnerFeed";
import Tablet from "@/components/Tablet";
import Terms from "@/components/Terms";
import Facilities from "@/components/Facilities";
import Contest from "@/components/Contest";
import VueRouter from "vue-router";
import axios from "axios";

const routes = [
	{path: '/', component: App, beforeEnter: (to, from, next) => {
			console.log("ENTERING PAGE!", to.query, from)
			let title = ""
			if ("qrcode-tshirt" in to.query) {
				console.log("QR CODE T SHIRT!!!")
				title = "qrcode-tshirt"
			} else if ("ref" in to.query) {
				console.log("QR CODE!!!")
				title = to.query["ref"]
			}


			if (title != "") {
				let payload = {
					'channel': "log-users-district5",
					'username': 'fredbot',
					'text': "*" + title + "* | " + navigator.userAgent + "\nroot",
					'icon_emoji': ':robot_face:'
				}
				axios.post("https://hooks.slack.com/services/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", payload, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded"
					}
				});

			}

			next()
		}
	},
	{
		path: '/logout', beforeEnter: (to, from, next) => {
			guard(to, from, next, guardLogout);
		}
	},
	{
		name: "profile",
		path: '/profile/:username',
		component: UserProfile,
		beforeEnter: (to, from, next) => {
			console.log("Guarding profile");
			guard(to, from, next, guardProfile);
		}
	},
	{
		name: "userFeed",
		path: '/feed/:arena',
		component: OwnerFeed, beforeEnter: (to, from, next) => {
			let title = ""
			console.log("ENTERING PAGE FEED!", to.query, from)
			if ("qrcode-tshirt" in to.query) {
				console.log("QR CODE T SHIRT!!!")
				title = "qrcode-tshirt"
			} else if ("ref" in to.query) {
				console.log("QR CODE!!!")
				title = to.query["ref"]
			}


			if (title != "") {
				let payload = {
					'channel': "log-users-district5",
					'username': 'fredbot',
					'text': "*" + title + "* | " + navigator.userAgent + "\nfeed",
					'icon_emoji': ':robot_face:'
				}
				axios.post("https://hooks.slack.com/services/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", payload, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded"
					}
				});

			}

			next()
		}
	},
	{
		name: "ownerFeed",
		path: '/ownerfeed/:arena',
		component: OwnerFeed,
	},
	{
		name: "curationFeed",
		path: '/curation/:arena',
		component: OwnerFeed,
	},
	{
		name: "tablet",
		path: '/tablet/:arena',
		component: Tablet,
	},
	{
		name: "search",
		path: '/search',
		component: GameSearch,
		beforeEnter: (to, from, next) => {
			guard(to, from, next, pass);
		}
	},
	{
		name: 'footagePreview', // For Ads on TVs
		path: '/footagePreview/:dome/:id',
		component: FootagePreview,
		// beforeEnter: (to, from, next) => {
		// 	guard(to, from, next, guardFootage);
		// }
	},
	{
		name: 'footage',
		path: '/footage/:footageId/:state?/:plan?',
		component: Footage,
		beforeEnter: (to, from, next) => {
			guard(to, from, next, guardFootage);
		}
	},
	{
		name: 'preview',
		path: '/preview/:footageId',
		component: Footage,
		beforeEnter: (to, from, next) => {
			console.log("YOOOO" , to)
			guard(to, from, next, guardFootage);
		}
	},
	{
		name: 'convert', // Step1: changed name to reflect the path in the auth  flow
		path: '/convert/:team/:date',
		component: Conversion,
		beforeEnter: (to, from, next) => {
			// Q: getting user data
			// what's the best way between "store.getters.user" or "Vue.prototype.$auth.user" ?
			// Or it's better to wait for the atcher / async (like now?

			guard(to, from, next, guardConvert);

		}
	},
	{
		name: 'convertRegistered', // Step 2: on call back post signup, just before paying on stripe
		path: '/convert/:team/:date/:player',
		component: Conversion,
		beforeEnter: (to, from, next) => {
			guard(to, from, next, guardConvert);

		}
	},
	{
		name: 'convertPaid', // Step 3: on call back post signup, just before paying on stripe
		path: '/convert/:team/:date/:player/:authsub',
		component: Conversion,
		beforeEnter: (to, from, next) => {

			guard(to, from, next, guardConvert);

		}
	},
	{
		name: "gameEditor",
		path: '/gameEditor/shahmir/:url',
		component: GameEditor,
		beforeEnter: (to, from, next) => {
			guard(to, from, next, guardProfile);
		}
	},
	{
		name: 'internal',
		path: '/internal/:footageId',
		component: PlayViewer,
	},
	{
		name: 'facilities',
		path: '/facilities',
		component: Facilities,
		// beforeEnter: (to, from, next) => {
		// 	next(false);
		// 	window.location.href = "https://book.myreplay.co/replay-for-domes";
		// }
	},
	// {
	// 	name: 'facilitiesDraft',
	// 	path: '/facilitiesDraft',
	// 	component: Facilities,
	// 	// beforeEnter: (to, from, next) => {
	// 	// 	next(false);
	// 	// 	window.location.href = "https://book.myreplay.co/replay-for-domes";
	// 	// }
	// },
	{
		name: 'terms',
		path: '/terms',
		component: Terms,
	},
	{
		name: 'topPlayContest',
		path: '/contest',
		component: Contest,
	},
];

export default new VueRouter({
    routes: routes
});