<template>
	<div>
		<video oncontextmenu="return false;"
			ref="videoPlayer"
			:class="{'editor': options.editor, 'no-border': noBorder}"
			class="video-js vjs-theme-city vjs-big-play-centered vjs-16-9 rounded-video"
		>
		</video>
	</div>
</template>

<script>
import videojs from 'video.js';

// see: https://www.npmjs.com/package/videojs-markers-plugin
import "@/plugins/videojs-markers/videojs.markers.plugin.js";
import "@/plugins/videojs-markers/videojs.markers.plugin.css";

import "videojs-playlist/dist/videojs-playlist.js";
import "videojs-seek-buttons/dist/videojs-seek-buttons.min.js";
import 'videojs-landscape-fullscreen';
//import '../helpers/mobileUI.js';
import {_} from 'vue-underscore';

import api from "@/api";

export default {
	name: 'VideoPlayer',
	props: {
		options: {
			type: Object,
			default() {
				return {};
			}
		},
		markers: {
			type: Array,
			default() {
				return [];
			}
		},
		currentMarkerId: {
			type: Number,
			default() {
				return 0;
			}
		},
		preview: {
			type: Boolean,
			default() {
				return false;
			}
		},
		purchased: {
			type: Boolean,
			default() {
				return false;
			}
		},
		paymentDate: {
			type: String,
			default() {
				return "";
			}
		},
		noBorder: {
			type: Boolean,
			default() {
				return false;
			}
		}
	},
	data() {
		return {
			player: null,
			user: this.$store.getters.user,
			maxQuota: 60,
			trackerInterval: 5,
		}
	},
	methods: {
		pause() {
			this.player.pause();
		},
		play() {
			this.player.play();
		},
		_keyListener(e) {
			if (e.keyCode === 32 && !this.options.profile) {
				e.preventDefault();
				if (this.player.paused()) {
					this.play();
				} else {
					this.pause();
				}
				return false;
			}
			if ((e.ctrlKey || e.metaKey) && e.keyCode === 37) {
				this.player.currentTime(this.player.currentTime() - this.trackerInterval);
			}
			if ((e.ctrlKey || e.metaKey) && e.keyCode === 39) {
				this.player.currentTime(this.player.currentTime() + this.trackerInterval);
			}
		},
		clickListener(marker) {
			this.player.playlist.currentItem(marker && marker.sequenceId);
		},
		convertToSeconds(time) {
			// Time in HH:MM:SS or HH:SS
			var p = time.split(':'),
				s = 0, m = 1;
			while (p.length > 0) {
				s += m * parseInt(p.pop(), 10);
				m *= 60;
			}
			return s;
		},
		setTime(time) {
			this.player && this.player.currentTime(time);
		}
	},
	watch: {
		markers: function(newVal, oldVal) {
			if (newVal && newVal.sources && newVal.sources.length > 0) {
				if (this.markers) {
					this.player.markers({
						markers: this.markers,
						breakOverlay : {
							display: true
						},
						markerTip: {
							text: function text(marker) {
								return "Clip: " + marker.text;
							},
						}
					});
				}
			}

			if (this.player && newVal.sources && oldVal.sources[0].src !== newVal.sources[0].src) {
				this.player.src(newVal.sources[0].src);
			}
		},
		options: function(newVal, oldVal) {
			const that = this;
			if (newVal && newVal.sources && newVal.sources.length > 0) {
				let video = videojs(this.$refs.videoPlayer, newVal);
				console.log("VIDEEEOJS CREATED", newVal, oldVal)
				console.log("markers -> ", this.markers)
				if (this.markers) {
					// video.markers({
					// 	markers: this.markers,
					// 	markerTip: {
					// 		text: function text(marker) {
					// 			return "Clip: " + marker.text;
					// 		},
					// 	}
					// });
				}
				
				video.playsinline(true);

				video.on('error', function(event) {
					
					console.log("VIDEO JS ERROR FREEEEEEED", event);
					console.log(video.error());
					//if (event.target.error.message.includes("not be loaded")) {
					const message = "Game CURRENTLY PROCESSING. Please try again in 1h hour or with a DIFFERENT DEVICE." // that.$i18n.messages[that.$i18n.locale].footage['missing_game'];
					console.log("MESSAGE", message);

					that.myReplaySlackHook("log-errors", "VIDEOJS_ERROR_CODE_" + video.error().code, video.error().message)
					video.createModal(message);
					//}
				});

				if (this.$route.query && this.$route.query["t"]) {
					video.currentTime(this.$route.query["t"]);
				}

				if (this.options.footage) {
					// Only apply this logic on the footage page

					const debouncedTimeout = _.debounce(function() {
						that.$emit('viewing-timeout');
						video.exitFullscreen();
						video.pause();
					}, 300);

					const blockView = function() {
						video.on('play', function() {
							if (!that.purchased) {
								video.pause();
								debouncedTimeout();
							}
						});
					}

					let numViews = 0;

					// todo: to put this state query in a POJO user object...
					const date = this.paymentDate;

					const trackView = _.debounce(function (curTime) {
						numViews += 1;
						
						window.heap.track("viewPlayInGame", {time: curTime});
						if (numViews === 60) {
							// The 5-minute mark of viewing, which we are choosing as the cohort OMTM
							window.heap.track("viewed5Minutes");
						}

						// start tracking minutes if the user have not purchase this video
						if (curTime && curTime !== "0" && !that.preview && !that.purchased) {

							api.incrementConsumptionUsage(that.user, date, that.trackerInterval, function(quotaState) {

								// check if busted the one minute
								if (quotaState >= that.maxQuota) {
									blockView();
									debouncedTimeout();
								}
							});


						}

					}, 1200);

					video.on('timeupdate', function () {
						// track!
						that.$emit('timeupdate', video.currentTime());
						const curTime = video.currentTime().toFixed(0);
						if (curTime % that.trackerInterval === 0) {
							trackView(curTime);
						}
					});

					if (that.$parent.checkQuotaStatus() === 0 && !this.purchased) blockView();
				}

				this.player = video;
				if (!this.options.profile) {
					this.player.seekButtons({
						forward: 15,
						back: 15
					});
				}
			}

			if (this.player && newVal.sources && oldVal.sources[0].src !== newVal.sources[0].src) {
				console.log("NEWW: ", newVal.sources[0].src)
				this.player.src(newVal.sources[0].src);
			}
		}
	},
	mounted() {
		const that = this;

		if (this.options.profile) {
			// This is the Profile page Video, for now
			let video = videojs(this.$refs.videoPlayer, this.options);
			video.playsinline(true);

			//Fake poster link
			const url = that.options.sources[0].src;
			const poster = url.replace("mp4", "jpg");
			video.poster(poster);
			// Currently only here when in profile
			const trackStart = _.debounce(function () {
					that.$emit('highlight-viewed', url);
				},
				2000);

			video.loop(true);
			video.on('timeupdate', function () {
				const curTime = parseInt(video.currentTime());
				if (curTime === 0) {
					trackStart();
				}
			});
			this.player = video;
		} else if (this.options.editor) {
			let video = videojs(this.$refs.videoPlayer, this.options);
			video.playsinline(true);
			// Video loaded on Editor page
			video.on('timeupdate', function () {
				const curTime = video.currentTime().toFixed(0);
				that.$emit('timeupdate', curTime);
			});


			this.player = video;
		} else if (this.options.footage) {
			// New game page
			// In this page we don't load the footage yet. We watch for the change in source in the watcher
			
		}

		window.addEventListener("keyup", this._keyListener);
		window.onkeydown = function (e) {
			// Stop scroll on keydown of spacebar
			return e.keyCode !== 32;
		};
	},
	created() {
		const that = this;
		if (this.options.editor) {
			this.$root.$on('clickEditorMarker', (event, time) => {
				if (time) {
					console.log(that.player, "NOT READY?");
					that.player.currentTime(that.convertToSeconds(time));
				}
			});
		}
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
		window.removeEventListener('keyup', this._keyListener);
		this.$root.$off('markerClick', this.clickListener);
	}
}
</script>


<style>
.video-js, .vjs-tech {
	border-radius: 24px;
	box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
}

.video-js.no-border, .no-border .vjs-tech {
	border-radius: 0px;
}

.video-js {

}

.video-js.editor, .vjs-tech.editor {

}

/* This appears to be needed because the control bar (or its contents) will otherwise break out of the player. */
.video-js .vjs-control-bar {
	border-bottom-left-radius: 24px;
	border-bottom-right-radius: 24px;
}

.video-js.no-border .vjs-control-bar {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.vjs-poster {
	border-radius: 24px;
	box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
}
.no-border .vjs-poster {
	border-radius: 0px;
}

.vjs-modal-dialog {
	border-radius: 24px;
}
.no-border .vjs-modal-dialog {
	border-radius: 0px;
}

.vjs-modal-dialog .vjs-modal-dialog-content {
    font-size: 1.8em !important;
    vertical-align: middle;
    text-align: center;
    line-height: 1.5;
    padding: 20px 24px;
    z-index: 0;
    background: #000;
    border-radius: 24px;
    font-family: 'Nunito';
    padding-top: 20% !important;
}

.no-border .vjs-modal-dialog .vjs-modal-dialog-content {
    border-radius: 0px;
}

</style>

<style src='video.js/dist/video-js.css'>

</style>

<style src='videojs-seek-buttons/dist/videojs-seek-buttons.css'>

</style>