<template>
	<div :class='{modalOpen: showModal}' class="conversion-page">
		<replay-header :landingStyle=true style="position: fixed; width: 100%;"></replay-header>
		<div class="conversion-container">
			<div class="conversion-container--content-wrapper">
				<div class="game-title">{{ team }} - {{ date }}</div>
				<div class="game-subtitle">{{ subtitle }}</div>
				<img :src="thumbnail" class="game-thumbnail">
				<player-selector :players="players" :selectedPlayer="selectedPlayer" buttonText="Sign Up" @buttonClick="signup" @playerSelected="selectPlayer"></player-selector>
			</div>
		</div>
		<conversion-modal :selectedPlayer="selectedPlayer" :showModal="showModal"></conversion-modal>


		<stripe-checkout
			ref="checkoutRef"
			:cancel-url="stripe.cancelURL"
			:clientReferenceId="stripe.clientReferenceId"
			:customerEmail="stripe.customerEmail"
			:line-items="stripe.lineItems"
			:pk="stripe.publishableKey"
			:success-url="stripe.successURL"
			mode="payment"
			@loading="v => stripe.loading = v"
		/>
	</div>
</template>

<script>

import ReplayHeader from './ReplayHeader';
import PlayerSelector from './PlayerSelector';
import ConversionModal from './ConversionModal';
import {_} from 'vue-underscore';


import {StripeCheckout} from '@vue-stripe/vue-stripe';
import api from "@/api";


export default {
	name: 'LandingPage',
	components: {
		ReplayHeader,
		PlayerSelector,
		ConversionModal,
		StripeCheckout
	},
	props: {},
	methods: {
		signup() {
			console.log("sign up");
			this.showModal = true;
		},
		continueToStripe(playerId) {
			console.log("continueToStripe ", playerId)

			// binding the user with the cart and passing data to the webhook
			this.stripe.customerEmail = this.$store.getters.user.email;
			this.stripe.clientReferenceId = this.$store.getters.user.sub;

			// Dynamise the success callback url and add security token based on current user
			// using the internal ID from auth0 truncated from the start for a valid url
			this.stripe.successURL = this.stripe.successURL + playerId + "/" + this.$store.getters.userSubId;
			this.stripe.cancelURL = this.stripe.cancelURL + playerId + "/canceled";

			console.log("Success url is now: " + this.stripe.successURL, this.customerEmail, this.clientReferenceId)
			this.$refs.checkoutRef.redirectToCheckout();
		},

		convertFromCallback(forcePlayerId) {

			console.log("convert per convertFromCallback .", forcePlayerId, this.$route.params.authsub)
			// if referrer is stripe and if he didng pay -> it means it failed
			if (this.$route.params.authsub !== "canceled") {

				this.continueToStripe(forcePlayerId)

			}  // else the user presses back (failCallback), do nothing
		},
		selectPlayer(currentPlayerIndex) {
			const currentPlayer = this.players[currentPlayerIndex || 0];
			this.selectedPlayer = currentPlayer;
		},
		getPlayerList(gameData) {
			// Get unique list of players and play count by player
			const playerData = _.countBy(
				_.flatten(gameData.markers.map(x => x.text.replace(/\s/g, "").split(","))).filter(x => x)
			);
			// Convert to object with id and numPlays
			const players = Object.keys(playerData).map(function (player, index) {
				return {
					index,
					id: player,
					numPlays: playerData[player],
				};
			});
			return players;
		},
	},
	data() {
		const params = this.$route.params;

		const teamId = params && params.team;
		const dateId = params && params.date;

		let gameData = api.fetchTeamGames(teamId.toLowerCase(), dateId)[0];

		let date = new Date(dateId.substr(0, 4) + "-" +
			dateId.substr(4, 2) + "-" +
			dateId.substr(6) + "T12:00:00");

		let opponentId = (gameData && gameData.opponentId);

		let gameKey = dateId + "-" + teamId + "-" + opponentId;
		if (teamId.localeCompare(opponentId) === 1) gameKey = dateId + "-" + opponentId + "-" + teamId;


		return {
			team: teamId || "No Game Found",
			date: date.toDateString(),
			subtitle: "Team " + teamId + " against team " + opponentId + ". Captured by MyReplay\n",
			thumbnail: (`${this.getCloudProviderBase(true)}/${gameKey}/${gameKey}.jpg`).toLowerCase(),
			selectedPlayer: {},
			showModal: false,
			players: [],
			stripe: {
				publishableKey: process.env.VUE_APP_STRIPE_KEY,
				loading: false,
				customerEmail: null,
				clientReferenceId: null,
				lineItems: [
					{
						price: process.env.VUE_APP_STRIPE_PRICE,
						quantity: 1,
					},
				],
				successURL: window.location.origin + "/#/convert/" + this.$route.params.team + "/" + this.$route.params.date + "/",
				cancelURL: window.location.origin + "/#/convert/" + this.$route.params.team + "/" + this.$route.params.date + "/"
			},
			gameData,
		};
	},

	mounted() {
		const that = this;
		this.$on('hideModal', () => {
			that.showModal = false;
		});

		const gameData = this.gameData;

		api.fetchMarkers(gameData.dateId, gameData.teamId, gameData.opponentId, function (data) {
			// Here we get the marker Data
			const newGameData = JSON.parse(JSON.stringify(that.gameData));
			newGameData.markers = data;

			that.gameData = newGameData;

			that.players = that.getPlayerList(that.gameData);
			that.selectedPlayer = that.$route.params.player ? (_.findWhere(that.players, {id: that.$route.params.player}) || {}) : {};

		});

		// When a successful callback from auth0 is made here, it means the user wants to pay stripe
		// Need to do this, because the vuejs-stripe plugin needs to be attached to a component, otherwise we would have
		//  directly redirected it from route guard
		// This is not idea setup, it duplicates the logic, any better way?

		// todo: this below code is not DRY
		// @fred what about in the router?

		// automatically convert
		if (this.$route.params.player && (this.$store.getters.user && this.$store.getters.user.meta.loginsCount === 1)) {
			this.convertFromCallback(this.$route.params.player);
		}

	},
}
</script>

<style src="../assets/reset.css">
</style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');

.conversion-page {
	background: #fff;
	height: 100%;
	width: 100%;
	display: flex
}

.conversion-page.modalOpen {
	/*overflow: hidden;*/
}

.conversion-container {
	background: #efeff1;
	height: 100%;
	position: relative;
	display: block;
	padding-top: calc(3% + 75px);
	width: 100%;
}

.conversion-container--content-wrapper {
	margin: auto;
	width: 90%;
	max-width: 600px;
	text-align: center;
	background: #fff;
	padding: 24px;
	border-radius: 24px;
	box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 50%);
}

.game-title {
	margin-top: 3vw;
	font-family: 'Russo One';
	font-style: italic;
	font-size: 36px;
	margin-bottom: 12px;
}

.game-subtitle {
	font-size: 16px;
	margin-bottom: 24px;
}

.game-thumbnail {
	width: 100%;
}

.skip-selection-sign-up {
	font-size: 12px;
	margin-top: 12px;
	cursor: pointer;
}

.skip-selection-sign-up > span {
	color: #3f51b5;
}

@media only screen and (max-width: 600px) {
	.game-title {
		font-size: 24px;
	}

	.game-subtitle {
		font-size: 14px;
	}
}
</style>