<template>
	<div class="gif-container" :class="{hidden: !showGif}">
		<img :class="{hidden: !showGif}" class="myreplay-gif" src="../assets/myreplayicon.png">
	</div>
</template>

<script>

export default {
	name: 'LoadingGif',
	props: {
        showGif: {
            type: Boolean,
            default() {
                return false;
            }
        }
	},
	data() {
		return {
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.gif-container {
    /* position: absolute;
    width: 100%;
    padding: 20vh 0;
    left: 0;
    opacity: 1;
    pointer-events: auto; */

    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(-50%);
}


.myreplay-gif {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    opacity: 1;
    animation: elastic-spin-pulse 2s infinite ease;
    animation-delay: 0.5s;
    transition: opacity 0.3s ease;
}

.hidden {
    opacity: 0;
    pointer-events: none;
}

@keyframes elastic-spin-pulse {
  0% { transform: rotate(0deg) scale(0.8); }
  5% { transform: rotate(0deg) scale(0.8); }
  45% { transform: rotate(720deg) scale(1.2); }
  55% { transform: rotate(720deg) scale(1.2); }
  95% { transform: rotate(1440deg) scale(0.8); }
  100% { transform: rotate(1440deg) scale(0.8); }
}

</style>
