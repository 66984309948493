<template>
	<div id="replay-app">
		<div class="main-container">
			<div class="content-container">
				<div class="left-container">

					<video-player :options="videoOptions"></video-player>
					<!-- <iframe
                        class="iframe1"
						:src="'https://player.twitch.tv/?parent=' + 'replay.camera' + '&video=' + footageId"
						height="70%"
						width="99%"
						allowfullscreen="false">
					</iframe>					 -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from '../api/server.js';
import {aws} from "/config.json";
import VideoPlayer from './VideoPlayer';

export default {
	name: 'App',
	components: {
		VideoPlayer
	},
	data() {

		return {
			date: null,
			curTime: 0,
			footageId: "",
			footageIdLast: "",
			img: "",
			lastFootageKey: "",
			lastFootageDate: ""
		}
	},
	methods: {
		capitalize(string) {
			if (!string) return "";
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		timeUpdate(curTime) {
			this.curTime = curTime;
		}
	},
	mounted() {	
		/*window.Intercom('update', {
			"hide_default_launcher": true
		});*/

        const that = this;
		const params = that.$route.params;
		//this.footageId = ""; Compilator: Uploading to S3

		const fetchAndUpdateGameDistrict5LastPreview = function() {
			console.log("dfdf")
		}
		fetchAndUpdateGameDistrict5LastPreview()

		const fetchAndUpdateGameDistrict5 = function() {
			
			console.log("fetchAndUpdateGameDistrict5 is called...");
			

			var dateNow = new Date()
			/*var dateKey = dateNow.toLocaleString("en-CA", { // you can use undefined as first argument
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
			}).replaceAll("-", "")*/
			
				/**
				 * difference first game versus game mode on. FULL LOGIC:
				 * 	if now == no preview AND now - 1h == no preview
				 * 	elseif now == preview
				 * 		if mode == PLAYBACK_FOR_LAST_PLAYERS
				 * 			// from 0->30 last game, and from 30-60-> current game
				 * 			
				 * 			preview = get preview now - 1h
				 * 			if none, play current, otherwise play last one
				 * 		if mode == SHOWCASE_FOR_NEXT_PLAYERS
				 * 			// from 0->60 current game
				 * 			play now
				 * 
				 * REDUCTED LOGIC
				 * 	if now -1h has preview
				 * 		if video empty
				 * 			play preview
				 *		else
				 *			check if new url OR more recent if yes reload
				 * 	elseif now - 0 has preview
				 * 		play preview
				 * 	else
				 * 		fetch last preview and play it
				 * 
				 * 			get past 30 days
				 * 			start with more recent, for each
				 * 			check if preview is there, if not, go to the next
				 * 			if yes return video link, otherwise, return joker video
				 * 
				 * now we have the video link, check if current video link is different
				 * 	if yes refresh 1 min after
				 * 
				 * 
				 */

			api.fetchLastPreviewNew(params.dome, params.id, (err, res) => {
				console.log("Fetched new games", err, res);
				if (err || !res) {
					console.log("ERROR FETCHING GAMES", err);
					console.log("Failover: Loading joker preview...")
					that.footageIdLast = "content/district5/preview.mp4"
					that.footageId = that.footageIdLast + "?t=" + dateNow.getMinutes() + "" + dateNow.getMilliseconds();
					that.img = require(`../assets/${params.dome}.png`);
					that.lastFootageDate = res.LastModified
					that.myReplaySlackHook("log-tv-stream", "TV_STREAM_" + params.dome + "_" + params.id, "Failover joker: " + that.footageIdLast) 
				} else if (res) {
					console.log(that.lastFootageDate, res.LastModified, that.lastFootageDate < res.LastModified)


					if (res && res.Key) {
						if (!that.footageId) {
							console.log("FIRST LOAD")
							that.footageIdLast = res.Key
							that.footageId = that.footageIdLast + "?t=" + dateNow.getMinutes() + "" + dateNow.getMilliseconds();
							that.img = require(`../assets/${params.dome}.png`);
							that.lastFootageDate = res.LastModified
							that.myReplaySlackHook("log-tv-stream", "TV_STREAM_" + params.dome + "_" + params.id, "loading: " + that.footageIdLast) 
						} else if (that.footageIdLast != res.Key) { // new video reload page wait 
							console.log("RELOADING! Waiting 2 min before reload...", that.footageIdLast, res.Key)
							that.myReplaySlackHook("log-tv-stream", "TV_STREAM_" + params.dome + "_" + params.id, "New video. reloading in 2 minutes... " + res.Key) 
							setTimeout(function() {
								window.location.reload(true);
							}, 60*1000*2); 
							
						} else if (that.lastFootageDate < res.LastModified)  { // case where same file but new data
							console.log("INVALIDATE CACHE HERE!!! Waiting 2 min before reload...")
							//that.$forceUpdate();
							that.myReplaySlackHook("log-tv-stream", "TV_STREAM_" + params.dome + "_" + params.id, "Invalidating cache. Reloading in 2 minutes... " + res.Key) 
							setTimeout(function() {
								window.location.reload(true);
							}, 60*1000*2); 
						} else {
							console.log("DO NOTHING")
						}
					} else {
						console.log("wtf", res)
					}
					
					
					//todo: change for this as its more dats efficient
					// api.fetchHead(lastPreview.Key, (err, res) => {
					// 	if (res && res.Metadata && res.Metadata.integrity > 0) {
					// 		that.integrity = res.Metadata.integrity;
					// 	}
					// });

				}
			});
		}

		const fetchAndUpdateGameUifLared = function() {
			console.log("fetchAndUpdateGameUifLared is called...");
			
			api.fetchGames(params.dome, (err, res) => {
				//console.log("Fetched new games", err, res);
				if (err) {
					console.log("ERROR FETCHING GAMES", err);
				} else if (res) {

					const allPreviews = res.filter(x => {
						// added /preview to exclude /user-preview.mp4
						if (x.Key.includes('/preview')) console.log(x.Key, `${params.dome}-${params.id}`);
						return x.Key.includes('/preview') && x.Key.includes(`${params.dome}-${params.id}`);
					});
					const lastPreview = allPreviews.slice()[0];
					console.log(allPreviews);
					that.footageId = lastPreview && lastPreview.Key;
					that.img = require(`../assets/${params.dome}.png`);

					that.myReplaySlackHook("log-tv-stream", "TV_STREAM_" + params.dome + "_" + params.id, "loaded: " + that.footageId) //data-setup
					//todo: change for this as its more dats efficient
					// api.fetchHead(lastPreview.Key, (err, res) => {
					// 	if (res && res.Metadata && res.Metadata.integrity > 0) {
					// 		that.integrity = res.Metadata.integrity;
					// 	}
					// });

				}
			});
		}

		// add the url of the video chosen on slack
		that.myReplaySlackHook("log-tv-stream", "TV_STREAM_" + params.dome + "_" + params.id, "PAGE_LOADED") 
		if (params.dome == "district5") {
			fetchAndUpdateGameDistrict5();
			setInterval(function() {
				fetchAndUpdateGameDistrict5();
			}, 60*1000*5); // Check every 60s * 3 (5 mins to align with scheduler processing time)
		} else {
			fetchAndUpdateGameUifLared();
			setInterval(function() {
				fetchAndUpdateGameUifLared();
			}, 60*1000*10); 
		}
		
	},
	computed: {
		videoOptions: function () {
			let src = "";
			let t = "https://replay-ott.s3.us-east-2.amazonaws.com/users-clips/1626753751.mp4"
			t = "https://replay-ott.s3.us-east-2.amazonaws.com/games-v2/district5/20221228/202212281800-district5-2/preview.mp4"
			t = "https://replay-ott.s3.us-east-2.amazonaws.com/games-v2/district5/20221224/202212241700-district5-3/preview.mp4"
			
			t = "https://replay-ott.s3.us-east-2.amazonaws.com/games-v2/district5/20221228/202212281800-district5-3/btn-2339/20221228182339-left.mp4"
			t = "https://replay-ott.s3.us-east-2.amazonaws.com/games-v2/district5/20221228/202212281800-district5-3/btn-2339/preview-d.mp4"

			const params = this.$route.params;
			if (params.dome == "district5" || params.dome == "soccer5") {
				if (this.footageId) src = `${aws.videoRootNoCache}/${this.footageId}`;	
			} else {
				if (this.footageId) src = `${aws.videoRoot}/${this.footageId}`;
			}

			//src = t

			console.log("SOURCE", src, params.dome, aws, t);
			return {
				autoplay: true,
				loop: true,
				controls: true,
				footagePreview: true,
				fluid: true,
				sources: [
					{
						src,
						type: "video/mp4"
					}
				]
			}
		},
	},
}
</script>

<style src="../assets/reset.css">

</style>

<style>
.vjs-fluid {
	max-width: calc(min(100vh/0.5625,100%)) !important;
    height: 100vh !important;
    margin: 0 auto;
    padding-top: 0 !important;
}
</style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');

body {
	background: #efeff1;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

#replay-app {
	height: 100%;
	background: #efeff1;
	position: relative;
    overflow: hidden;
}

.main-container {
	margin: 0 auto;
}

.content-container {
	margin: 0 auto;
}

.left-container {
	width: 100%;
	float: left;
	height: 100%;
	display: flex;
	flex-flow: column;
}

.iframe1, .iframe2 {
    position: absolute;
    margin: 0.5%;
}

.iframe1 {
    left: 0;
}

.iframe2 {
    right: 0;
}

.title-container {
    font-family: Russo One;
	width: calc(100% - 32px);
	flex: 1 1 auto;
	background: #fff;
	padding: 16px;
	margin: 16px 0px 0px 16px;
	/*border-radius: 8px;*/
	color: black;
	border-radius: 24px;
	box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 50%);
}

.title-text-container {
	display: inline-block;
	text-align: center;
	width: 100%;
    font-size: 26px;
}

.title, .web, .title-end {
    display: inline-block;
}

.web {
    color: #FF0054;
}

.lared-logo {
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
}

.partner {
    display: inline-block;
    vertical-align: middle;
    font-family: Nunito;
    font-size: 20px;
}

.subtitle {
    margin-top: 16px;
}

.right-container {
	width: calc(32% - 16px);
	float: right;
	display: inline-block;
	height: 100%;
	overflow: hidden;
	background: #fff;
	margin: 0 0 0 16px;
	border-radius: 24px;
	box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
	/*border-radius: 8px;*/
	position: relative;
}

@media only screen and (max-width: 980px) {
	.left-container {
		width: 100%;
		height: auto;
	}

	.right-container {
		width: 100%;
		max-height: 100%;
	}
}
</style>