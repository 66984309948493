<template>
	<div :class="{hidden: !showModal}" class="modal-overlay" @click="hideModal">
		<div class="modal-container">
			<div class="modal-content" @click.stop>
				<div class="modal-content--title">{{$t('conversionModal.title', {dome: gameData.dome})}}</div>
				<div class="modal-content--description">{{teamOnly ? $t('conversionModal.team_description') : $t('conversionModal.description')}}</div>
				<div class="modal-content--price">{{ free ? $t('conversionModal.free_trial') : finalPrice.price }}
					<div v-if="finalPrice.initialPrice" class="old-price">{{finalPrice.initialPrice}}</div>
					<div class="modal-content--price-description">{{toggleOption ?
													(subscriptionOption ? $t('conversionModal.subscription_price_description') : $t('conversionModal.team_price_description')) : 
													$t('conversionModal.price_description')}}</div>
				</div>
				<div v-if="!teamOnly" class="switch">
					<div class="price-option-name" @click="toggleOptionSelect(false)">
						<div>{{(subscriptionOption ? $t('conversionModal.game_option') : $t('conversionModal.player_option'))}}</div>
					</div>
					<div class="toggle-container">
						<input id="cmn-toggle-1" class="cmn-toggle cmn-toggle-round" type="checkbox" v-model="toggleOption">
						<label for="cmn-toggle-1"></label>
					</div>
					<div class="price-option-name" @click="toggleOptionSelect(true)">
						<div class="price-option--content">{{(subscriptionOption ? $t('conversionModal.subscription_option', {percent: this.savePercent}) : $t('conversionModal.team_option'))}}</div>
					</div>
				</div>
				<div class="modal-content--cta" @click="convert">{{free ? $t('conversionModal.free_cta') : $t('conversionModal.cta')}}</div>
				<div class="modal-content--alternate">{{teamOnly ? $t('conversionModal.team_alternate') : $t('conversionModal.alternate')}}</div>
				<form>
					<div class="material-group">      
						<input type="input" class="material-input" @input="validateCodeOnInput($event)">
						<div class="invalid-code" :class="{show: invalidCode}">x</div>
						<span class="material-highlight"></span>
						<span class="material-bar"></span>
						<label class="material-label">{{$t('conversionModal.material_label')}}</label>
					</div>
				</form>
			</div>
		</div>

		<stripe-checkout
			ref="checkoutRefFootage"
			:cancel-url="stripe.cancelURLPayment"
			:clientReferenceId="stripe.clientReferenceId"
			:customerEmail="stripe.customerEmail"
			:line-items="stripe.lineItems"
			:pk="stripe.publishableKey"
			:success-url="stripe.successURLPayment"
			:mode="stripe.mode"
			@loading="v => stripe.loading = v"
		/>
	</div>
</template>

<script>
//7AfBhqwj

import {slack, stripe, whitelistedEmails} from "/config.json";
import { mapGetters } from 'vuex';
import axios from "axios";
import api from '../api/server.js';
import {StripeCheckout} from "@vue-stripe/vue-stripe";


export default {
	name: 'ConversionModal',
	components: {
		StripeCheckout
	},
	props: {
		showModal: {
			type: Boolean,
			default() {
				return false;
			}
		},
		purchased: {
			type: Boolean,
			default() {
				return false;
			}
		}
	},
	data() {
		const gameData = this.getGameDataFromPath(this.$route.path);

		return {
			invalidCode: false,
			toggleOption: false,
			mode: process.env.VUE_APP_MODE,
			stripe: {
				publishableKey: process.env.VUE_APP_STRIPE_KEY,
				loading: false,
				customerEmail: null,
				clientReferenceId: null,
				lineItems: [
					{
						price: process.env.VUE_APP_MODE == "prod" ? stripe[gameData.domeKey].player.key : process.env.VUE_APP_STRIPE_PRICE,
						quantity: 1,
					},
				],
				successURLPayment: window.location.origin + "/#/footage/" + this.$route.params.footageId + "/",
				cancelURLPayment: window.location.origin + "/#/footage/" + this.$route.params.footageId + "/",
				mode: "payment",
			},
			gameData,
			subscriptionOption: !!stripe[gameData.domeKey].subscription
		}
	},
	computed: {
		discount() {
			return this.$route.query && this.$route.query["discount"] && (this.purchases.length === 0);
		},
		finalPrice() {
			// reacts to toggleOption change & purchases object through discount
			let price = "";
			let initialPrice = "";
			if (this.toggleOption) {
				if (process.env.VUE_APP_MODE == "prod") {
					price = this.subscriptionOption ? stripe[this.gameData.domeKey].subscription.price : stripe[this.gameData.domeKey].team.price;

					if (this.gameData.domeKey === "urbanindoorfutbol") {
						initialPrice = 5.99
					} else if (this.gameData.domeKey === "district5") {
						initialPrice = 4.99
					}

				} else {
					price = stripe.default.price;
				}
			} else {
				if (process.env.VUE_APP_MODE == "prod") {
					if (this.discount) {
						price = stripe[this.gameData.domeKey].discount.price;
						initialPrice = stripe[this.gameData.domeKey].player.price; 
					} else {
						price = stripe[this.gameData.domeKey].player.price;
					}
				} else {
					if (this.discount) {
						price = stripe.defaultDiscount.price;
						initialPrice = stripe.default.price;
					} else {
						price = stripe.default.price;
					}
				} 
			}
			return {price: "$" + price, initialPrice};
		},
		savePercent() {
			return Math.round((this.subscriptionOption ? 1 - stripe[this.gameData.domeKey].subscription.price/(stripe[this.gameData.domeKey].player.price * 4.33) : 0) * 100);
		},
		free() {
			return stripe[this.gameData.domeKey].free || whitelistedEmails.includes(this.user.email);
		},
		teamOnly() {
			return stripe[this.gameData.domeKey].teamOnly;
		},
		...mapGetters([
			'user',
			'userSubId',
			'userMetadata',
			'username',
			'isLoggedIn',
			'purchases'
		]),
	},
	methods: {
		hideModal() {
			this.$parent.$emit('hideModal');
		},
		convert() {
			if (this.free) {
				this.convertForFree();	
			} else {
				this.convertFromClick();
				this.$parent.$emit('hideModal');
			}
		},

		validateCodeOnInput(event) {
			const code = event.target.value;
			
			if (this.validateCode(code)) {
				//alert(code + " is valid! redirecting...")
				this.convertFromCoupon(code);
				this.invalidCode = false;
			} else if (code.length >= 6) {
				this.invalidCode = true;
			} else {
				this.invalidCode = false;
			} 
		},
		toggleOptionSelect(flag) {
			this.toggleOption = flag;
		},
		convertForFree() {
			if (!this.purchased) {
				let user = this.user;
				const date = this.gameData.paymentDateString;
				api.persistPayment(user, date, "free", "free", this.gameData.domeKey);
				this.$parent.$emit('showSuccessModal');
				this.hideModal();
			}
		},
		convertFromCoupon(code) {
			// assume its already validated
			if (!this.purchased) {
				let user = this.user;
				const date = this.gameData.paymentDateString;
				let userinfo = '\n- Username: *' + this.username + '*' + "\n- Email: *" + user.email + "*\n- Phone: *" + this.userMetadata["form_phone"] + "*";
				let slackEmojies = ":moneybag:";
				for (let step = 0; step < this.purchases.length; step++) {
					slackEmojies += ":moneybag:"
				}
				api.persistPayment(user, date, code, "coupon", this.gameData.domeKey);
				axios.get(slack.slackUrl + '&type=laredkocha&event=' + "PURCHASE_COUPON" + '&text=' + encodeURIComponent(slackEmojies + ' coupon *' + code + '* for date *' + date + "*" + userinfo));
				this.$parent.$emit('showSuccessModal');
				this.hideModal();
			}
		},
		convertFromClick() {
			this.stripe.customerEmail = this.user.email;
			this.stripe.clientReferenceId = this.user.sub;

			this.stripe.successURLPayment = this.stripe.successURLPayment + this.userSubId + "/" + this.stripe.lineItems[0].price;
			this.stripe.cancelURLPayment = this.stripe.cancelURLPayment + "canceled";

			if (this.discount && !this.toggleOption) {
				if (this.mode === "prod") {
					this.stripe.lineItems[0].price = stripe[this.gameData.domeKey].discount.key;
				} else {
					this.stripe.lineItems[0].price = stripe.defaultDiscount.key;
				}
			}

			const that = this;
			if (this.stripe.customerEmail) {
				this.$refs.checkoutRefFootage.redirectToCheckout();
			} else {
				// This is bad practice, it should really be done in a watcher or something that uses the store's reactivity 
				setTimeout(function() {
					that.$refs.checkoutRefFootage.redirectToCheckout();
				}, 500)
			}
		},
		getPaymentDate(datePart) {
			// Input date in YYYYMMDDHH format
			const date = new Date(`${datePart.substr(0, 4)}-${datePart.substr(4, 2)}-${datePart.substr(6, 2)}T${datePart.substr(8, 2)}:00`)
			const paymentDate = (new Date(date.getTime() - 4 * 60 * 60000 - (date.getTimezoneOffset() * 60000 )))
                    .toISOString()
                    .split("T")[0];
			return paymentDate;
		},
		generateCode(path) {
			// Important Note: this function has to match the function on the server
			// or the owners will be texted the wrong code
			const pathSplit = path.split("/");
			const codeSplit = pathSplit[pathSplit.length - 1].split("-");
			const datePart = codeSplit[0].substr(0, 10);
			const paymentDate = this.getPaymentDate(datePart);
			const uniqueGameDayCode = paymentDate + "-" + codeSplit[1];

			const hashCode = uniqueGameDayCode.split('').reduce((prevHash, currVal) =>
				(((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);

			return hashCode.toString(36).replace("-", "").toUpperCase();
		},
		validateCode(inputCode) {
			function roryCipher(s, k) {
				var n = 26; // alphabet letters amount
				if (k < 0) {
					return roryCipher(s, k + n);
				}
				return s.split('')
					.map(function (c) {
						if (c.match(/[a-z]/i)) {
							var code = c.charCodeAt();
							var shift = code >= 65 && code <= 90 ? 65 : code >= 97 && code <= 122 ? 97 : 0;
							return String.fromCharCode(((code - shift + k) % n) + shift);
						}
						return c;
					}).join('');
			}
			
			for (var i = 1; i <= 26; i++) {
				const validCode = roryCipher(this.generateCode(this.$route.path), i);
				if (validCode.toLowerCase() === inputCode.toLowerCase()) {
					return true;
				}
			}

			if (inputCode && inputCode.length === 6) {
				api.checkCodeUsage(inputCode.toUpperCase(), (err, res) => {
					if (err) {
						console.log("Error checking code usage", err);
					} else if (res) {
						this.convertFromCoupon(res);
						this.invalidCode = false;
						api.markCodeAsUsed(res, () => {});
					}
				});
			}

			return false;
		},
	},
	mounted() {
		let user = this.user;
		// can't use this.$route.path because it brings all the other parameters too
		let path = "/" + this.$route.name + "/" + this.$route.params.footageId;

		const that = this;

		let type = "stripe";

		// Manual starting with team
		if (this.teamOnly) {
			this.toggleOptionSelect(true);
		}

		// Game code from link sharing
		const query = this.$route.query;
		const gameCode = query && query.gameCode;
		if (gameCode && this.validateCode(gameCode)) {
			this.convertFromCoupon(gameCode);
			window.history.replaceState({}, document.title, "/#" + path);
		}
		
		// check for stripe callback if the payment was successful (user logged in, same subid)
		if (this.$route.params.state && this.isLoggedIn) {

			console.log(this.$route.params.state)
			// comes from stripe

			const date = this.gameData.paymentDateString;
			let userinfo = '\n- Username: *' + this.username + '*' + "\n- Email: *" + user.email + "*\n- Phone: *" + this.userMetadata["form_phone"] + "*";
			userinfo += "\n\n- Arena: *" + this.userMetadata["segment"] + "*"
			userinfo += "\n- Game Link: https://myreplay.co/#/footage/" + this.$route.params.footageId

			let slackEmojies = ":moneybag:";
			for (let step = 0; step < this.purchases.length; step++) {
				slackEmojies += ":moneybag:"
			}

			if (this.userSubId === this.$route.params.state) {
				// Right user 
				let plan = "";
				if (!this.$route.params.plan) {
					axios.get(slack.slackUrl + '&type=laredkocha&event=' + "ERROR" + '&text=' + encodeURIComponent("User came back without stripe plan, going with default"));	
					plan = this.stripe.lineItems[0].price;
				} else {
					plan = this.$route.params.plan;

					console.log(plan, stripe[this.gameData.domeKey].team.key);

					if (plan == stripe[this.gameData.domeKey].team.key) {
						this.$nextTick(() => {
							that.$parent.$emit("showCode", that.generateCode("/#" + path));
						});
					}
					
					if (stripe[this.gameData.domeKey].subscription && plan == stripe[this.gameData.domeKey].subscription.key) {
						// subscription for dome exists and this is it
						type = "sub";
					}
				}
				api.persistPayment(user, date, plan, type, this.gameData.domeKey);
				this.showModalSuccess = true;
				if (type === "sub") {
					axios.get(slack.slackUrl + '&type=laredkocha&event=' + "PURCHASE_SUBSCRIPTION" + '&text=' + encodeURIComponent(slackEmojies + ' for user: ' + userinfo));
				} else {
					axios.get(slack.slackUrl + '&type=laredkocha&event=' + "PURCHASE_STRIPE" + '&text=' + encodeURIComponent(slackEmojies + ' for date *' + date + "*" + userinfo));
				}
				
			} else {
				console.log(this.userSubId, this.$route.params.sate, this.$route.params.plan, stripe[this.gameData.domeKey].team.key);
			}

			// redirect or remove parameters . should probly use a vue function
			window.history.replaceState({}, document.title, "/#" + path);

		}
	},
	watch: {
        toggleOption: function(val) {
            if (val) {
				// true means switch to team payment option
				if (this.subscriptionOption) {
					// Toggle option is either subscription price or team price
					this.stripe.lineItems[0].price = stripe[this.gameData.domeKey].subscription.key;
					this.stripe.mode = "subscription";
				} else {
					// team price
					this.stripe.lineItems[0].price = stripe[this.gameData.domeKey].team.key;
					this.stripe.mode = "payment";
				}
			} else {
				this.stripe.mode = "payment";
				if (this.discount) {
					this.stripe.lineItems[0].price = stripe[this.gameData.domeKey].discount.key;	
				} else {
					this.stripe.lineItems[0].price = stripe[this.gameData.domeKey].player.key;
				}
			}
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	z-index: 1001;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.56);
	transition: opacity 0.3s;
}

.modal-container {
	position: absolute;
	height: auto;
	width: 90%;
	max-width: 600px;
	background: #fff;
	border-radius: 24px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}

.modal-content {
	padding: 24px 12px;
	text-align: center;
	height: 100%;
	position: relative;
}

.modal-content--title {
	text-align: center;
	font-size: 32px;
	font-family: 'Nunito';
}

.modal-content--description {
	text-align: center;
	font-size: 22px;
	font-family: 'Nunito';
	margin-top: 24px;
	padding: 0 24px;
	font-weight: 700;
}

.modal-content--price {
	position: relative;
	text-align: center;
	font-size: 32px;
	font-family: 'Nunito';
	font-weight: 900;
	margin-top: 24px;
}

.modal-content--price-description {
    font-size: 16px;
    max-width: 132px;
    margin: 0 auto;
	font-weight: 400;
}

.price-option-name {
	display: inline-block;
    line-height: 32px;
    vertical-align: top;
	margin: 0px 3%;
    font-family: 'Nunito';
    width: calc((88% - 60px)/2);
    font-size: 14px;
    font-weight: 600;
	cursor: pointer;
}

.price-option-name:first-child {
	text-align: right;
}

.price-option-name:last-child {
	text-align: left;
	line-height: 18px;
}

.price-option--content {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.switch {
	margin: 24px 0;
	height: 32px;
    position: relative;
}

.toggle-container {
	width: 60px;
	display: inline-block;
}

.cmn-toggle {
  position: absolute;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

input.cmn-toggle-round + label {
	padding: 2px;
    width: 60px;
	height: 32px;
	background: #dddddd;
	border-radius: 60px;
	display: inline-block;
}
input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 60px;
  transition: background 0.4s;
}
input.cmn-toggle-round + label:after {
  width: 30px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: margin 0.4s;
}
input.cmn-toggle-round:checked + label:before {
	background:  #610888;
}
input.cmn-toggle-round:checked + label:after {
    margin-left: 28px;
}


.old-price {
	position: absolute;
	left: calc(50% + 80px);
	transform: translateX(-50%);
	top: 0;
	text-decoration: line-through;
	text-decoration-color: red;
	color: #000;
	text-decoration-thickness: 2px;
	font-size: 22px;
}

.modal-content--cta {
	margin-top: 16px;
	cursor: pointer;
	font-family: 'Nunito';
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 32px;
	padding: 12px;
	font-size: 20px;
	border-style: none;
	text-align: center;
	white-space: nowrap;
	width: 280px;
	margin: 28px auto 0;
}

.modal-content--alternate {
	margin-top: 42px;
	font-family: Nunito;
	font-size: 18px;
	font-weight: 600;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}

.material-group { 
    position: relative;
    font-family: Nunito;
    width: 300px;
	max-width: 90%;
    margin: 32px auto 12px;
}
.material-input {
  font-size:18px;
  padding:10px 10px 10px 5px;
  width:300px;
  max-width: 100%;
  border:none;
  border-bottom:1px solid #757575;
}

.invalid-code {
	font-family: 'Nunito';
    background: red;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    color: white;
    line-height: 22px;
    font-weight: 700;
    position: absolute;
    right: 0;
    bottom: 12px;
	display: none;
}

.invalid-code.show {
	display: initial;
}

.material-input:focus {
	outline: none;
}

/* LABEL ======================================= */
.material-label {
  color:#999; 
  font-size:18px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
.material-input:focus ~ .material-label {
  top:-20px;
  font-size:14px;
  color:#FF0054;
}

/* BOTTOM BARS ================================= */
.material-bar 	{ 
	position:relative;
	display:block;
	width:300px;
	max-width: 100%;	
}
.material-bar:before, .material-bar:after 	{
  content:'';
  height:2px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background:#FF0054; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.material-bar:before {
  left:50%;
}
.material-bar:after {
  right:50%; 
}

/* active state */
.material-input:focus ~ .material-bar:before, .material-input:focus ~ .material-bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.material-highlight {
  position:absolute;
  height:60%; 
  width:100px; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
.material-input:focus ~ .material-highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:#FF0054; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#FF0054; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#FF0054; }
  to 	{ width:0; background:transparent; }
}

@media only screen and (max-width: 480px) {
	.modal-content--title {
		font-size: 16px;
	}
	.modal-content--description {
		font-size: 22px;
		padding: 0px 0px;
	}
	.modal-content--cta {
		max-width: 90%;
	}
}

</style>
