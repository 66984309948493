var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contest-container"},[_c('div',{staticClass:"img-background"}),_c('div',{staticClass:"contest-background"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/logo.png")}}),_vm._m(0),_c('div',{staticClass:"video-container"},[_c('div',{staticClass:"total-entry"},[_vm._v(_vm._s(_vm.totalSubmissions))]),_c('div',{staticClass:"total-entry--description"},[_vm._v("Total Entries")]),_c('div',{staticClass:"winning-video-description"},[_vm._v("Current Top Plays in Florida")]),_vm._l((_vm.top10),function(t,index){return _c('div',{key:t.instagram + index},[_c('div',{staticClass:"entry-divider"}),_c('a',{attrs:{"target":"_blank","href":("https://www.instagram.com/" + (t.instagram) + "/")}},[_c('div',{staticClass:"winning-entry-profile"},[_c('span',{staticClass:"play-number"},[_vm._v("Play #"+_vm._s(index + 1))]),_c('img',{staticClass:"insta-img",attrs:{"src":t.profilePic}}),_vm._v(" By "),_c('span',{staticClass:"insta-link"},[_vm._v("@"+_vm._s(t.instagram))])])]),_c('video-player',{attrs:{"no-border":true,"options":{
                        autoplay: (index == 0),
                        muted: (index == 0),
                        controls: true,
                        profile: true,
                        sources: [
                            {
                                src: t.sourceURL,
                                type: 'video/mp4'
                            }
                        ]
                    }}}),_c('div',{staticClass:"vote-container"},[_c('div',{staticClass:"vote-button-container"},[_c('div',{staticClass:"vote-button",class:{'checked': t.upvoted},on:{"click":function($event){return _vm.upvote(t.sourceURL)}}})]),_c('div',{staticClass:"num-upvotes"},[_vm._v(_vm._s(t.numVotes)+" "),_c('span',{staticClass:"upvotes-desc"},[_vm._v("Upvotes")])])])],1)})],2),_c('div',{staticClass:"CTA-container"},[_c('div',{staticClass:"CTA-prompt"},[_vm._v("Can you beat them?")]),_c('button',{staticClass:"CTA-button",attrs:{"id":"browse"}},[_vm._v("Upload Your Submission")]),_c('div',{staticClass:"CTA-success",class:{'hidden': !_vm.uploadSuccess}},[_vm._v("Your play was successfully uploaded! Complete your entry below to enter the contest.")])]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contest-header--title"},[_c('div',{staticClass:"contest-title"},[_vm._v("Nicest Play Wins")]),_c('div',{staticClass:"contest-deadline"},[_vm._v("Contest Ends Thu, Jan 20, 5:00PM")]),_c('img',{staticClass:"contest-prize-image",attrs:{"src":require("../assets/contest_prize.jpg")}}),_c('div',{staticClass:"contest-prize-description"},[_vm._v("Nike Mercurial Superfly 8 Elite CR7 FG Soccer Cleats - $250 Value")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hubspot-form-container"},[_c('div',{attrs:{"id":"hubspot-form"}})])}]

export { render, staticRenderFns }