<template>
	<div :class="{hidden: !showModal}" class="modal-overlay" @click="hideModal">
		<div class="modal-container">
			<div class="modal-content" @click.stop>
				<div class="modal-content--title">{{$t('successModal.title')}}</div>
				<div v-if="!showCode" class="modal-content--description">{{$t('successModal.description')}}</div>
                <img v-if="!showCode" class="modal-img" src="../assets/checkmark.png">
				<div v-if="showCode">
					<div class="code-share-title">{{$t('successModal.share_code')}}</div>
					<div class="code">{{showCode}}</div>
					<div class="copy-wrapper" @click="copyCode"><i class="fas fa-copy" ></i></div>
					<div class="code-cta" @click="copyCode">{{$t('successModal.copy_code')}}</div>
				</div>
			</div>
			<input type="hidden" id="testing-code" :value="showCode">
		</div>
	</div>
</template>

<script>

export default {
	name: 'SuccessModal',
	props: {
		showModal: {
			type: Boolean,
			default() {
				return false;
			}
		},
		showCode: {
			default() {
				return null;
			}
		}
	},
	data() {
		return {
		}
	},
    watch: {
        showModal: function(newVal) {
            if (newVal == true) {
                const that = this;
				setTimeout(function() {
					if (!that.showCode) {
						that.hideModal();
					}
				}, 4000);
            }
        }
    },
	methods: {
		hideModal() {
			this.$parent.$emit('hideSuccessModal');
		},
		copyCode() {
			let testingCodeToCopy = document.querySelector('#testing-code')
			testingCodeToCopy.setAttribute('type', 'text')
			testingCodeToCopy.select()

			try {
				var successful = document.execCommand('copy');
				var msg = successful ? 'successfully' : 'unsuccessfully. Please try again or copy the code manually!';
				alert('Code was copied ' + msg);
			} catch (err) {
				console.log(err);
			}

			/* unselect the range */
			testingCodeToCopy.setAttribute('type', 'hidden')
			window.getSelection().removeAllRanges()

			this.hideModal();
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	z-index: 1001;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.56);
	transition: opacity 0.3s;
}

.modal-container {
	position: absolute;
	height: auto;
	width: 90%;
	max-width: 600px;
	background: #fff;
	border-radius: 24px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}

.modal-content {
	padding: 24px 12px;
	text-align: center;
	height: 100%;
	position: relative;
}

.modal-content--title {
	text-align: center;
	font-size: 20px;
	font-family: 'Russo One';
}

.modal-content--description {
	text-align: center;
	font-size: 22px;
	font-family: 'Nunito';
	margin-top: 42px;
	padding: 0 24px;
}

.modal-content--cta {
	margin-top: 16px;
	cursor: pointer;
	font-family: 'Nunito';
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 32px;
	padding: 12px;
	font-size: 20px;
	border-style: none;
	text-align: center;
	white-space: nowrap;
	width: 280px;
	margin: 28px auto 0;
}

.modal-img {
    margin-top: 36px;
    width: 100px;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}

.code-share-title {
	font-family: 'Nunito';
    font-size: 24px;
    margin-top: 48px;
}

.code {
	font-size: 42px;
    margin: 28px 12px;
    font-weight: 600;
    display: inline-block;
}

.copy-wrapper {
	padding: 8px;
    background: linear-gradient(45deg, #FF0054, #610888);
    border-radius: 4px;
    width: fit-content;
    display: inline-block;
    vertical-align: text-bottom;
    cursor: pointer;
}

.fa-copy {
	color: #fff;
}

.code-cta {
	margin-top: 16px;
	cursor: pointer;
	font-family: 'Nunito';
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 32px;
	padding: 12px;
	font-size: 20px;
	border-style: none;
	text-align: center;
	white-space: nowrap;
	width: 280px;
	margin: 28px auto 0;
}

</style>
