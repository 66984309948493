<template>
    <div>
        <transition-group name="list" tag="ul"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:leave="leave"
        >
            <div class="game-preview" v-for="(game, i) in games" :key="game.date+game.dome+game.time+game.field" :data-index="i" >
                <router-link :to="{name: (game.preview || !isLoggedIn || game.userPreview) ? 'preview' :'footage', params : { footageId: game.link}}">
                    <span @click="logSlack(game.link)">
                        <game-search-element :game="game" :index="indexKey(game)" :gifOn="gifIndex == indexKey(game)" :imgIndex="imgIndex" @changeGifIndex="changeGifIndex"></game-search-element>
                    </span>
                </router-link>
            </div>
        </transition-group>
    </div>
</template>

<script>
import GameSearchElement from './GameSearchElement';
import Velocity from 'velocity-animate'
import {app} from "/config.json";
import { mapGetters } from 'vuex';

export default {
	name: 'GameSearchList',
    components: {
        GameSearchElement
    },
    props: {
        games: {
			type: Array,
			default: function () {
				return [];
			},
		}
    },
    mounted() {
        const that = this;

        this.interval = setInterval(function() {
            that.imgIndex = ((that.imgIndex || 0) + 1) % app.gifMaxIndex;
        }, 500)
    },
    data() {
        return {
            gifIndex: -1,
            imgIndex: 0,
            interval: null,
        //    username: this.user.username
        };
    },
    methods: {
        logSlack(link) {
			this.myReplaySlackHook(
				"log-users",
				"GAME_CLICK | User: " + this.username,
				"\n\t" + link,
                true
			);
        },
        beforeEnter: function (el) {
            el.style.opacity = 0
            el.style.height = 100
        },
        enter: function (el, done) {
            // Mod 10 in these transitions for loading the next paginated batch right away
            var delay = (el.dataset.index % 10) * 200 + 300
            setTimeout(function () {
                Velocity(
                    el,
                    { opacity: 1 },
                    { complete: done }
                )
            }, delay)
        },
        leave: function (el, done) {
            // Mod 10 in these transitions for loading the next paginated batch right away
            Velocity(
                el,
                { opacity: 0 },
                { complete: done }
            )
        },
        changeGifIndex(index) {
            this.gifIndex = index;
        },
        indexKey(game) {
            return game.date+game.dome+game.time+game.field;
        }
    },
    watch: {
        games(newVal, oldVal) {
            // Pre-load images for better animation
            const image = new Image();
            for (var i = oldVal.length; i < newVal.length; i++) {
                image.src = newVal[i].thumbnail;
            }

            return true;
        }
    },
    destroyed() {
        clearInterval(this.interval);
    },
    computed: {
        ...mapGetters([
			'isLoggedIn',
            "username"
		]),
    }
}
</script>

<style scoped>
.game-preview {
    position: relative;
    max-width: 500px;
    width: 100%;
    background: #fafafa;
    border-radius: 12px;
    margin: 12px 0;
    padding: 4px;
    cursor: pointer;
    box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
}


</style>