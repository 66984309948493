<template>
    <div class="dropdown">              
        <div class="dropdown--title" :class="{'dropdown--adjust': gameSearch}">
            {{title}} <span style="color: #9e9e9e"><i class="fas fa-chevron-circle-down"></i></span>
        </div>
        <div class="dropdown--option-wrapper" :class="{fitContent: fitContent}">
            <div class="dropdown--option" v-for="option in list" :key="option.key" @click="emit(option)">
                <div class="dropdown--name">{{option.name}}</div>
                <div v-if="option.img" class="dropdown--image-wrapper">
                    <img class="dropdown--image" :src="option.img">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Dropdown',
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        title: {
            type: String,
            default() {
                return this.$i18n.messages[this.$i18n.locale].search.dome; // Is this dumb to get the default?
            }
        },
        fitContent: {
            type: Boolean,
            default() {
                return false;
            }
        },
        gameSearch: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
	components: {

	},
	mounted() {
	},
	data() {

		return {

		}
	},
	computed: {

	},
	methods: {
        emit(option) {
            this.$emit('select', option);
        }
	}
}
</script>

<style scoped>

.dropdown {
    font-family: 'Nunito';
    text-align: center;
    border: none;
    cursor: pointer;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    margin: 4px 6px 0px;
}

.dropdown--title {
    border-radius: 24px;
    padding: 9px 12px;
    font-weight: 600;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
    min-width: 150px;
    user-select: none;
}

.dropdown--adjust {
    min-width: 140px !important;
}

.dropdown--option-wrapper {
    position: absolute;
    display: block;
    opacity: 0;
    pointer-events: none;
    z-index: 12;
    padding-top: 6px;
    min-width: 200px;
    border-radius: 16px;
    line-height: 40px;
    transition: opacity 0.2s ease-in-out;
    left: 50%;
    transform: translateX(-50%);
}

.dropdown--option {
    text-align: left;
    padding-left: 12px;
    background: #fff;
    user-select: none;
    min-width: 150px;
}

.dropdown--option:last-child {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.dropdown--option:first-child {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.dropdown--option:hover {
    background: #eee;
}

.dropdown:hover .dropdown--option-wrapper {
    opacity: 1;
    pointer-events: auto;
}

.dropdown--image-wrapper {
    width: 38px;
    height: 38px;
    display: inline-block;
    float: right;
}

.dropdown--image {
    margin: 0 auto;
    display: block;
    padding: 4px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.dropdown--name {
    display: inline-block;
    font-size: 16px;
    vertical-align: top;
    font-weight: 600;
    user-select: none;
}

.fitContent {
    width: fit-content;
    
}

.fitContent > .dropdown--option {
    padding-right: 12px;
}

@media only screen and (max-width: 639px) {
    .dropdown--title, .dropdown--option {
        min-width: 102px;
    }
}

</style>