<template>
	<div id="tablet-container">
        <div class="content-container">
            <div class="content-title">
                {{$t('tablet.title')}}
            </div>
            <img :src="selectedDome && selectedDome.img" class="dome-logo">
            <div class="CTA-title">
                {{$t('tablet.cta')}}
            </div>
            <form class="input-wrapper" @submit="submitNum($event)">
                <input class="input" v-model="num">
				<div class="submit-button" @click="submitNum($event)">{{$t('tablet.submit')}}</div>
            </form>
			<img src="./../assets/logo.png" class="replay-logo">
        </div>
	</div>
</template>

<script>
import {slack} from "/config.json";
import axios from "axios";

export default {
	name: 'Tablet',
	components: {
	},
	data() {
		return {
            selectedDome: null,
			num: "",
		}
	},
	computed: {
		
	},
	watch: {
        
	},
	methods: {
		submitNum(event) {
			axios.get(slack.slackUrl + '&type=laredkocha&event=' + "SUBMIT_NUMER" + '&text=' + encodeURIComponent('User submitted number: *' + this.num + "* for arena: " + this.selectedDome.key));
			event.preventDefault();

			let message = "";
			if (this.$i18n.locale === "fr") {
				message = `Succès! Tu receveras un SMS au ${this.num} dès que la partie sera disponible (généralement dans l'heure qui suit)`;
			} else if (this.$i18n.locale === "es") {
				message = `Success! We will send a text to ${this.num} as soon as your game is available.`;
			} else {
				message = `Success! We will send a text to ${this.num} as soon as your game is available.`;
			}

			this.$swal(message);

			this.num = "";
		}
	},
	mounted() {
        const domeKey = this.$route.params && this.$route.params.arena;
        this.selectedDome = this.findDomeByKey(domeKey);
	}
}
</script>

<style src="../assets/reset.css">

</style>


<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Russo+One&display=swap');

body {
	background: #efeff1 !important;
	height: 100%;
}

#tablet-container {
	background: linear-gradient(45deg, #e52057, #762998);
	position: absolute;
	overflow: hidden;
	min-height: 100vh;
    z-index: 15;
    width: 100%;
    top: 0;
    opacity: 1;
    pointer-events: auto;
    font-family: Nunito;
}

.content-container {
	width: calc(100% - 2*48px);
    height: calc(100vh - 2*48px);
    background: #fafafa;
    overflow: auto;
    margin: 48px;
    border-radius: 24px;
    padding: 24px;
	text-align: center;
	position: relative;
}


.content-title {
	font-size: 32px;
	margin-top: 24px;
}

.dome-logo {
	margin: 0 auto;
    width: 22%;
    margin-top: 22px;
}

.CTA-title {
	font-size: 2em;
    margin-top: 24px;
}

.input-wrapper {
	margin-top: 24px;
}

.input {
	border-radius: 8px;
    font-size: 22px;
    font-family: 'Nunito';
    text-align: center;
}

.submit-button {
	margin-top: 16px;
	cursor: pointer;
	font-family: 'Nunito';
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 32px;
	padding: 12px;
	font-size: 20px;
	border-style: none;
	text-align: center;
	white-space: nowrap;
	width: 280px;
	margin: 28px auto 0;
}


.replay-logo {
    width: 80%;
	max-width: 300px;
    position: absolute;
	bottom: 24px;
	transform: translateX(-50%);
}
</style>