import markers from "../data/markers.json";
//import gameMarkers from "../data/markers-games.json"

import Vue from "vue/dist/vue.js";
import axios from "axios";
import { app, aws, twitch, airtable } from "/config.json";
import AWS from "aws-sdk";
import store from "@/store/store";
import { Utils } from "../helpers/Utils.js";
import dayjs from "dayjs";
//import { base } from "airtable";
//Vue.use(Utils);


export default {
	async fetchUser(key, value, cb) { // possible usernames: md5(email), username | auth0 identity

		AWS.config.update({
			region: "us-east-2",
			accessKeyId: aws.lambda.accessKey,
			secretAccessKey: aws.lambda.secretKey
		});

		let  lambda = new AWS.Lambda();

		let data = { "username": value, "action": "read" };
		let params = {
			FunctionName: "auth0-public-user-crud",
			Payload: JSON.stringify(data),
		};

		lambda.invoke(params, function(err, res) {

			if (err) {
				console.error(err, err.stack);
				cb && cb(err, null);
			} else {
				try {

					let data = JSON.parse(JSON.parse(res.Payload).body)
					cb && cb(err, data);
				} catch (erre) {
					cb && cb(erre, null)
				}

			}
		});

	},

	async updateUserMetadata(userId, metadata, hubspotId, cb) { // possible usernames: md5(email), username | auth0 identity

		console.log("update USERRRRRR", userId, metadata)

		AWS.config.update({
			region: "us-east-2",
			accessKeyId: aws.lambda.accessKey,
			secretAccessKey: aws.lambda.secretKey
		});

		let lambda = new AWS.Lambda();

		let data = { "userId": userId, "action": "update", hubspotId, "metadata": {"user_metadata" : metadata} };
		let params = {
			FunctionName: "auth0-public-user-crud",
			Payload: JSON.stringify(data),
		};

		lambda.invoke(params, function(err, res) {
			console.log("YOOO 2 request returns... ", err, res);

			if (err) {
				console.error(err, err.stack);
				cb(err, null);
			} else {
				try {
					let data = JSON.parse(JSON.parse(res.Payload).body)

					cb(err, data);

				} catch (erre) {
					console.error("errrror lambda!")
					cb(erre, null)
				}

			}
		});
	},

	buildMarkersFromClips (clips) {

		let id = 0;
		
		let markers = clips.map(x => {
			return {
				"id": ++id,
				"time": x.TimeMarker || 0,
				"text": x.Views.toString(),
				"overlayText": x.Username
			}
		})
		console.log("res" , markers)
		return markers
	},

	
	async fetchGameMarkers(key) {
		const that = this;

		this.getVideoAssets({type: "clip", gameKey: key}, function(err, clips) {
			console.log("MARKERRS" , err, clips)
			
			//let game = gameMarkers.filter(game => game.gamekey === key)
			
			if (clips && clips.length > 0) {
				return (that.buildMarkersFromClips(clips));
			} else {
				return ([])
			}	
		});		
	},

	fetchTeamGames(team, date) {
		console.log(team, date);

		return markers.filter(function (game) {

			console.log("Game data", game.teamId.toLowerCase(), game.dateId)

			if (team && date) {
				return (game.teamId.toLowerCase() === team && game.dateId === date);
			} else if (team) {
				return (game.teamId.toLowerCase() === team);
			} else {
				// allow returning all games
				return true;
			}

		});

	},

	async fetchMarkers(date, team1, team2, cb) {

		// fetch the json
		team1 = team1.toLowerCase();
		team2 = team2.toLowerCase();
		let team1_alpha, team2_alpha; // Only for the game key do we need alphabetical

		if (team1.localeCompare(team2) > 0) {
			team1_alpha = `${team2}`;
			team2_alpha = `${team1}`;
		} else {
			team1_alpha = `${team1}`;
			team2_alpha = `${team2}`;
		}

		let url = date + "-" + team1_alpha + "-" + team2_alpha + "/" + team1 + "/" + date + "-" + team1 + ".json";
		let response = await axios.get(aws.videoRoot + "/" + url);

		cb(response.data);

		// merge data
	},

	async getTwitchVideos(cb) {

		try {
			let response = await axios.get(twitch.api + twitch.channelId + "/videos",
				{headers: {
						'Client-ID': twitch.clientId,
						'Accept': " application/vnd.twitchtv.v5+json"
					}
				});

			cb(null, response.data);
		} catch (err) {
			cb(err, null);
		}
	},

	async createClipAxios(url, length, startTime, cb) {
		let data = {
			"url": url,
			"start": parseInt(startTime).toString(),
			"length": parseInt(length).toString()
		};

		try {
			let response = await axios.post(aws.api.url,
				data,
				{headers: {
						'x-api-key': aws.api.apikey,
					}
				});

			cb(null, response.data);
		} catch (err) {
			cb(err, null);
		}
	},

	async createClip(options, cb) {

		const {url, length, startTime, username, arena, type, view, gameKey} = options;

		AWS.config.update({
			region: "us-east-2",
			accessKeyId: aws.lambda.accessKey,
			secretAccessKey: aws.lambda.secretKey
		});

		let  lambda = new AWS.Lambda();
		let that = this
		let data = {
			body: {
				start: parseInt(startTime).toString(),
				length: parseInt(length).toString(),
				url,
				username,
				view
			}
		};
		console.log("curring playyy", arena);
		console.log(JSON.stringify(data));
		let params = {
			FunctionName: aws.lambda.function,
			Payload: JSON.stringify(data),
		};

		lambda.invoke(params, function(err, data) {
			if (err) {
				console.error(err, err.stack);
				cb(err, data);
			} else {
				let data2 = JSON.parse(JSON.parse(data.Payload).body)

				that.insertVideoAsset(arena, "clip", username, type, data2.url, 0, null, view, gameKey, parseInt(startTime), function(err, data3) {
					console.log(err, data3, data2);
					cb(err, data2);
				});

			}
		});

		console.log(params);
	},

	async fetchS3(overwriteParams, cb) {
		AWS.config.update({
			region: aws.s3.region,
			accessKeyId: aws.s3.accessKey,
			secretAccessKey: aws.s3.secretKey
		});

		var s3 = new AWS.S3();
		var params = {
			Bucket: "replay-ott",
			Prefix: `games-v2/`,
			MaxKeys: 1000,
		};

		params = {...params, ...overwriteParams};

		s3.listObjectsV2(params, (err, data) => {
			//console.log(data)
			cb(err, data);
		});
	},

	async getPreviewSymbolicLink(url, cb) {
		try {
			let response = await axios.get(url);
			console.log("getPreviewSymbolicLink", response)
			cb(null, response);
		}
		catch (err) {
			console.log("getPreviewSymbolicLink ERROR", err)
			cb(err, null);
		}
	},

	async fetchBanner(cb) {

		try {
			
			let fileUrl = aws.banner_api.url
			let response = await axios.get(fileUrl);
			
			cb(null, response);
		}
		catch (err) {
			cb(err, null);
		}
	},

	async fetchSchedules(cb) {
		try {
			let fileUrl = await Utils.methods.getCloudFileUrl(aws.schedules_api.file, false)
			let response = await axios.get(fileUrl);
			cb(null, response);
		}
		catch (err) {
			cb(err, null);
		}
	},
	async fetchHeadAwait(key) {
		console.log("fetch...")
	

		AWS.config.update({
			region: aws.s3.region,
			accessKeyId: aws.s3.accessKey,
			secretAccessKey: aws.s3.secretKey
		});

		var s3 = new AWS.S3();
		var params = {
			Bucket: "replay-ott",
			Key: key,
		};
		// fetchHeadAwait
		var ok = await s3.headObject(params, function(e, e2) {
			console.log("calll", e ,e2)
		})
		console.log("oks", ok )
		return ok
	},
	
	async getObject(key, cb) {
		console.log("fetch object...")
		// let  lambda = new AWS.Lambda();

		// AWS.config.update({
		// 	region: "us-east-2",
		// 	accessKeyId: aws.lambda.accessKey,
		// 	secretAccessKey: aws.lambda.secretKey
		// });

		// let data = { body: { key } };
		// let params = {
		// 	FunctionName: "head-footage-cors",
		// 	Payload: JSON.stringify(data),
		// };

		// lambda.invoke(params, function(err, data) {
		// 	if (err) console.log("Head request error: ", err);
		// 	console.log(err, data);
		// 	cb(err, data);
		// });

		AWS.config.update({
			region: aws.s3.region,
			accessKeyId: aws.s3.accessKey,
			secretAccessKey: aws.s3.secretKey
		});

		var s3 = new AWS.S3();
		var params = {
			Bucket: "replay-ott",
			Key: key,
		};
		
		
		let res = await s3.getObject(params).promise(); //, function(err, data) {
		console.log("GET", key, params, s3, res, 22)
			
		if (typeof cb == "function") {
			cb(null, res)
		} else {
			return (null, res)
		}
		//}) 
		console.log("HMMM END", res)
	},
	async fetchHeadNew(key, cb) {

		AWS.config.update({
			region: aws.s3.region,
			accessKeyId: aws.s3.accessKey,
			secretAccessKey: aws.s3.secretKey
		});

		var s3 = new AWS.S3();
		var params = {
			Bucket: "replay-ott",
			Key: key,
		};
		
		let response = await s3.headObject(params).promise()
		console.log("head", key, params, s3, response)

		if (typeof cb == "function"){
			cb(null, response);
		} else {
			return (null, response)
		}
			
	},
	async fetchHead(key, cb) {
		console.log("fetch head...")
		// let  lambda = new AWS.Lambda();

		// AWS.config.update({
		// 	region: "us-east-2",
		// 	accessKeyId: aws.lambda.accessKey,
		// 	secretAccessKey: aws.lambda.secretKey
		// });

		// let data = { body: { key } };
		// let params = {
		// 	FunctionName: "head-footage-cors",
		// 	Payload: JSON.stringify(data),
		// };

		// lambda.invoke(params, function(err, data) {
		// 	if (err) console.log("Head request error: ", err);
		// 	console.log(err, data);
		// 	cb(err, data);
		// });

		AWS.config.update({
			region: aws.s3.region,
			accessKeyId: aws.s3.accessKey,
			secretAccessKey: aws.s3.secretKey
		});

		var s3 = new AWS.S3();
		var params = {
			Bucket: "replay-ott",
			Key: key,
		};
		
		s3.headObject(params, (err, data) => {
			console.log("head", key, params, s3, data)
			if (err) console.log("Head request error: ", err);

			if (typeof cb == "function"){
				cb(err, data);
			} else {
				return (err, data)
			}
			
		});
	},

	async fetchLastPreviewNew(dome, field, cb) {
		let that = this
		let link = "content/" + dome + "/" + field + "/preview.link"
		console.log("link !!!!!!!!!!!!!!!!!!", link, typeof cb)
		let e,r = await this.getObject(link)

		if (e) {
			console.log("GET OB error", e)

			if (typeof cb == "function") {
				cb(null, null)
			} else {
				return (null, null)
			}
			
		} else {
			let mp4 = r.Body.toString()
			console.log("GET OB", mp4)
			let response = await that.fetchHeadNew(mp4) //, function(err, response) {
			if (response) response.Key = mp4
			if (typeof cb == "function") {
				cb(null, response)
			} else {
				return (null, response)
			}
			
			
		}

		if (typeof cb == "function") {
			cb(null, null)
		} else {
			return (null, null)
		}
	
	},
	async fetchLastPreview(dome, field, dateKey, nbDays, cb) {
		const that = this;


		console.log("ENTERING WITH " , dateKey, nbDays)

		var d = new Date()
		d.setDate(d.getDate() - nbDays) //second attempt, get yesterday
		dateKey = d.toLocaleString("en-CA", { 
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		}).replaceAll("-", "")

		// enought tryed, make it fail
		if (nbDays >= 10) {
			cb(null)
		}

		var params = {
			Prefix: "games-v2/" + dome + "/" + dateKey + "/",
			Delimiter: "/",
		};

		this.fetchS3(params, function(err, data) {
			if (err) {
				cb(err, data)
			} else {
				const prefixes = data.CommonPrefixes;
				
				console.log(prefixes)
				const allPreviews = prefixes.filter(x => {
					if (x.Prefix.includes(dome + "-" + field)) {
						return x.Prefix	
					}
				})

				//let gameFound = null
				console.log("*************8")
				console.log(allPreviews)
				//let lastGame = allPreviews[allPreviews.length - 1].Prefix
				let sortedPrefixes = allPreviews.sort((a, b) => b.Prefix.localeCompare(a.Prefix));
				console.log(sortedPrefixes)
				console.log("*************8")


				if (!sortedPrefixes || sortedPrefixes.length == 0){ // if we request the second game of the day and there is none, abort
					console.log("fred - empty", nbDays)
					that.fetchLastPreview(dome, field, dateKey, nbDays + 1, cb)
					return;
				}

				
				/**
				 * on tv if < 55 min fetch previous hour so that players that finishes the game can enjoy themselves
					if > 55, fetch current hour so that people see that it's "live streamed" and prepare for when the other team finishes
					if none is found, then -1 -1 -1 until found
				*/
				let gameIndex = 0 // take first record
				var dateNow = new Date()
				let timeLast = sortedPrefixes[0].Prefix.split("/")[3].split("-")[0].substr(-2)
				let timeBeforeLast = -1
				if (sortedPrefixes.length >= 2) {
					timeBeforeLast = sortedPrefixes[1].Prefix.split("/")[3].split("-")[0].substr(-2)
				}
				
				
				// if the last game recorded is in the hour, go to the last game so that we keep ppl engaged, otherwise, no game, show last one
				// here we are answering the question, which game do we load, the last one in the stack
				if (dateNow.getMinutes() <= 55) {

					
					// if there is a game right now, and the last preview was 2h ago, then show the current prevuew
					if (timeLast == dateNow.getHours() && timeBeforeLast > -1) {
						if (!(timeLast - timeBeforeLast > 1 )) {
							gameIndex = 1
						}
					}
					console.log("testing!...", timeLast, timeBeforeLast, dateNow.getHours(), dateNow.getHours() - parseInt(timeLast), "results: " + gameIndex)

					/*if (timeBeforeLast > (dateNow.getHours() - 2) ) {
						timeLast
						console.log("go back!...", time, dateNow.getHours(), dateNow.getHours() - parseInt(time))
						gameIndex += 1 
					}*/
				}


				that.fetchHeadRecc(sortedPrefixes, gameIndex, function(e,r){
					if (e) {
						console.log("didnt find anything for the day...")
						console.log("fred 2 - empty", nbDays)
						that.fetchLastPreview(dome, field, dateKey, nbDays + 1, cb)
					} else{
						cb(null, r)
					}
				})

				// 2 last hours, otherwise, get yesterday, otherwise fail until reload.
				/*that.fetchHead(sortedPrefixes[0].Prefix + "preview.mp4", function(err, data1){
					if (err){
						that.fetchHead(sortedPrefixes[1].Prefix + "preview.mp4", function(err, data2){
							if (err){
								console.log("fails...")
								that.fetchLastPreview(dome, field, dateKey, nbDays + 1, cb)
								cb(null)
							}else{
								data2.Key = sortedPrefixes[1].Prefix + "preview.mp4"
								cb(null, data2)
							}
						})
					} else{
						data1.Key = sortedPrefixes[0].Prefix + "preview.mp4"
						cb(null, data1)
					}
				})*/
				
				
			}
		})

		console.log("oh no..")

	},

	fetchHeadReccNew(sortedPrefixes, index, cb) {
		const that = this
		that.fetchHead(sortedPrefixes[index].Prefix + "preview.mp4", function(err, data){
			if (err){
				if (index + 1 < sortedPrefixes.length){
					that.fetchHeadRecc(sortedPrefixes, index + 1, cb)
				} else{
					cb(null)
				}
			} else{
				data.Key = sortedPrefixes[index].Prefix + "preview.mp4"
				cb(null, data)
			}
		})
		
	},

	// 2 last hours, otherwise, get yesterday, otherwise fail until reload.
	fetchHeadRecc(sortedPrefixes, index, cb) {
		const that = this
		that.fetchHead(sortedPrefixes[index].Prefix + "preview.mp4", function(err, data){
			if (err){
				if (index + 1 < sortedPrefixes.length){
					that.fetchHeadRecc(sortedPrefixes, index + 1, cb)
				} else{
					cb(null)
				}
			} else{
				data.Key = sortedPrefixes[index].Prefix + "preview.mp4"
				cb(null, data)
			}
		})
		
	},
	async fetchGames(dome, cb) {
		
		const that = this;
		//get todays date
		var params = {
			Prefix: `games-v2/${dome}/`,
			Delimiter: "/",
		};

		this.fetchS3(params, function(err, data) {
			if (err) {
				cb(err, data)
			} else {
				const prefixes = data.CommonPrefixes;
				const sortedPrefixes = prefixes.sort((a, b) => b.Prefix.localeCompare(a.Prefix));
				let thisWeekGames = sortedPrefixes.splice(0, 7).map(x => x.Prefix);

				let res = [];
				let index = 0;

				// basically a for loop for each days...
				recursiveS3(thisWeekGames, index, function(err, result) {
					if (err) {
						cb(err)
					} else {
						cb(err, result);
					}
				}, res);
			}
		});

		const recursiveS3 = function recursiveS3Call(thisWeekGames, index, cb, res) {	
			if (index < thisWeekGames.length) {
				var params = {
					Prefix: thisWeekGames[index],
				};
				console.log(params)
				that.fetchS3(params, function(err, data) {
					console.log("data", data)
					if (err) {
						
						cb(err, data)
					} else {
						res = res.concat(data.Contents);
						recursiveS3Call(thisWeekGames, index + 1, cb, res);
					}
				});
			} else {
				// When the recursive calls are done and res has the result
				console.log("WTF")
				res = res.sort((a, b) => b.Key.localeCompare(a.Key))
				cb(null, res);
			}
		}
	},

	async fetchGamesByDate(dome, date, cb) {
		var params = {
			Prefix: `games-v2/${dome}/${date}`,
		};

		this.fetchS3(params, function(err, data) {
			if (err) {
				cb(err, data)
			} else {
				console.log(data.Contents);

				cb(err, data.Contents);
			}
		});
	},

	async fetchGameSiblingsByDate(dome, date, hour, cb) {
		var params = {
			Prefix: `games-v2/${dome}/${date}`,
			Delimiter: "-",
		};
		// These params get dates

		// const paymentDate = Utils.getPaymentDate(`${date}${hour}`);
		// const YYYYMMDD = Utils.yyyymmdd(paymentDate);

		const that = this;
		this.fetchS3(params, function(err, data) {
			if (err) {
				cb(err, data)
			} else {
				const prefixes = data.CommonPrefixes || [];

				params.Prefix = `games-v2/${dome}/${date}/${date}${hour}`;
				// These params get fields

				params.Delimiter = "/";
				that.fetchS3(params, function(err, data) {
					if (err) {
						cb(err, data)
					} else {
						const prefixes2 = data.CommonPrefixes;
						cb(err, prefixes.concat(prefixes2));
					}
				});
			}
		});
	},

	async fetchGameFiles(gameCode, cb) {
		
		const splitCode = gameCode.split("-");
		const dome = splitCode[1];
		const datePart = splitCode[0];
		const year = datePart.substr(0, 4);
		const month = datePart.substr(4, 2);
        const day = datePart.substr(6, 2);
		const date = year + month + day;

		// Currently returns all s3 objects in the games folder, not just the "games"
		AWS.config.update({
			region: aws.s3.region,
			accessKeyId: aws.s3.accessKey,
			secretAccessKey: aws.s3.secretKey
		});

		var s3 = new AWS.S3();
		var params = {
			Bucket: "replay-ott",
			Prefix: `games-v2/${dome}/${date}/${gameCode}/`
		};
		s3.listObjectsV2(params, function(err, data) {
			if (err) {
				cb(err, data)
			} else {
				cb(null, data)	
			}
		});

		// try {
		// 	let response = await axios.get(aws.videoRoot + "/gameData/gameData.json");
		// 	cb(null, response);
		// } catch (err) {
		// 	cb(err, null);
		// }
	},

	/**
	 * Structure of the quotas
	 * @param user : the current logged in user
	 * @param date : represents the footages that was recorded for that date
	 * @param seconds :  number of seconds this user have watching for that day
	 * @param cb
	 * @returns {Promise<void>}
	 */
	async incrementConsumptionUsage(user, date, seconds, cb) {

		console.log("incrementConsumptionUsage with ", user, date, seconds);
		let quotas = store.getters.userMetadata["quotas"];

		if (!quotas) quotas = [];

		let index = quotas.findIndex(x => x.date === date);

		let returnedState = seconds;
		if (index === -1) {
			quotas.push({"date": date, "time": seconds});
		} else {
			// already exists, increment
			returnedState = parseInt(quotas[index].time) + seconds;
			quotas[index].time = returnedState;
		}

		store.commit("UPDATE_METADATA_PROP", {name: "quotas", value: quotas});

		cb(returnedState);
	},

	async persistPaymentLegacy(u, playerId, team, date, cb) {

		u.meta.playerIds = [{id: playerId, team: team, date: date}];

		u.meta.purchase = {
			stripePrice: process.env.VUE_APP_STRIPE_PRICE,
			timestamp: new Date().toISOString(),
		}

		let payload = {
			user_metadata: {
				playerIds: u.meta.playerIds,
				purchase: u.meta.purchase,
				cohort: app.cohorts[app.cohorts.length - 1].id // keep track of cohorts
				// 1 = before stripe
				// 2 = stripe flow
				// 3 = public profiles
				// 4 = free trial
				// 5 = re-id flow + 4.99 price
			}
		};

		await Vue.prototype.$auth.updateCurrentUserMetadata(payload);
		cb(u);
	},

	async persistPayment(user, footageDate, product, type, arena, cb) {

		let obj = {
			timestamp: new Date().toISOString(),
			type,
			product,
			footageDate,
			arena
		};

		if (type === "sub") {
			obj = {
				timestamp: new Date().toISOString(),
				startDate: new Date().toISOString(),
				product
			};

			store.commit("PURCHASE_SUBSCRIPTION", obj);
		} else {
			store.commit("PURCHASE_GAME", obj);
		}
		
		store.commit("UPDATE_METADATA_PROP", {name: "cohort", value: app.cohorts[app.cohorts.length - 1].id});
		window.heap.track("paymentMade", {type, footageDate, product, arena});

		if (typeof cb === 'function') cb(user);
	},

	async checkCodeUsage(code, cb) {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base('appXYEe5OKqARdFU4');
		const table = base('Codes');

		const fetchedCodes = [];

		// Populate fetched codes
		table.select({
			// Selecting the first 3 records in Grid view:
			filterByFormula: `{Code} = "${code}"`,
			view: "Grid view",
		}).eachPage(function page(records, fetchNextPage) {
			records.forEach(function (record) {
				fetchedCodes.push({
					code: record.fields.Code,
					dateUsed: record.fields.DateUsed || "",
					id: record.getId()
				});
			});
			fetchNextPage();
		}, function done(err) {
			if (err) {
				console.error(err);

				cb(err, null)
			} else {
				const codeInDB = fetchedCodes.find(x => x.code == code);
				const codeId = codeInDB && !codeInDB.dateUsed && codeInDB.id;
				
				cb(null, codeId);
			}
		});
	},

	async markCodeAsUsed(id, cb) {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base('appXYEe5OKqARdFU4');
		const table = base('Codes');

		table.update(id, {
			"DateUsed": (new Date()).toISOString().split(".")[0]
		}, (err, record) => {
			if (err) {
				console.error(err);
				cb(err, null)
			} else {
				cb(err, record)
			
			}
			console.log("UPDATE RESPONSE 1 ", record);
		});
	},

	// to convert
	async fetchContent(dome, cb) {
		
		const that = this;
		var params = {
			Prefix: `content/${dome}/`,
		};
					
		that.fetchS3(params, function(err, data) {
			if (err) {
				cb(err, data)
			} else {
				const res = data.Contents.sort((a, b) => b.Key.localeCompare(a.Key))
				cb(null, res);
			}
		});
	},

	async checkTopViews(urls, cb) {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TopViews');

		const fetchedURLs = [];

		// Populate fetched codes
		table.select({
			// Selecting the first 3 records in Grid view:
			view: "Grid view",
		}).eachPage(function page(records, fetchNextPage) {
			records.forEach(function (record) {
				fetchedURLs.push({
					URL: record.fields.URL,
					Views: record.fields.Views || 0,
					id: record.getId()
				});
			});
			try {
				fetchNextPage();
			} catch (err) {
				console.log(err);
			}
		}, function done(err) {
			const returnedURLs = fetchedURLs.filter(x => urls.includes(x.URL));
			if (err) {
				console.error(err);
				cb(err, returnedURLs)
			} else {
				cb(null, returnedURLs);
			}
		});
	},

	async incrementTopViews(id, views, cb) {
		
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TopViews');

		table.update(id, {
			"Views": views
		}, (err, record) => {
			if (err) {
				console.error(err);
				cb(err, null)
			} else {
				cb(err, record)
			
			}
			console.log("UPDATE RESPONSE 2 ", record);
		});
	},

	// to remove
	async createTopViewsEntry(url, views, cb) {
		
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TopViews');

		table.create({
			"URL": url,
			"Views": views
		}, (err, record) => {
			if (err) {
				console.error(err);
				cb(err, null)
			} else {
				cb(err, record)
			}
			console.log("UPDATE RESPONSE 3 ", record);
		});
	},

	// fetch all clip and allow filtering
	//
	/**
	 * example of Tags
	 * [
	 * 	{type: "user", "value": "username", "rank": 5},
	 * 	{type: "user", "value": "rory", "rank": 4},
	 * 	{type: "user", "value": "fredow", "rank": 3},
	 *
	 * 	{type: "caption", "value": "nice play", "time": 55},
	 *
	 * 	{type: "leaderbord", "value": "4"},
	 *
	 * ]
	 *
	 * versus "Username" column and "Leaderbord" column
	 *
	 *  {}
	 * ]
	 */
	async getVideoAssets(filters, cb, sortByRanking = "") {

		const {arena, type, status, username, ranked, afterDate, beforeDate, gameKey} = filters;
		const type1 = type === "feed" ? "clip" : type; // We need 2 types for the feed
		const that = this;
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});

		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TopViews');
		
		
		let filterByFormula = 'AND(';
		filterByFormula += arena ? '{Arena} = "' + arena + '",' : "";
		filterByFormula += status ? '{Status} = "' + status + '",' : "";
		filterByFormula += type1 ? '{Type} = "' + type1 + '",' : "";
		filterByFormula += username ? '{Username} = "' + username + '", {HideFromProfile} != "true",' : "";
		filterByFormula += ranked ? '{Ranking} > 0,' : "";
		filterByFormula += afterDate ? '{Timestamp} >= "' + afterDate +'",' : "";
		filterByFormula += beforeDate ? '{Timestamp} <= "' + beforeDate + '",' : "";
		filterByFormula += gameKey ? '{GameKey} = "' + gameKey + '",' : "";
		filterByFormula = filterByFormula.slice(0,-1); // Remove trailing comma
		filterByFormula += ")";

		if (type === "feed") {
			// Construct second part of filter: OR
			filterByFormula = `OR(${filterByFormula}, AND({Arena} = "${arena}",{Type} = "top5"), AND({Arena} = "${arena}",{Type} = "button"))`;
		}

		console.log(filterByFormula)

		let fetchedItems = [];
		// Populate fetched codes
		table.select({
			// Selecting the first 3 records in Grid view:
			view: "Grid view",
			filterByFormula
		}).eachPage(function page(records, fetchNextPage) {
			records.forEach(function (record) {
				fetchedItems.push({
					URL: record.fields.URL,
					Views: record.fields.Views || 0,
					id: record.getId(),
					Type: record.fields.Type || "",
					Username: record.fields.Username || "",
					Status: record.fields.Status || "",
					Arena: record.fields.Arena || "",
					Tags: record.fields.Tags || "",
					Ranking: record.fields.Ranking || 0,
					GameKey: record.fields.GameKey || "",
					TimeMarker: record.fields.TimeMarker || null,
					Downloads: record.fields.Downloads || "[]"
				});
			});
			try {
				fetchNextPage();
			} catch (err) {
				console.log(err);

			}
		}, function done(err) {
			const returnedItems = fetchedItems.map(x => {
				const type = x.Type;
				const data = that.getPlayData(x.URL, type, x.GameKey);
				Object.assign(x, data);
				if (x.Username) x.username = x.Username;
				
				// override persisted url to put it in front of a CDN
				if (x.URL.startsWith(aws.videoRootNoCache)) {
					
					let wrapperUrl = aws.videoRoot
					wrapperUrl = Utils.methods.getCloudProviderBase(true, x.GameKey)

					x.URL = wrapperUrl + x.URL.substr(aws.videoRootNoCache.length);
					//console.log(x.GameKey, wrapperUrl, x.URL)
					
				} else {
					console.log("NOT AWS.. ?", x)
				}
				
				return x;
			}).sort((a, b) => {
				if (!sortByRanking) return b.dateObj - a.dateObj;

				return b.Ranking - a.Ranking;

			});

			if (err) {
				console.error(err);
				cb(err, returnedItems)
			} else {
				cb(null, returnedItems);
			}
		});
	},

	async updateVideoAssetStatus(id, updateObj, cb) {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});

		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TopViews');
		table.update(id, updateObj, (err, record) => {
			if (err) {
				console.error(err);
				cb(err, null);
			} else {
				cb(err, record);
			}
			console.log("UPDATE RESPONSE 4", record);
		});
	},

	async updateVideoDownloads(id, updateObj, cb) {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});

		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TopViews');
		table.update(id, updateObj, (err, record) => {
			if (err) {
				cb(err, null);
			} else {
				cb(null, record);
			}
		});
	},

	async insertVideoAsset(arena, type, username, status, url, views, timestamp, view, gameKey, timeMarker, cb) {

		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TopViews');
		let object = {
			"URL": url,
			"Views": views || 0,
			"Type": type,
			"Username": username,
			"Status": status,
			"Arena": arena,
			"View": view,
			"Timestamp": timestamp || new Date().toISOString(),
			"GameKey" : gameKey,
			"TimeMarker" : timeMarker
		}

		console.log("inserting into Airtable... ", object)

		table.create(object, (err, record) => {

			try {
				if (err) {
					console.log("error while inserting", object)
					console.error(err);
					cb(err, null)
				} else {
					console.log("good!")
					cb(null, record)
				}
				console.log("UPDATE RESPONSE 5", record);
			} catch(e){ console.log('error inside => ',e)}
		});
	},

	getPlayData(url, type, gameKey) {
		const split1 = url.split("/");
		const suffix = split1[split1.length - 1];
		const split2 = suffix.split("-");
		let seconds = parseInt(split2[split2.length - 1].replace(".mp4", ""));
		const username = split2.length == 3 ? split2[1] : null;
		let dateObj = new Date(seconds * 1000);
		const gameTime = String(gameKey.split('-')[0]).padEnd(14, 0)

		if (type === "top5") {
			let datePart = split1[split1.length - 2];

			const year = datePart.substr(0, 4);
            const month = datePart.substr(4, 2);
            const day = datePart.substr(6, 2);
            const date = new Date(year + "-" + month + "-" + day + "T00:00:00")

			console.log(year, month, day, date);

            dateObj = (new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )));
		}
		
		let date = (dateObj).toLocaleString(undefined, {weekday: 'short', month: "short", day: "numeric"});
		// if (date == "Invalid Date" && gameKey != "") {
		if (gameKey && gameKey != "") {
			dateObj = dayjs(gameTime, "YYYYMMDDHHMMSS")
		}

		const ret = {date, dateObj, suffix, gameTime};
		if (username) ret.username = username;
		return ret;
	},

	getDateObject(dateString, appendString) {
		let date = dateString + appendString;
		return new Date(date.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,'$4:$5:$6 $2/$3/$1'));
	},

	/**
	 * Top 5 process for context (step 4 is this function)
	 * 	1. generate thumbnail
	 * 	2. upload vid & thumnbail to s3
	 * 	3. add line in airtable
	 * 	4. THIS FUNCTION: ADDING USER TAGS & SENDING TRIGGER
	 */
	async processTop5AndSendTriggers(arena, periodStart, periodEnd) {

		const that = this;

		// find the video to get the Id on airtable and the arena
		that.getVideoAssets({arena: arena, type: "top5"}, function(err, data) {

			let lastTop5 = data[0];
			console.log(lastTop5, periodStart, periodEnd);

			// fetch all video part of the week from a cutoff that has a ranking > 0
			that.getVideoAssets({arena: arena, type: "clip", afterDate: periodStart, beforeDate: periodEnd}, function (err2, data2) {

				console.log(data2);

				// loop through the 5 clips, for each link it to the top 5 video
				let tagsForTop5Winners = [];
				let tagsForTop5Loosers = [];

				data2.forEach(function(e) {

					if (e.Ranking > 0 && e.Ranking < 6)
						tagsForTop5Winners.push(e.username);
					else if (!tagsForTop5Winners.includes(e.username) && !tagsForTop5Loosers.includes(e.username) && e.Status === "approved") {
						tagsForTop5Loosers.push(e.username);
					}

				})

				// update the top 5 winners into airtable
				console.log("FINISH", JSON.stringify(tagsForTop5Winners))
				that.updateVideoAssetStatus(lastTop5.id, {"Tags" : JSON.stringify(tagsForTop5Winners)}, function(rr, dd){
					console.log(rr,dd)
				})

				console.log("FINISH loosers", JSON.stringify(tagsForTop5Loosers))

				// send triggers for winners and loosers, need to query auth0 to get hubspot ID

				const payloadWinners = {top5Triggers: {properties: [{property: 'madeTop5', value: "1"}]}};
				const payloadLoosers = {top5Triggers: {properties: [{property: 'madeTop5', value: "0"}]}};

				const updateUsers = function(index, array, payload, cb) {

					if (index >= array.length) {
						cb && cb();
						return
					}

					that.fetchUser("username", array[index],
						function(err, user) {

							console.log("hubSpotContactId WINNER", user.username, user.email, user.app_metadata.hubSpotContactId)

							that.updateUserMetadata(null, payload, user.app_metadata.hubSpotContactId, function(err, data) {
								console.log("UPDATED USER tagsForTop5Winners", user.app_metadata.hubSpotContactId, err, data)
							})

							updateUsers(index + 1, array, payload, cb);
						}
					)
				};

				// add the metadata to hubspot
				updateUsers(0, tagsForTop5Winners, payloadWinners, function() {
					updateUsers(0, tagsForTop5Loosers, payloadLoosers, function() {});
				});
				

			}, true);
		})

		// trigger also everyone else that didn'T make it
		// get all user that submitted a play in same timeperiod

	},


	async checkSubmissions(cb) {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TotalSubmissions');

		let totalCount = 0;
		let id = "";

		// Populate fetched codes
		table.select({
			// Selecting the first 3 records in Grid view:
			view: "Grid view",
		}).eachPage(function page(records, fetchNextPage) {
			totalCount = records[0].fields.Count;
			id = records[0].getId();
			try {
				fetchNextPage();
			} catch (err) {
				console.log(err);
			}
		}, function done(err) {
			if (err) {
				console.error(err);
				cb(err, id, totalCount)
			} else {
				cb(null, id, totalCount);
			}
		});

	},

	async incrementSubmissions(id, count, cb) {
		
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TotalSubmissions');

		table.update(id, {
			"Count": count
		}, (err, record) => {
			if (err) {
				console.error(err);
				cb(err, null)
			} else {
				cb(err, record)
			}
			console.log("UPDATE RESPONSE SUBMISSIONS ", record);
		});
		
	},

	async getUserVoteStatus(userId, URL, cb) {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('Upvotes');

		let userVoted = false;

		let filterByFormula = 'AND(';
		filterByFormula += '{URL} = "' + URL + '",';
		filterByFormula += '{HeapID} = "' + userId + '"';
		filterByFormula += ")";

		// Populate fetched codes
		table.select({
			// Selecting the first 3 records in Grid view:
			filterByFormula: filterByFormula,
			view: "Grid view",
		}).eachPage(function page(records, fetchNextPage) {
			records.forEach(function (record) {
				if (record.getId()) {
					userVoted = true;
				}
			});
			fetchNextPage();
		}, function done(err) {
			if (err) {
				console.error(err);
				cb(err, userVoted)
			} else {				
				cb(null, userVoted);
			}
		});

	},

	async getUpvotesForURL(URL, cb) {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('Upvotes');

		let numVotes = 0;

		// Populate fetched codes
		table.select({
			// Selecting the first 3 records in Grid view:
			filterByFormula: `{URL} = "${URL}"`,
			view: "Grid view",
		}).eachPage(function page(records, fetchNextPage) {
			records.forEach(function (record) {
				if (record.getId()) {
					numVotes += 1;
				}
			});
			fetchNextPage();
		}, function done(err) {
			if (err) {
				console.error(err);
				cb(err, numVotes)
			} else {				
				cb(null, numVotes);
			}
		});

	},

	async getGameFieldAndTimeData(arena, cb) {
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('TopViews');

		let fetchedItems = [];
		table.select({
			maxRecords: 20, // todo: hack... to make dynamic, should be based on the existing games not on the clips
			sort: [{field: "Timestamp", direction: "desc"}],
			filterByFormula: `{Arena} = "${arena}"`,
			view: "Grid view",
		}).eachPage(function page(records, fetchNextPage) {
			records.forEach(function (record) {
				if (record.fields.GameKey) {
					const info = record.fields.GameKey.split('-');
					fetchedItems.push({
						time: info[0].substring(8), field: info[2]
					});
				}
			});
			fetchNextPage();
		}, function done(err) {
			if (err) {
				console.error(err);
				cb(err, fetchedItems)
			} else {				
				console.log("DONE getGameFieldAndTimeData", fetchedItems)
				cb(null, fetchedItems);
			}
		});
	},

	async upvoteURL(userId, URL, cb) {
		
		const Airtable = require('airtable');

		Airtable.configure({
			endpointUrl: airtable.endpointUrl,
			apiKey: airtable.apiKey
		});
		const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE);
		const table = base('Upvotes');

		table.create({
			"URL": URL,
			"HeapID": userId
		}, (err, record) => {
			if (err) {
				console.error(err);
				cb(err, null)
			} else {
				cb(err, record)
			}
			console.log("UPDATE RESPONSE 3 ", record);
		});
		
	},

	async getInstagramInfo(handle, cb) {

		const options = {
			method: 'GET',
			url: 'https://instagram-data1.p.rapidapi.com/user/info',
			params: {username: handle},
			headers: {
			'x-rapidapi-host': 'instagram-data1.p.rapidapi.com',
			'x-rapidapi-key': 'ca27cf97cfmshe43da4b3e9c0f6bp1dbebejsn9691ad6b2495'
			}
		};

		axios.request(options).then(function (response) {
			console.log(response.data);
			cb(null, response.data);
		}).catch(function (error) {
			console.error(error);
			cb(error, null);
		});
	},

}