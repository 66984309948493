import Vue from 'vue/dist/vue.js';
import axios from 'axios';
import { slack } from '../../config.json';


// Not working window.heap is missing functions (identity etc), fixed it affects =heap but then data not showing on the tool
// Currently solved by switching the keys in the index.html based on the url sheme...
// See https://www.gitmemory.com/issue/segmentio/analytics.js/605/605464507
// import heap from "./plugins/heap";
// Vue.use(heap, {key: process.env.VUE_APP_HEAP_ID});

Vue.config.errorHandler = (err, vm, info) => {
	// err: error trace
	// vm: component in which error occured
	// info: Vue specific error information such as lifecycle hooks, events etc.
	console.error("Error", err, vm, info);
	
	axios.get(slack.slackUrl + '&type=laredkocha&event=' + "ERROR_1" + '&text=(' + encodeURIComponent(window.location.href) + ')' + JSON.stringify(err) + "\n\n" + JSON.stringify(info));

	let data = 	"\n\tTag Name: " + vm.$vnode.componentOptions.tag +
				"\n\tComponent Name: " + vm.$vnode.componentOptions.Ctor.extendOptions.name;
    sendErrorOnSlack('Vue Error Handler', "Error: " + err + "\nVM: " + data + "\nInfo: " + info);
};

window.onerror = function(message, source, line, column, error) {
	console.error("Error 2" , message, source, line, column, error);
	
	axios.get(slack.slackUrl + '&type=laredkocha&event=' + "ERROR_2" + '&text=(' + JSON.stringify(encodeURIComponent(window.location.href))+') ' + JSON.stringify(message) + "\n\n" + JSON.stringify(error));

    sendErrorOnSlack('On Event', JSON.stringify(message));
};

// https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#window.addeventlistenererror
window.addEventListener('error', function(event) {
	sendErrorOnSlack('Error Listener', JSON.stringify(event));
});

// https://stackoverflow.com/questions/31472439/catch-all-unhandled-javascript-promise-rejections
window.addEventListener('unhandledrejection', function(event) {
    // event.promise contains the promise object
    // event.reason contains the reason for the rejection
	sendErrorOnSlack('Unhandled Rejection - Promise', JSON.stringify(event.promise));
	sendErrorOnSlack('Unhandled Rejection - Reason', JSON.stringify(event.reason));
});

axios.interceptors.response.use( 
  //successful callback, we don't need to do anything
  (response) => {
    return response;
  }, 

  //check if we received a 404 and redirect
  (error) => {
    sendErrorOnSlack('Interceptor', JSON.stringify(error));
    return Promise.reject(error);
  }
);

function sendErrorOnSlack(username, errorMessage) {
    let payload = {
		'channel': 'log-errors',
		'username': username,
		'text': errorMessage,
		'icon_emoji': ':robot_face:'
	}
	axios.post(slack.myReplaySlackHook, payload, {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		}
	});
}