<template>
    <div>
        <!-- ======== preloader start ======== -->
        <div class="preloader">
        <div class="loader">
            <div class="spinner">
            <div class="spinner-container">
                <div class="spinner-rotator">
                <div class="spinner-left">
                    <div class="spinner-circle"></div>
                </div>
                <div class="spinner-right">
                    <div class="spinner-circle"></div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <!-- preloader end -->

        <!-- ======== header start ======== -->
        <header class="header">
        <div class="navbar-area">
            <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg">
                    <a class="navbar-brand" href="index.html">
                    <img src="../assets/logoWhite.svg" alt="Logo" />
                    </a>
                    <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    >
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                    </button>

                    <div
                    class="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                    >
                    <ul id="nav" class="navbar-nav ms-auto">
                        <li class="nav-item">
                        <a class="page-scroll active" href="/">Home</a>
                        </li>
                        <li class="nav-item">
                        <a href="#features" class="page-scroll" >Features</a>
                        </li>
                        <li class="nav-item">
                        <a href="#about" class="page-scroll" >About</a>
                        </li>

                        <li class="nav-item">
                        <a href="#clients" class="page-scroll" >Clients</a>
                        </li>

                        <li class="nav-item">
                        <a href="#contact" class="page-scroll" >Contact</a>
                        </li>
                        <li class="nav-item">
                        <a href="#pricing" class="page-scroll" >Pricing</a>
                        </li>
                        <!-- <li class="nav-item">
                        <a href="#clients" class="page-scroll" >Clients</a>
                        </li> -->
                    </ul>
                    </div>
                    <!-- navbar collapse -->
                </nav>
                <!-- navbar -->
                </div>
            </div>
            <!-- row -->
            </div>
            <!-- container -->
        </div>
        <!-- navbar area -->
        </header>
        <!-- ======== header end ======== -->

        <!-- ======== hero-section start ======== -->
        <section id="home" class="hero-section" style="overflow-x: hidden" >
        <div class="container">
            <div class="row align-items-center position-relative">
            <div class="col-lg-6">
                <div class="hero-content">
                <h1 class="wow fadeInUp" data-wow-delay=".4s">
                    Camera System for 5v5 Soccer Fields
                </h1>
                <p class="wow fadeInUp" data-wow-delay=".6s">
                    Capture all footage from every game. We provide an app for players to sign up online and see all their best plays and those of their teammates.
                </p>
                <a
                    href="#contact"
                    class="main-btn border-btn btn-hover wow fadeInUp page-scroll"
                    data-wow-delay=".6s"
                    >Learn More</a
                >
                <a href="#features" class="scroll-bottom">
                    <i class="lni lni-arrow-down"></i
                ></a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="hero-img wow fadeInUp" data-wow-delay=".5s">
                <!-- <img src="../assets/template_landing/img/hero/hero-img.png" alt="" /> -->
                <video autoplay muted loop class="landing-img">
                    <source src="../assets/landingVideo.mp4"
                            type="video/mp4">
                </video>
                <!-- <img loading="lazy" src="../assets/landing.gif" alt="" class="landing-img"/> -->
                </div>
            </div>
            </div>
        </div>
        </section>
        <!-- ======== hero-section end ======== -->

        <!-- ======== subscribe-section start ======== -->
        <section id="contact-2" class="subscribe-section pt-120">
        <div class="container">
            <div class="subscribe-wrapper img-bg">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-7" style="width: 100%; text-align: center">
                <div class="section-title mb-15">
                    <p class="text-white pr-5 mb-25">Call us For More Information</p>
                    <a href="tel:+18442916615"><h2 class="text-white">1-844-291-6615</h2></a>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <!-- ======== subscribe-section end ======== -->

        <!-- ======== feature-section start ======== -->
        <section id="features" class="feature-section pt-120">
        <div class="container">
            <div class="row justify-content-center">
            <div class="col-lg-4 col-md-8 col-sm-10">
                <div class="single-feature">
                <div class="icon">
                    <i class="lni lni-facebook"></i>
                </div>
                <div class="content">
                    <h3>Social Media Content</h3>
                    <p>
                    Capture every amazing goal, save, and nutmeg from all your players. Your facility and the players in your leagues get easy video clips to post on social media.
                    </p>
                </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-8 col-sm-10">
                <div class="single-feature">
                <div class="icon">
                    <i class="fas fa-users" style="font-family: 'Font Awesome 5 Free'"></i>
                    <!-- <i class="lni lni-shopping-store"></i> -->
                </div>
                <div class="content">
                    <h3>Players Stick Around</h3>
                    <p>
                    With fieldside TVs installed in your facility, players stick around after the game to watch their highlights. Increase food and drink sales.
                    </p>
                </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-8 col-sm-10">
                <div class="single-feature">
                <div class="icon">
                    <i class="fab fa-html5" style="font-family: 'Font Awesome 5 Free'"></i>
                </div>
                <div class="content">
                    <h3>Weekly Top 5s</h3>
                    <p>
                    Create hype around your games when players compete in the weekly top 5s. The best plays will be featured for all to see.
                    </p>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <!-- ======== feature-section end ======== -->

        <!-- ======== about-section start ======== -->
        <section id="about" class="about-section pt-150" style="overflow: hidden">
        <div class="container">
            <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6">
                <div class="about-img">
                <img src="../assets/camera.png" alt="" class="w-100" />
                <img
                    src="../assets/template_landing/img/about/about-left-shape.svg"
                    alt=""
                    class="shape shape-1"
                />
                <img
                    src="../assets/template_landing/img/about/left-dots.svg"
                    alt=""
                    class="shape shape-2"
                />
                </div>
            </div>
            <div class="col-xl-6 col-lg-6">
                <div class="about-content">
                <div class="section-title mb-30">
                    <h2 class="mb-25 fadeInUp animated" data-wow-delay=".2s">
                    Get Social Media Content from Every Game
                    </h2>
                    <p class="fadeInUp animated" data-wow-delay=".4s">
                    How many amazing headers and bicycle have gone uncaptured in your facility? Get players sharing each top play on their social media platforms.
                    Every time a player downloads their play, you can share it on your page and tag those players, increasing the virality of your facility. 
                    </p>
                </div>
                <a
                    href="#contact"
                    class="main-btn border-btn btn-hover fadeInUp page-scroll"
                    data-wow-delay=".6s"
                    >Discover More</a
                >
                </div>
            </div>
            </div>
        </div>
        </section>
        <!-- ======== about-section end ======== -->

        <!-- ======== about2-section start ======== -->
        <section id="about" class="about-section pt-150" style="overflow: hidden">
        <div class="container">
            <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6">
                <div class="about-content">
                <div class="section-title mb-30">
                    <h2 class="mb-25 fadeInUp" data-wow-delay=".2s">
                    Players Watch Themselves on TV After the Game
                    </h2>
                    <p class="fadeInUp" data-wow-delay=".4s">
                    The fun doesn't have to end when the buzzer rings. Players will want to stick around, grab drinks, and watch their plays right next to the field.
                    We install TVs and previews of every game are available before players have finished playing. 
                    </p>
                </div>
                <ul>
                    <li>Increase Food/Drink Sales</li>
                    <li>Increase Repeat Bookings</li>
                    <li>Improve Your Facility Experience</li>
                </ul>
                <a
                    href="#contact"
                    class="main-btn btn-hover border-btn fadeInUp page-scroll"
                    data-wow-delay=".6s"
                    >Learn More</a
                >
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 order-first order-lg-last">
                <div class="about-img-2">
                <img src="../assets/curvedTVs.png" alt="" style="width: 92%;" />
                <img
                    src="../assets/template_landing/img/about/about-right-shape.svg"
                    alt=""
                    class="shape shape-1"
                />
                <img
                    src="../assets/template_landing/img/about/right-dots.svg"
                    alt=""
                    class="shape shape-2"
                />
                </div>
            </div>
            </div>
        </div>
        </section>
        <!-- ======== about2-section end ======== -->

        <section id="about" class="about-section pt-150" style="overflow: hidden">
        <div class="container">
            <h2 class="mb-25 fadeInUp" data-wow-delay=".2s">
                Learn How to Access Weekly Top 5s
            </h2>
            <div class="wistia_responsive_padding" style="padding:45.21% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/gea0hcaht8?seo=false&videoFoam=true" title="primary Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
        </div>
        </section>

        <!-- ======== feature-section start ======== -->
        <section id="clients" class="feature-extended-section pt-100">
        <div class="feature-extended-wrapper">
            <div class="container">
            <div class="row justify-content-center">
                <div class="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
                <div class="section-title text-center mb-60">
                    <h2 class="mb-25 fadeInUp" data-wow-delay=".2s">
                    Our Facilities
                    </h2>
                    <p class="fadeInUp" data-wow-delay=".4s">
                    Our proven system works in multiple cities across North America.
                    </p>
                </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                <div class="single-feature-extended">
                    <div class="icon">
                    <img src="../assets/urbanindoorfutbol.png">
                    </div>
                    <div class="content">
                    <h3>Urban Indoor Futbol</h3>
                    <p>
                        Miami, FL
                    </p>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-md-6">
                <div class="single-feature-extended">
                    <div class="icon">
                    <img src="../assets/highsoccerarena.png">
                    </div>
                    <div class="content">
                    <h3>High Soccer Arena</h3>
                    <p>
                        Orlando, FL
                    </p>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-md-6">
                <div class="single-feature-extended">
                    <div class="icon">
                    <img src="../assets/soccer5.png" style="width: 85px">
                    </div>
                    <div class="content">
                    <h3>Soccer 5</h3>
                    <p>
                        Montreal, QC, CA
                    </p>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-md-6">
                <div class="single-feature-extended">
                    <div class="icon">
                    <img src="../assets/district5.png">
                    </div>
                    <div class="content">
                    <h3>District 5</h3>
                    <p>
                        Montreal, QC, CA
                    </p>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-md-6">
                <div class="single-feature-extended">
                    <div class="icon">
                    <img src="../assets/laredkocha.png">
                    </div>
                    <div class="content">
                    <h3>La Redkocha</h3>
                    <p>
                        Miami, FL
                    </p>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-md-6">
                <div class="single-feature-extended">
                    <div class="icon">
                    <img src="../assets/mrgol.png">
                    </div>
                    <div class="content">
                    <h3>Mr Gol</h3>
                    <p>
                        Miami, FL
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <!-- ======== feature-section end ======== -->

        <!-- ======== subscribe-section start ======== -->
        <section id="pricing" class="subscribe-section pt-120">
        <div class="container pricing-container">
            <div>
                <div class="top-section-pricing">
                    <p>
                        MyReplay Full Installation
                    </p>
                    <h1 class="mt-15">
                        $200
                    </h1>
                    <div class="mt-15">
                        Monthly price per field
                    </div>
                    <a href="#contact" class="page-scroll" ><button class="main-btn btn-hover mt-25">
                        Order Now
                    </button></a>
                </div>
                <div class="divider mt-15 mb-15">
                </div>
                <div class="mt-15" style="font-weight: 700">
                    <i class="fa fa-check" aria-hidden="true"></i> Increase reservation prices by up to $10
                </div>
                <div class="mt-15" style="font-weight: 700">
                    <i class="fa fa-check" aria-hidden="true"></i> x2 food & drinks sales
                </div>
                <div class="mt-15">
                    <i class="fa fa-check" aria-hidden="true"></i> Instantaneously replays after the game
                </div>
                <div class="mt-15">
                    <i class="fa fa-check" aria-hidden="true"></i> Free access for all players
                </div>
                <div class="mt-15">
                    <i class="fa fa-check" aria-hidden="true"></i> Unlimited technical support
                </div>
                <div class="mt-15">
                    <i class="fa fa-check" aria-hidden="true"></i> $1K Installation Cost - Fully Refundable
                </div>
            </div>
        </div>
        </section>
        <!-- ======== subscribe-section end ======== -->

        <!-- ======== subscribe-section start ======== -->
        <section id="contact" class="subscribe-section pt-120">
        <div class="container">
            <div class="subscribe-wrapper img-bg">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-7">
                <div class="section-title mb-15">
                    <h2 class="text-white mb-25">Find Out How To Install</h2>
                    <p class="text-white pr-5">
                    Enter your email and we will get in touch with you to see if you're a good fit for footage in your facility. Or call us at the number below.
                    </p>
                    <a href="tel:+18442916615"><h2 class="text-white mt-15">1-844-291-6615</h2></a>
                </div>
                </div>
                <div class="col-xl-6 col-lg-5">
                <form action="#" class="subscribe-form" @submit="submitEmail">
                    <input
                    type="email"
                    name="subs-email"
                    id="subs-email"
                    placeholder="Your Email"
                    />
                    <input
                    type="email"
                    name="subs-email"
                    id="subs-email"
                    placeholder="Your Phone number"
                    />
                    <button type="submit" class="main-btn btn-hover">
                    Submit
                    </button>
                </form>
                </div>
            </div>
            </div>
        </div>
        </section>
        <!-- ======== subscribe-section end ======== -->

        <!-- ======== footer start ======== -->
        <footer class="footer">
        <div class="container">
            <div class="widget-wrapper">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="logo mb-30">
                    <a href="index.html">
                        <img src="../assets/logoWhite.svg" alt="" />
                    </a>
                    </div>
                    <p class="desc mb-30 text-white">
                    The easiest way to capture every play from your facility.
                    </p>
                    <ul class="socials">
                    <li>
                        <a target="_blank" href="https://facebook.com/myreplay.co">
                        <i class="lni lni-facebook-filled"></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://twitter.com/myreplayco">
                        <i class="lni lni-twitter-filled"></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://instagram.com/myreplay.co">
                        <i class="lni lni-instagram-filled"></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://linkedin.com/company/myreplay">
                        <i class="lni lni-linkedin-original"></i>
                        </a>
                    </li>
                    </ul>
                </div>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-6">
                <div class="footer-widget">
                    <h3>About Us</h3>
                    <ul class="links">
                    <li><a href="/">Home</a></li>
                    <li><a href="#features" class="page-scroll">Features</a></li>
                    <li><a href="#about" class="page-scroll">About</a></li>
                    <li><a href="#why" class="page-scroll">Why</a></li>
                    </ul>
                </div>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>MyReplay Product</h3>
                    <ul class="links">
                    <li><a href="https://myreplay.co/#/feed/laredkocha">Feed</a></li>
                    <li><a href="https://myreplay.co/#/search">All Games</a></li>
                    <li><a href="https://myreplay.co/#/terms">Terms</a></li>
                    </ul>
                </div>
                </div>

                <!-- <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Other Products</h3>
                    <ul class="links">
                    <li><a href="jvascript:void(0)">Accounting Software</a></li>
                    <li><a href="jvascript:void(0)">Billing Software</a></li>
                    <li><a href="jvascript:void(0)">Booking System</a></li>
                    <li><a href="jvascript:void(0)">Tracking System</a></li>
                    </ul>
                </div>
                </div> -->
            </div>
            </div>
        </div>
        </footer>
        <!-- ======== footer end ======== -->

        <!-- ======== scroll-top ======== -->
        <!-- <a href="#" class="scroll-top btn-hover">
        <i class="lni lni-chevron-up"></i>
        </a> -->

        <!-- ======== JS here ======== -->
        <!-- <script src="assets/js/bootstrap.bundle.min.js"></script>
        <script src="assets/js/wow.min.js"></script>
        <script src="assets/js/main.js"></script> -->
    </div>
</template> 

<script src='../assets/template_landing/js/bootstrap.bundle.min.js'></script>
<script data-cfasync="false">window.ju_num="E72CBDE3-9352-4ED5-A1F1-C8A96069B40A";window.asset_host='//cdn.jst.ai/';(function(i,s,o,g,r,a,m){i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script',asset_host+'vck.js','juapp');</script>
<script src='../assets/template_landing/js/main.js'></script>
<script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
<script>
import { WOW } from 'wowjs/dist/wow.min.js';
import axios from "axios";
import {slack} from "/config.json";


export default {
    name: 'Facilities',
	components: {

	},
	data() {
		return {
		}
	},
	computed: {

	},
	watch: {
        
	},
	methods: {
        submitEmail(event) {
            event.preventDefault();
            event.stopPropagation();
            this.myReplaySlackHook("log-users", "OWNER_SUBMITTED_EMAIL", `Email: ${event.target.elements["subs-email"].value} on new landing page`)
            return false;
        }
	},
	mounted() {

        var wow = new WOW({
            live: true,
            offset: 160
        });

        wow.init();

        function fadeout() {
            window.document.querySelector(".preloader").style.opacity = "0";
            window.document.querySelector(".preloader").style.display = "none";
        }

        fadeout();

        /*=====================================
            Sticky
            ======================================= */
        window.onscroll = function () {
            const header_navbar = document.querySelector(".navbar-area");
            const sticky = header_navbar.offsetTop;
            const logo = document.querySelector(".navbar-brand img");

            if (window.pageYOffset > sticky) {
                header_navbar.classList.add("sticky");
                logo.src = "../assets/template_landing/img/logo/logo-2.svg";
            } else {
                header_navbar.classList.remove("sticky");
                logo.src = "../assets/template_landing/img/logo/logo.svg";
            }

            // show or hide the back-top-top button
            const backToTo = document.querySelector(".scroll-top");
            if (
            document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50
            ) {
            backToTo.style.display = "flex";
            } else {
            backToTo.style.display = "none";
            }
        };

        // for menu scroll
        const pageLink = document.querySelectorAll(".page-scroll");

        pageLink.forEach((elem) => {
            elem.addEventListener("click", (e) => {
            e.preventDefault();
            document.querySelector(elem.getAttribute("href")).scrollIntoView({
                behavior: "smooth",
                offsetTop: 1 - 60,
            });
            });
        });

        // section menu active
        function onScroll(event) {
            const sections = document.querySelectorAll(".page-scroll");
            const scrollPos =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;

            for (let i = 0; i < sections.length; i++) {
                const currLink = sections[i];
                const val = currLink.getAttribute("href");
                const refElement = document.querySelector(val);
                const scrollTopMinus = scrollPos + 73;
                if (
                    refElement.offsetTop <= scrollTopMinus &&
                    refElement.offsetTop + refElement.offsetHeight > scrollTopMinus
                ) {
                    document.querySelector(".page-scroll").classList.remove("active");
                    currLink.classList.add("active");
                } else {
                    currLink.classList.remove("active");
                }
            }
        }

        window.document.addEventListener("scroll", onScroll);

        let navbarToggler = window.document.querySelector(".navbar-toggler");
        const navbarCollapse = window.document.querySelector(".navbar-collapse");

        window.document.querySelectorAll(".page-scroll").forEach((e) =>
            e.addEventListener("click", () => {
            navbarToggler.classList.remove("active");
            navbarCollapse.classList.remove("show");
            })
        );
        navbarToggler.addEventListener("click", function () {
            navbarToggler.classList.toggle("active");
            navbarCollapse.classList.toggle("show");
        });


        var observer = new IntersectionObserver(onIntersection, {
            root: null,   // default is the viewport
            threshold: .5 // percentage of taregt's visible area. Triggers "onIntersection"
        })

        // callback is called on intersection change
        function onIntersection(entries, opts){
            window.heap.track("scrollToPricing");
        }

        // Use the bserver to observe an element
        observer.observe( document.querySelector('#pricing') )


	}
}
</script>

<style src="../assets/template_landing/css/bootstrap.min.css" scoped></style>
<style src="../assets/template_landing/css/lineicons.css" scoped></style>
<style src="../assets/template_landing/css/animate.css" scoped></style>
<style src="../assets/template_landing/css/main.css" scoped></style>

<style scoped>

* {
    font-family: Nunito;
}

.text-white {
    color: #fff !important;
}

.navbar-brand img {
    width: 160px;
}

.navbar-nav .nav-item a::before {
    background: #ff0054 !important;
}

#about {
    overflow: hidden;
    margin-bottom: -100px;
    padding-bottom: 100px;
}

.landing-img {
    max-width: 50vw;
    border-radius: 24px;
}

.hero-section {
    padding: 160px 0 250px;
    margin-bottom: -200px;
}

.lni-bootstrap::before {
    content: "\e918";
}

.wow {
   visibility: hidden;
}

.subscribe-form button {
    top: 6px !important;
    right: 6px !important;
}

.pricing-container {
    max-width: 400px;
    padding: 24px;
    border-radius: 24px;
    border: 1px solid #eeefff;
}

.top-section-pricing {
    text-align: center;
}

.divider {
    width: 100%;
    height: 1px;
    background: #eeefff;
}

.fa-check {
    color: #4caf50;
}

.icon img {
    width: 120px;
    height: 120px;
}

.single-feature-extended {
    text-align: center;
}

@media only screen and (max-width: 991px) {
    .hero-section .hero-img img, .hero-section .hero-img video {
        max-width: 100% !important;
    }
}

@media (max-width: 767px) {
    .hero-section {
        padding-top: 130px;
        padding-bottom: 150px;
    }
}
</style>