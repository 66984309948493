import createAuth0Client from "@auth0/auth0-spa-js";
import Vue from 'vue/dist/vue.js';
import {auth} from "../../config.json";
import axios from "axios";
import store from '@/store/store'
import router from '@/router/router';

let instance;


// centralizing this because of of the potential race condition
const defaultOptions = {
	audience: auth.api,
	domain: auth.domain,
	clientId: auth.clientId,
	redirectUri: window.location.origin + "/#",
	scope: 'openid profile email update:current_user_metadata read:current_user',
	screen_hint: "signup",
}

//const getInstance = () => instance;

const useAuth0 = () => {

	if (instance) {
		return instance;
	}
	instance = new Vue({
		data() {
			return {
				auth0Client: null,
				isLoading: true, // is only for the redirect loading on Created
				isAuthenticated: false,
				error: null,
				popupOpen: false,
			};
		},
		methods: {

			async loginWithRedirect(page, code) {

				let o = {
					redirect_uri: defaultOptions.redirectUri + (page ? page : ""),
					appState: {gameCode: code}
				}

				return await this.auth0Client.loginWithRedirect(o);
			},

			logout(options) {
				store.commit("CLEAR_USER");
				return this.auth0Client.logout(options);
			},

			getTokenSilently(ignoreCache) {

				const options = {
					ignoreCache: ignoreCache
				}

				return this.auth0Client.getTokenSilently(options);
			},
			async commitState() {
				this.isAuthenticated = await this.auth0Client.isAuthenticated();
				const user = await this.auth0Client.getUser(); // passing the audience makes a diff?
				this.isLoading = false;
				store.commit("UPDATE_USER", user);
			},

			async updateCurrentUserMetadata(metadataPayload) {

				try {

					let accessToken = await this.getTokenSilently();
					await axios.patch(auth.api + "users/" + store.getters.user.sub, metadataPayload, {
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + accessToken
						}
					})

					return this.getTokenSilently(true) // persist new data in token

				} catch (e) {
					console.log("error fetching user", e)
					return null;
				}
			}
		},
		async created() {

			this.auth0Client = await createAuth0Client({
				...defaultOptions,
				domain: defaultOptions.domain,
				client_id: defaultOptions.clientId,
				audience: defaultOptions.audience,
				redirect_uri: defaultOptions.redirectUri,
				scope: defaultOptions.scope,
				useRefreshTokens: true,
				cacheLocation: 'localstorage',
			});

			try {
				if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
					window.heap.track("userSignUp");
					const { appState } = await this.auth0Client.handleRedirectCallback();

					let url = "/" + window.location.hash;

					window.history.replaceState({}, document.title, url);

					if (appState && appState.gameCode) {
						console.log("GOT CODE")
						const gameCode = appState.gameCode;
						const footageSplit = url.split("/")
						const footageId = footageSplit[footageSplit.length - 1]
						router.push({name: "footage", params: {footageId}, query: {gameCode}});
						// router.push(url.replace("#/",""), {query: {gameCode}})
					}

					
					//window.history.replaceState({}, document.title, url);
					//console.log("Location: ", window.location.href);
				}
			} catch (error) {
				this.error = error;
				console.log("Error from login", error)
			} finally {
				await this.commitState();
			}
		},

	});

	return instance;
};

export default {
	install(Vue) {
		//if (!options) options = defaultOptions

		Vue.prototype.$auth = useAuth0();

	},
};