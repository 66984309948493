<template>
  <div id="owner-feed-page">
    <replay-header></replay-header>
    <div class="feed-container">
      <div class="feed-content-wrapper">
        <div v-if="curation" class="search-filters">
          <div class="search-filters--title">Curate Plays For</div>
          <div class="search-filters--options">
            <dropdown
              :title="selectedDome && selectedDome.name"
              :list="domes"
              @select="selectDome"
            ></dropdown>
          </div>

          <br />
          <div><button @click="triggerTop5()">Send TOP5 trigger!</button></div>
        </div>

        <span v-if="!hasFeatureFlag('no_paywall_button_only_flow')">
          <router-link
            v-if="userFeed"
            :to="{ name: 'search', query: { arena: domeKey } }"
          >
            <div @click="log(domeKey)" class="user-feed--button button">
              {{ $t("ownerFeed.view_games") }}
              <i class="fas fa-arrow-right"></i>
            </div>
          </router-link>
        </span>
        <div v-if="userFeed" class="user-feed--header">
          <div class="user-feed--title">
            <span v-if="hasFeatureFlag('no_paywall_button_only_flow')">
              {{ selectedDome && selectedDome.name }}
              <br />
              <hr />
              <i
                >⚠️ Le service sera discontinué à partir du 3 avril ⚠️<br /><br />
                Parlez-en au staff de district 5 pour remettre le service.
                <a href="/#/footagePreview/district5/3" style="color:white"
                  >*</a
                >
                <br />
                <br />
              </i>
            </span>
            <span v-else>
              {{ $t("ownerFeed.title") }}
              {{ selectedDome && selectedDome.name }}
            </span>

            <span
              :title="$t('ownerFeed.reset_filters')"
              @click="resetFilters(selectedDome)"
            >
              <i class="undo-filter fa fa-undo"></i>
            </span>
          </div>
          <hr style="border: none;background-color: #efeff1;height: 1px;" />
          <div
            class="search-filters--options"
            style="margin-top: 10px !important;"
          >
            <dropdown
              class="adjust"
              :title="field && field.name"
              :list="fields"
              :fitContent="true"
              @select="selectField"
            ></dropdown>
            <datepicker
              @selected="selectDate"
              input-class="datepicker-input"
              class="datepicker adjust"
              :value="selectedDate"
              calendar-class="datepicker-calendar"
              :highlighted="highlighted"
            ></datepicker>
            <dropdown
              class="adjust"
              :title="time && time.name"
              :list="hours"
              :fitContent="true"
              @select="selectTime"
              :gameSearch="true"
            ></dropdown>
          </div>
        </div>
        <div
          :id="banner.id + '-feed-banner-' + domeKey"
          v-for="(banner, i) in bannersList"
          :key="i"
          class="search-filters close-icon"
        >
          <span @click="closeBanner(banner.id)"
            ><i :title="$t('search.hide')" class="fa fa-times-circle"></i
          ></span>
          <div class="search-filters--title">{{ banner.content.text }}</div>
          <span v-if="banner.content.image">
            <img class="banner-image" :src="banner.content.image" alt="" />
          </span>
        </div>
        <loading-gif :showGif="loading"></loading-gif>
        <div v-for="(c, i) in topContent" :key="c.url + i">
          <feed-element
            :description="c.title"
            :picture="selectedDome && selectedDome.img"
            :arena="selectedDome && selectedDome.name"
            :timeAgo="c.timeAgo"
            :owner="owner"
            :userFeed="userFeed"
            :videoUrl="c.url"
            :shareLink="c.shareLink"
            :shared="c.shared"
            :views="c.Views"
            :publicUsername="c.username"
            :id="c.id"
            :top5Users="c.top5Users"
            :style="{ opacity: c.opacity, display: c.display }"
            :ranking="c.Ranking"
            :timeMarker="c.TimeMarker"
            :gameKey="c.GameKey"
            :downloads="c.Downloads"
            :type="c.Type"
            style="transition: opacity 1s"
            ref="feedElement"
            @highlight-viewed="incrementViews"
            @download="download"
          >
            <!-- slot -->
          </feed-element>

          <div v-if="userFeed && i == 0" class="user-feed--header">
            <div class="user-feed--title">{{ $t("feed.top_week") }}</div>
          </div>
        </div>
        <div v-for="d in dayContentGames" :key="d.date">
          <div class="date-divider">
            <div class="date-divider--line"></div>
            <div class="date-divider--day">{{ d.date }}</div>
          </div>

          <div v-for="(v, i) in d.content" :key="v.gameKey + i">
            <div class="data-center" style="font-weight:bold">
              {{ $t("ownerFeed.game_header_pre") }}
              {{ timeAgoHours(v.gameKey) }}
            </div>
            <div
              class="data-center"
              style="font-size:medium; font-style: italic;"
            >
              <br />{{ v.games.length }} {{ $t("ownerFeed.game_header_post") }}
            </div>

            <div v-for="(c, i) in v.games" :key="c.url + i">
              <feed-element
                :description="c.title"
                :picture="selectedDome && selectedDome.img"
                :arena="selectedDome && selectedDome.name"
                :timeAgo="c.timeAgo"
                :owner="owner"
                :userFeed="userFeed"
                :videoUrl="c.url"
                :shareLink="c.shareLink"
                :shared="c.shared"
                :views="c.Views"
                :publicUsername="c.username"
                :id="c.id"
                :top5Users="c.top5Users"
                :style="{ opacity: c.opacity, display: c.display }"
                :timeMarker="c.TimeMarker"
                :gameKey="c.GameKey"
                :downloads="c.Downloads"
                :type="c.Type"
                style="transition: opacity 1s"
                ref="feedElement"
                @highlight-viewed="incrementViews"
                @download="download"
              >
                <!-- slot -->
                <div v-if="curation" class="curation-buttons">
                  <div
                    class="accept"
                    :style="{ width: c.acceptWidth }"
                    @click="curatePlay(c, 'approved')"
                  >
                    ACCEPT
                  </div>
                  <div
                    class="reject"
                    :style="{ width: c.rejectWidth }"
                    @click="curatePlay(c, 'rejected')"
                  >
                    REJECT
                  </div>
                </div>
              </feed-element>
            </div>
          </div>
        </div>

        <div
          v-if="topContent.length === 0 && dayContent.length === 0 && !loading"
          class="game-preview"
        >
          <div class="no-games">{{ $t("search.no_games") }}</div>
        </div>

        <div class="load-more-wrapper">
          <div
            @click="loadMore"
            v-if="paginationLength < allContent.length"
            class="button load-more-button"
          >
            <!-- {{$t('search.load_more')}} -->
            {{ $t("profile.load_more") }}
          </div>
        </div>
      </div>
    </div>

    <div
      :class="{ hidden: !showDownloadModal }"
      class="modal-overlay"
      @click="hideModal"
    >
      <div class="modal-container">
        <div class="modal-content" @click.stop>
          <div class="modal-content--title">
            {{ $t("ownerFeed.download_this_clip") }}
          </div>
          <div class="modal-content--description">
            {{ $t("ownerFeed.select_the_angle") }}
          </div>
          <br />
          <div>
            <div
              class="btns control-container--button"
              style="width:25%; float:left"
              @click="downloadVideo('left')"
            >
              {{ $t("videoController.left") }} &nbsp;
              <i class="fas fa-download"></i>
            </div>
            <div
              class="btns control-container--button"
              style="width:25%; float:right"
              @click="downloadVideo('right')"
            >
              {{ $t("videoController.right") }} &nbsp;
              <i class="fas fa-download"></i>
            </div>
            <div
              class="btns control-container--button"
              style="width:25%; float:left; margin-left:12.5%"
              @click="downloadVideo('full')"
            >
              {{ $t("videoController.full") }} &nbsp;
              <i class="fas fa-download"></i>
            </div>
          </div>
          <div class="button-container" style="margin-top: 10px;">
            <div class="control-container--button" @click="hideModal">
              {{ $t("videoController.close") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <segment-modal
      :showModal="showSegmentModal"
      :domes="domes"
      @domeSelected="selectDome"
      @hideSegmentModal="hideSegmentModal"
    ></segment-modal>
  </div>
</template>

<script>
import ReplayHeader from "./ReplayHeader";
import FeedElement from "./FeedElement.vue";
import Dropdown from "./Dropdown.vue";
import LoadingGif from "./LoadingGif.vue";
import SegmentModal from "./SegmentModal";
import api from "@/api";
import axios from "axios";
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import { slack, featureFlags } from "/config.json";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Canada/Atlantic");

export default {
  name: "OwnerFeed",
  components: {
    ReplayHeader,
    FeedElement,
    Dropdown,
    LoadingGif,
    SegmentModal,
    Datepicker,
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const queryParams = this.$route.query;
    if (this.domeKey) {
      this.selectedDome = this.findDomeByKey(this.domeKey);
      this.setContent(this.selectedDome);
      this.fetchBanner();
      // api.getGameFieldAndTimeData(this.domeKey, (err, res) => {this.filterData(err, res);});
      if (
        queryParams &&
        (queryParams.field || queryParams.time || queryParams.date)
      )
        this.parametrizedURL = true;
    }
    //this.migratePlays()
  },
  data() {
    const lastMonday = new Date();
    lastMonday.setHours(0);
    lastMonday.setMinutes(0);
    lastMonday.setSeconds(0);
    lastMonday.setDate(lastMonday.getDate() - ((lastMonday.getDay() + 6) % 7));
    const user = this.$parent.$store.getters;

    return {
      domes: this.getDomeList().filter((x) => x.key !== "test"),
      allContent: [],
      allContentData: [],
      paginationLength: 5,
      selectedDome: {},
      owner: this.$route.name === "ownerFeed",
      curation: this.$route.name === "curationFeed",
      userFeed: this.$route.name === "userFeed",
      showSegmentModal: false,
      domeKey: this.$route.params && this.$route.params.arena,
      lastMonday,
      username: user.username,
      loading: false,
      selectedDate: new Date(),
      initialDate: new Date(),
      time: { name: "Select Time", key: "0" },
      hours: [],
      field: { name: "Select Field", key: "0" },
      fields: [],
      banners: [],
      parametrizedURL: false,
      downloadVideoLink: "",
      showDownloadModal: false,
    };
  },
  computed: {
    topContent: function() {
      const that = this;
      return this.allContent
        .filter((x) => {
          return (
            x.newestTop5 ||
            (x.Ranking > 0 && x.Ranking < 6 && x.dateObj > that.lastMonday)
          );
        })
        .sort((a, b) => {
          // First play is the shared one
          if (b.Type === "top5") {
            // Comes first
            return Number.POSITIVE_INFINITY;
          } else if (a.Type === "top5") {
            return Number.NEGATIVE_INFINITY;
          }

          // Rest sorted by ranking, increasing
          return a.Ranking - b.Ranking;
        });
    },
    dayContent: function() {
      const that = this;
      const nonTopContent = this.allContent.filter((x) => {
        return (
          !x.newestTop5 &&
          !(x.Ranking > 0 && x.Ranking < 6 && x.dateObj > that.lastMonday)
        );
      });

      const paginatedContent = nonTopContent.slice(0, this.paginationLength);
      const dates = {};
      const dateArray = paginatedContent.reduce((acc, cval) => {
        const myAttribute = cval.date;
        acc[myAttribute] = [...(acc[myAttribute] || []), cval];
        return acc;
      }, dates);

      return Object.keys(dateArray).map((key) => {
        return { date: key, content: dateArray[key] };
      });

      // return paginatedContent;
    },
    dayContentGames: function() {
      const that = this,
        games = {};
      const data = JSON.parse(JSON.stringify(that.dayContent));
      if (data && data.length) {
        data.forEach(function(dayArray) {
          let dateArray = [];
          dateArray = dayArray.content.reduce((acc, cval) => {
            const myAttribute = cval.GameKey;

            console.log("myAttribute", myAttribute);
            acc[myAttribute] = [...(acc[myAttribute] || []), cval];

            console.log("acc", acc);
            return acc;
          }, games);

          console.log("dateArray ", games);
          dayArray.content = [];
          dayArray.content = Object.keys(dateArray).map((key) => {
            return { gameKey: key, games: dateArray[key] };
          });
        });

        console.log("dateArray.content", data);
      }
      return that.cleanArray(data);
    },
    ...mapGetters(["user", "userMetadata", "publicUsers", "purchases"]),
    highlighted() {
      return {
        dates: this.purchases.map((x) => new Date(x.footageDate + "T00:00:00")),
      };
    },
    currentBanner() {
      return this.getCurrentBanners(
        "feed",
        this.selectedDome.key,
        this.selectedDate,
        this.banners
      );
    },
    bannersList: function() {
      return this.getBannersList(
        "feed",
        this.currentBanner,
        this.domeKey,
        this.username
      );
    },
  },
  methods: {
    timeAgoHours: function(gameKey) {
      // what to do when GameKey doesn't exist - because the output would look like - @ 10pm
      let startTime = gameKey.split("-")[0].slice(8);
      return (
        this.convertTime(startTime) + "-" + this.convertTime(startTime, true)
      );
    },
    hasFeatureFlag(flag) {
      let arena = this.selectedDome && this.selectedDome.key;

      if (featureFlags[flag] !== undefined) {
        return featureFlags[flag].includes(arena);
      }
      return false;
    },
    async log(url) {
      let ip = await this.getClientIP();
      this.myReplaySlackHook(
        "log-users",
        "VIEW_GAMES | User: " + this.username + " (" + ip + "), Arena: " + url,
        "",
        true
      );
    },
    incrementViews(url) {
      window.heap.track("viewOwnerFeed", {
        url: url,
        arena: this.selectedDome && this.selectedDome.key,
      });
    },
    getTop5sInternal() {
      const start = new Date(this.lastMonday);
      start.setDate(start.getDate() - 7);
      api.getVideoAssets(
        {
          ranked: true,
          type: "clip",
          afterDate: start.toISOString().split("T")[0],
          beforeDate: this.lastMonday.toISOString().split("T")[0],
        },
        function(err, data) {
          data.sort(function(a, b) {
            if (a.Arena == b.Arena) {
              return b.Ranking - a.Ranking;
            } else {
              return a.Arena.localeCompare(b.Arena);
            }
          });

          console.log(
            err,
            JSON.stringify(
              data.map((x) => ({ url: x.URL, ranking: x.Ranking }))
            )
          );
        }
      );
    },
    loadMore() {
      this.paginationLength = this.paginationLength + 10;
    },
    selectDome(dome) {
      this.hideSegmentModal();
      let path = "curation";
      if (this.userFeed) path = "feed";
      if (this.owner) path = "ownerfeed";
      this.$router.push({ path: `/${path}/${dome.key}` });
    },
    hideSegmentModal() {
      this.showSegmentModal = false;
    },
    selectDomeBySegment(segment) {
      let dome = this.findDomeByKey(segment);
      if (!dome.key) {
        dome = this.findDomeByName(segment);
      }
      if (!dome.key) return;
      this.selectDome(dome);
    },
    setContent(dome) {
      const that = this;
      let status;
      this.loading = true;

      if (this.owner || this.userFeed) status = "approved";
      if (this.curation) status = "submitted";

      const filters = { arena: dome.key, type: "clip", status, username: null };

      if (this.userFeed) {
        filters.type = "feed";
      }

      const query = this.$route.query;
      const sharedPlay = query && query.play;
      const path = this.$route.path;

      api.getVideoAssets(filters, (err, data) => {
        that.loading = false;
        if (err)
          that.myReplaySlackHook(
            "log-errors",
            "Owner Feed Page - ERROR FETCHING VIDEO ASSETS",
            err
          );
        const play_from =
          that.$i18n.messages[that.$i18n.locale].ownerFeed.play_from;
        const top_play =
          that.$i18n.messages[that.$i18n.locale].ownerFeed.top_play;
        const top_5 = that.$i18n.messages[that.$i18n.locale].ownerFeed.top_5;

        let newestTop5 = new Date();
        let newestTop5Index = -1;
        newestTop5.setDate(newestTop5.getDate() - 180); // Just to initialize

        const allContent = data.map((x, i) => {
          const playData = that.getPlayData(x);
          Object.assign(x, playData);
          x.url = x.URL;
          x.title = x.username ? `${play_from} ${x.username}` : top_play;
          x.acceptWidth = "50%";
          x.rejectWidth = "50%";
          x.opacity = "1";
          x.display = "inline-block";
          x.shared = x.suffix === sharedPlay;
          x.shareLink = `${window.location.origin}/#${path}?play=${x.suffix}`;

          // Find the newest top 5
          if (x.Type === "top5") {
            x.title = top_5;
            if (x.dateObj > newestTop5) {
              newestTop5 = x.dateObj;
              newestTop5Index = i;
            }
            x.top5Users = JSON.parse("[" + x.Tags + "]")[0];
          }

          return x;
        });

        if (newestTop5Index > -1) allContent[newestTop5Index].newestTop5 = true; // Used to pull to remove from dayContent

        const sortedContent = allContent.sort((a, b) => {
          // First play is the shared one
          if (b.newestTop5) {
            // Comes first
            return Number.POSITIVE_INFINITY;
          } else if (a.newestTop5) {
            return Number.NEGATIVE_INFINITY;
          }

          // Rest sorted by date
          return b.dateObj - a.dateObj;
        });

        if (sharedPlay) {
          sortedContent.forEach(function(item, i) {
            if (item.shared) {
              sortedContent.splice(i, 1);
              sortedContent.unshift(item);
            }
          });
        }

        that.allContentData = sortedContent;

        if (that.allContentData && that.allContentData[0])
          if (
            that.allContentData[0].date &&
            that.allContentData[0].date !== "Invalid Date"
          )
            that.selectedDate = that.allContentData[0].dateObj;
        that.initialDate = that.allContentData[0].dateObj;
        if (that.parametrizedURL) {
          const queryParams = that.$route.query;
          that.selectAllFilters(
            that.getDateObject(queryParams.date, "000000"),
            that.findTimeByKey(queryParams.time ? queryParams.time : "0"),
            that.findFieldByKey(queryParams.field ? queryParams.field : "0")
          );
        }

        this.allContent = this.filterGamesByDate();

        this.createDropDownValues(sortedContent);
      });
    },
    download(url, buttonClip) {
      axios.get(
        slack.slackUrl +
          "&type=laredkocha&event=" +
          "OWNER_DOWNLOAD_PLAY" +
          "&text=" +
          encodeURIComponent(
            `Owner downloaded play ${url} for arena: ${this.domeKey}`
          )
      );
      if (buttonClip) {
        this.downloadVideoLink = url;
        this.showDownloadModal = true;
      }
    },
    downloadVideo(angle) {
      let url = "";
      console.log(this.downloadVideoLink + " * " + angle);
      if (this.downloadVideoLink.indexOf("-full.mp4") > 0) {
        url = this.downloadVideoLink.replace("-full.mp4", "-" + angle + ".mp4");
        console.log("DOWNLOOAD 1 " + url);
      } else {
        url = this.downloadVideoLink.replace("full.mp4", angle + ".mp4");
        console.log("DOWNLOOAD 2 " + url);
      }

      this.myReplaySlackHook(
        "log-users",
        "DOWNLOAD_FEED_" + angle,
        "\n- URL: " + url,
        null,
        this.domeKey
      );

      this.downloadVideoFile(url, "my-replay-clip-" + angle + ".mp4");
    },
    hideModal() {
      this.showDownloadModal = false;
    },
    triggerTop5() {
      const start = new Date(this.lastMonday);
      start.setDate(start.getDate() - 7);

      api.processTop5AndSendTriggers(
        this.domeKey,
        start.toISOString().split("T")[0],
        this.lastMonday.toISOString().split("T")[0],
        function(err, data) {
          console.log(data);
        }
      );
    },
    persistCuratePlay(user, content, newStatus, choice) {
      const that = this;
      if (!user.user_metadata.triggers) user.user_metadata.triggers = [];

      user.user_metadata.triggers.push({
        type: "curation",
        timestamp: new Date().toISOString(),
        value: newStatus,
        url: content.url,
      });

      that.$store.commit("UPDATE_PUBLIC_USER_METADATA", user);

      console.log("CURATING... ", content, choice);
      api.updateVideoAssetStatus(
        content.id,
        { Status: newStatus, Ranking: choice },
        function(err, data) {
          if (err)
            that.myReplaySlackHook(
              "log-errors",
              "Owner Feed Page - updateVideoAssetStatus",
              err
            );

          // IF there is a ranking, get the other clips, and update the ranking of the other clips
          if (choice > 0) {
            let start = "";
            let end = "";
            if (content.dateObj > that.lastMonday) {
              // in current period
              end = new Date(that.lastMonday);
              end.setDate(end.getDate() + 7);

              start = that.lastMonday;
            } else {
              // it's in last week, maybe a late curation:
              start = new Date(that.lastMonday);
              start.setDate(start.getDate() - 7);
              end = that.lastMonday;
            }
            // format dates  "2021-11-29"

            let filters = {
              ranked: true,
              arena: content.Arena,
              type: "clip",
              afterDate: start.toISOString().split("T")[0],
              beforeDate: end.toISOString().split("T")[0],
            };

            api.getVideoAssets(filters, function(errr, dataa) {
              if (err)
                that.myReplaySlackHook(
                  "log-errors",
                  "Owner Feed Page - getVideoAssets",
                  err
                );

              if (!errr) {
                dataa.forEach(function(e) {
                  if (e.Ranking >= choice && e.id !== content.id) {
                    api.updateVideoAssetStatus(
                      e.id,
                      { Ranking: e.Ranking + 1 },
                      function(err, res) {
                        console.log(err, res);
                      }
                    );
                  }
                });
              }
            });
          }

          api.updateUserMetadata(
            user.user_id,
            user.user_metadata,
            user.app_metadata.hubSpotContactId,
            function(errr, dataa) {
              that.myReplaySlackHook(
                "log-errors",
                "Owner Feed Page - UPDATED USER",
                errr + " - " + dataa
              );
            }
          );

          if (!err) {
            if (newStatus === "approved") {
              content.acceptWidth = "100%";
              content.rejectWidth = "0%";
            } else {
              content.rejectWidth = "100%";
              content.acceptWidth = "0%";
            }

            const contentIndex = that.allContent.findIndex(
              (x) => x.id === content.id
            );
            that.allContent.splice(contentIndex, 1);
          }
          console.log(err, data);
        }
      );
    },
    curatePlay(content, newStatus) {
      const user = this.publicUsers.find(
        (u) => u.username === content.username
      );

      if (!user) {
        this.$swal(
          "Woops! Auth0 public user problem. Please refresh the page and try again."
        );
      } else {
        if (newStatus === "approved") {
          this.$swal({
            title: "Press enter to skip ranking this play",
            input: "radio",
            inputOptions: {
              "1": 1,
              "2": 2,
              "3": 3,
              "4": 4,
              "5": 5,
            },
            allowEnterKey: true,
            focusConfirm: false,
            allowEscapeKey: false,
            preConfirm: (choice) => {
              if (!choice) choice = -1;

              choice = parseInt(choice);

              console.log(choice);

              this.persistCuratePlay(user, content, newStatus, choice);
            },
          });
        } else {
          this.persistCuratePlay(user, content, newStatus, -1);
        }
      }
    },
    selectDate(date) {
      this.selectedDate = date;
      this.allContent = this.filterGamesByDate();
      this.createDropDownValues();
      this.time = { name: "Select Time", key: "0" };
      this.field = { name: "Select Field", key: "0" };
    },
    selectTime(time) {
      this.time = time;
      this.allContent = this.filterContent("time");
    },
    selectField(field) {
      this.field = field;
    },
    selectAllFilters(date, time, field) {
      if (!isNaN(Date.parse(date))) this.selectedDate = date;
      this.time = time;
      this.field = field;
    },

    filterContent() {
      const dateFiltered = this.filterGamesByDate();
      const time = this.filterGamesByTime(dateFiltered);
      return this.filterGamesByField(time);
    },

    resetFilters: function() {
      this.selectedDate = this.initialDate;
      this.time = { name: "Select Time", key: "0" };
      this.field = { name: "Select Field", key: "0" };
      this.allContent = this.filterContent();
    },

    findTimeByKey(time) {
      console.log("findTimeByKey", time);
      let re = this.hours.filter((x) => x.key == time)[0]
        ? this.hours.filter((x) => x.key == time)[0]
        : { name: "Select Time", key: "0" };
      console.log("findTimeByKey 2", re);
      return re;
    },
    findFieldByKey(field) {
      return this.fields.filter((x) => x.key == field)[0]
        ? this.fields.filter((x) => x.key == field)[0]
        : { name: "Select Field", key: "0" };
    },
    fetchBanner() {
      const fetchCallback = (err, res) => {
        if (err)
          this.myReplaySlackHook(
            "log-errors",
            "Game Search Page - ERROR FETCHING BANNER",
            err
          );
        else if (res) this.banners = JSON.parse(JSON.stringify(res.data));
      };
      api.fetchBanner(fetchCallback);
    },
    closeBanner(id) {
      localStorage.setItem(
        id +
          "-feed-banner-" +
          this.domeKey +
          "-" +
          (this.username ? this.username : ""),
        "hide"
      );
      document.getElementById(
        id + "-feed-banner-" + this.domeKey
      ).style.display = "none";

      this.myReplaySlackHook(
        "log-users",
        "FEED-BANNER-CLOSE",
        "- User: " + this.username + "\n- Banner_id: " + id,
        null,
        this.domeKey
      );
    },
    cleanArray(data) {
      for (var i = data.length - 1; i > 0; i--)
        data[i].content.splice(0, data[i - 1].content.length);
      return data;
    },

    getFieldAndTimeDataViaURL() {
      const that = this;
      if (that.allContent && that.allContent[0]) {
        if (
          that.allContent[0].date &&
          that.allContent[0].date !== "Invalid Date"
        )
          that.selectedDate = that.allContent[0].dateObj;

        let obj = {};
        if (that.allContent[0].GameKey && that.allContent[0].GameKey != "") {
          let part = that.allContent[0].GameKey.split("-");
          console.log("PARTT", part[0]);
          obj = {
            dome: that.selectedDome.key,
            date: part[0].slice(0, 8),
            hour: part[0].slice(8, 10),
          };
        } else {
          obj = {
            dome: that.selectedDome.key,
            date: that.yyyymmdd(that.allContent[0].dateObj),
            hour: 0,
          };
        }
        api.fetchGameSiblingsByDate(
          obj.dome,
          obj.date,
          obj.hour,
          (err, res) => {
            if (err)
              that.myReplaySlackHook(
                "log-errors",
                "Owner Feed - fetchGameSiblingsByDate",
                err
              );
            if (!err && res) {
              var obj = that.setHoursAndFields(res);

              console.log("fetchGameSiblingsByDate", obj);
              that.hours = obj.hours;
              that.fields = obj.fields;
              that.hours.unshift({ name: "Select Time", key: "0" });
              that.fields.unshift({ name: "Select Field", key: "0" });
              const queryParams = that.$route.query;
              if (that.parametrizedURL)
                that.selectAllFilters(
                  that.getDateObject(queryParams.date, "000000"),
                  that.findTimeByKey(queryParams.time ? queryParams.time : "0"),
                  that.findFieldByKey(
                    queryParams.field ? queryParams.field : "0"
                  )
                );
            }
          }
        );
      }
    },

    filterGamesByField: function(games) {
      if (this.field.key === "0") {
        return games;
      }

      return games.filter((game) => {
        const { GameKey } = game;
        const field = GameKey.split("-")[2];
        return field === this.field.key;
      });
    },

    filterGamesByTime: function(games) {
      if (this.time.key === "0") {
        return games;
      }

      return games.filter((game) => {
        const { GameKey } = game;
        const [time] = GameKey.split("-");
        const timeString = String(time).padEnd(14, 0);
        const date = dayjs(timeString, "YYYYMMDDHHmmss");
        return this.time.key === date.format("HHmm");
      });
    },

    filterGamesByDate: function() {
      return [...this.allContentData].filter((d) => {
        const { GameKey } = d;
        const [time] = GameKey.split("-");
        const timeString = String(time).padEnd(14, 0);
        const date = dayjs(timeString, "YYYYMMDD");
        return date.isSame(
          dayjs(this.selectedDate).format("YYYY-MM-DD"),
          "day"
        );
      });
    },

    createDropDownValues: function(allData) {
      console.log({ allData, date: this.selectedDate });
      console.log("Creating dropdown values");
      const slotValues = {};
      const fieldValues = {};
      const sorted = this.filterGamesByDate();
      sorted.forEach((data) => {
        const { GameKey } = data;
        // eslint-disable-next-line no-unused-vars
        const [time, _, field] = GameKey.split("-");
        const timeString = String(time).padEnd(14, 0);
        const date = dayjs(timeString, "YYYYMMDDHHMMSS");

        slotValues[`${date.format("HHmm")}`] = `${date.format(
          "hh:mma"
        )} - ${date.add(1, "hour").format("hh:mma")}`;

        fieldValues[field] = `Field ${field}`;
      });

      this.hours = [
        { name: "Select Time", key: "0" },
        ...Object.keys(slotValues).map((slot) => ({
          name: slotValues[slot],
          key: slot,
        })),
      ];

      this.fields = [
        { name: "Select Field", key: "0" },
        ...Object.keys(fieldValues).map((slot) => ({
          name: fieldValues[slot],
          key: slot,
        })),
      ];
    },
  },
  watch: {
    user: {
      handler() {
        const segment = this.userMetadata.segment;
        if (!segment && !this.domeKey) {
          this.showSegmentModal = true;
        } else if (segment && !this.domeKey) {
          this.showSegmentModal = false;
          this.selectDomeBySegment(segment);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style src="../assets/reset.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Nunito:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Russo+One&display=swap");

body {
  background: #fff !important;
}

a {
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#owner-feed-page {
  background: #efeff1;
  min-height: 100vh;
}

.feed-container {
  min-height: inherit;
}

.feed-content-wrapper {
  width: 100%;
  max-width: 500px;
  margin: auto;
  font-family: Nunito;
  margin-top: 16px;
}

.search-filters {
  width: 100%;
  background: #fafafa;
  border-radius: 12px;
  margin: 12px auto;
  padding: 16px;
  box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
  text-align: center !important;
}

.search-filters--options {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.search-filters--title {
  font-size: 20px;
  font-weight: 600;
  font-family: "Nunito";
  margin-bottom: 6px;
}

.user-feed--header {
  font-family: Nunito;
  margin: 16px 0;
  width: 100%;
  background: #fff;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: max(0.0001px, min(24px, ((100vw - 4px) - 100%) * 9999)) / 24px;
  text-align: center;
}

.user-feed--title {
  font-size: 20px;
  font-weight: 600;
}

.user-feed--button {
  padding: 8px 32px !important;
  width: fit-content !important;
  margin: 16px auto 4px !important;
  display: block;
}

.load-more-wrapper {
  padding: 16px 0 24px;
}

.button {
  cursor: pointer;
  font-family: "Nunito";
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 12px;
  background: linear-gradient(45deg, #ff0054, #610888);
  border-radius: 32px;
  padding: 8px;
  width: 140px;
  border-style: none;
  text-align: center;
}

.load-more-button {
  width: fit-content;
  padding: 12px;
  margin: 0 auto;
}

.curation-buttons {
  margin-top: 8px;
  margin-bottom: -12px;
  width: 100%;
  height: 36px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}

.curation-buttons > .accept,
.curation-buttons > .reject {
  width: 50%;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  transition: 0.3s ease-in;
  overflow: hidden;
}

.curation-buttons > .accept {
  background-color: #256f25;
}

.curation-buttons > .reject {
  background-color: #9f2222;
}

.curation-buttons > .accept:active {
  background-color: rgb(50, 150, 50);
}

.curation-buttons > .reject:active {
  background-color: rgb(200, 50, 50);
}

.curation-buttons.accepted {
  width: 100%;
}

.curation-buttons.rejected {
  width: 0%;
}

.date-divider {
  position: sticky;
  padding: 0px 12px;
  top: -1px;
  z-index: 1;
  background: #efeff1;
  height: 32px;
}

.date-divider--line {
  width: calc(100% - 24px);
  height: 1px;
  background: #000;
  position: absolute;
  top: 16px;
}

.date-divider--day {
  position: absolute;
  background: #efeff1;
  top: 50%;
  left: 50%;
  padding: 0px 12px;
  transform: translate(-50%, -50%);
  font-size: 14px;
}

.undo-filter {
  font-size: large;
  cursor: pointer;
  float: right;
  color: rgb(158 158 158);
}

.undo-filter:hover {
  color: grey;
}

.gif-container {
  z-index: 1000;
}

.game-preview {
  position: relative;
  width: 100%;
  background: #fafafa;
  border-radius: 12px;
  margin: 12px 0;
  padding: 16px;
  cursor: pointer;
  box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 20%);
}

.no-games {
  font-family: Nunito;
  font-weight: 600;
  text-align: center;
}

.adjust {
  margin: 0px !important;
  margin-top: 4px !important;
}

.close-icon {
  position: relative;
}

.close-icon .fa-times-circle {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(158 158 158);
  cursor: pointer;
}

.close-icon .fa-times-circle:hover {
  color: grey;
}

.search-filters--title {
  font-size: 15px;
  font-weight: 600;
  font-family: "Nunito";
  margin-bottom: 6px;
}

.banner-image {
  padding-top: 5px;
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 639px) {
  .search-filters {
    width: auto;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  z-index: 1001;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.56);
  transition: opacity 0.3s;
}

.modal-container {
  position: absolute;
  height: auto;
  width: 90%;
  max-width: 600px;
  background: #fff;
  border-radius: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  padding: 24px 12px;
  text-align: center;
  height: 100%;
  position: relative;
}

.modal-content--title {
  text-align: center;
  font-size: 32px;
  font-family: "Nunito";
}

.modal-content--description {
  text-align: center;
  font-size: 22px;
  font-family: "Nunito";
  margin-top: 24px;
  padding: 0 24px;
  font-weight: 700;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.data-center {
  text-align: center;
  padding-top: 8px !important;
  font-size: 18px;
}

@media only screen and (max-width: 480px) {
  .modal-content--title {
    font-size: 16px;
  }
  .modal-content--description {
    font-size: 22px;
    padding: 0px 0px;
  }
}
</style>
