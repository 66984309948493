<template>
	<div :class="{hidden: !showModal}" class="modal-overlay" @click="hideModal">
		<div class="modal-container">
			<div class="modal-content" @click.stop>
                <div class="modal-content--title">{{$t('segmentModal.select_arena')}}</div>
                <div class="dome-picker--option-wrapper">
                    <div class="dome-picker--option" v-for="dome in domes" :key="dome.key" @click="selectDome(dome)">
                        <div class="dome-picker--name">{{dome.name}}</div>
                        <div class="dome-picker--image-wrapper">
                            <img class="dome-picker--image" :src="dome.img">
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'SegmentModal',
	props: {
		showModal: {
			type: Boolean,
			default() {
				return false;
			}
		},
        domes: {
			type: Array,
			default() {
				return [];
			}
		},
	},
	data() {
		return {
		}
	},
	methods: {
		hideModal() {
			this.$emit('hideSegmentModal');
		},
        selectDome(dome) {
            const that = this;
            setTimeout(function() {
                that.hideModal();
                that.$emit('domeSelected', dome);
            }, 300);
        }
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	z-index: 1001;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.56);
	transition: opacity 0.3s;
}

.modal-container {
	position: absolute;
	height: auto;
	width: 90%;
	max-width: 600px;
	background: #fff;
	border-radius: 24px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}

.modal-content {
	padding: 24px 12px;
	text-align: center;
	height: 100%;
	position: relative;
}

.modal-content--title {
	text-align: center;
	font-size: 32px;
	font-family: 'Russo One';
}

.modal-content--description {
	text-align: center;
	font-size: 22px;
	font-family: 'Nunito';
	margin-top: 42px;
	padding: 0 24px;
}

.modal-content--cta {
	margin-top: 16px;
	cursor: pointer;
	font-family: 'Nunito';
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 12px;
	background: linear-gradient(45deg, #FF0054, #610888);
	border-radius: 32px;
	padding: 12px;
	font-size: 20px;
	border-style: none;
	text-align: center;
	white-space: nowrap;
	width: 280px;
	margin: 28px auto 0;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}


.dome-picker--option-wrapper {
    padding-top: 34px;
    padding-bottom: 16px;
    min-width: 150px;
    border-radius: 16px;
    line-height: 40px;
    font-family: Nunito;
}

.dome-picker--option {
    text-align: left;
    padding-left: 12px;
    background: #eeeeee;
    user-select: none;
    height: 52px;
    border: 1px solid #fafafa;
    transition: transform 0.4s ease-in-out;
    transform: translateZ(0);
}

.dome-picker--option:hover, .dome-picker--option:active{
    transform: scale(1.02) translateZ(0);
    background: #dddddd !important;
}

.dome-picker--option:last-child {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.dome-picker--option:first-child {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.dome-picker--option:hover {
    background: #eee;
}

.dome-picker:hover .dome-picker--option-wrapper {
    opacity: 1;
    pointer-events: auto;
}

.dome-picker--image-wrapper {
    width: 52px;
    height: 52px;
    display: inline-block;
    float: right;
}

.dome-picker--image {
    margin: 0 auto;
    display: block;
    padding: 4px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.dome-picker--name {
    display: inline-block;
    font-size: 18px;
    line-height: 52px;
    vertical-align: top;
    font-weight: 600;
    user-select: none;
}

</style>
